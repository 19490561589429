"use strict";

/*global Kobo */

/**
 * Attach this to an element and all clicks will cause the window to scroll back to the top.
 *
 * @param el
 * @param options
 * @param gizmo
 * @constructor
 */
Kobo.Gizmo.BackToTop = function (el, options, gizmo) {
  // eslint-disable-line no-unused-vars 
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init,
      setupHandlers,
      scrollToTop,
      checkScrollPosition;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("BackToTop");
  self.settings = Kobo.extend({
    windowYThreshold: Kobo.$(window).height() / 2,
    duration: 1000,
    easing: "swing"
  }, options);

  scrollToTop = function scrollToTop() {
    Kobo.$("html,body").animate({
      scrollTop: 0
    }, self.settings.duration, self.settings.easing);
  };

  checkScrollPosition = function checkScrollPosition(e) {
    // eslint-disable-line no-unused-vars 
    if (Kobo.$(window).scrollTop() > self.settings.windowYThreshold) {
      $el.addClass("visible");
    } else {
      $el.removeClass("visible");
    }
  };

  setupHandlers = function setupHandlers() {
    Kobo.$(window).on("scroll", checkScrollPosition);
    $el.on("click", scrollToTop);
  };

  init = function init() {
    if (Kobo._mediator.determineBreakpoint() !== "small") {
      setupHandlers();
      checkScrollPosition();
    }
  };

  init();
};

Kobo.Gizmo.BackToTop.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);