"use strict";

/*global Kobo,document,DynamicConfiguration*/
Kobo.Gizmo.PageTitleWidget = function (element, options) {
  // eslint-disable-line no-unused-vars 
  "use strict";

  var self = this;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("PageTitleWidget");
  self.saveHeading = "";
  self.saveTitle = "";
  self.hasReplacedTitle = false;

  self.moveTitle = function () {
    var title = "",
        screensize = Kobo._mediator.determineBreakpoint(),
        primaryMetadataWidget,
        titleNode,
        pageType = Kobo.$('meta[name=PageType]').attr("content"),
        Home = "Home",
        BookDetail = "Book",
        MagDetail = "Magazine",
        isLanding,
        titleShort;

    if (screensize === "large" || screensize === "jumbo") {
      if (self.hasReplacedTitle) {
        Kobo.$(".kobo-logo").html(self.saveHeading);
        document.title = self.saveTitle;
        self.hasReplacedTitle = false;
      }

      return;
    }

    primaryMetadataWidget = Kobo.$("div[data-kobo-widget='ItemDetailPrimaryMetadataWidget']");

    if (primaryMetadataWidget.length) {
      titleNode = primaryMetadataWidget.find('h1.title');

      if (titleNode.text()) {
        title = titleNode.text();
      }
    }

    if (!title) {
      // Fallback to get title from Mobile Menu active item
      Kobo.$(".active-menu").each(function () {
        title = Kobo.$(this).text();
      });
    }

    if (!title) {
      Kobo.$(".subcategory-title h1").each(function () {
        if (Kobo.$(this).text()) {
          title = Kobo.$(this).text();
        }
      });
    }

    if (!title) {
      // Final fallback to try grabbing HTML title tage
      title = Kobo.$(document).attr("title");
    } // function to check if we are on a landing page based on body class


    isLanding = function () {
      var $isLandingBook = Kobo.$('.Store-Landing-Book'),
          $isLandingMag = Kobo.$('.Store-Landing-Magazine');

      if ($isLandingBook.length || $isLandingMag.length) {
        return true;
      }

      return false;
    }(); // Conditionally apply overview text to only book item page and mag item page


    if (title && pageType !== Home) {
      if ((pageType === BookDetail || pageType === MagDetail) && !isLanding) {
        Kobo.$(".kobo-logo").html(DynamicConfiguration.resourceStrings.overview);
      } else {
        titleShort = title.replace(/^\s*Kobo\s*-\s*/, "").replace(/\s*-\s*Kobo\s*$/, ""); // US13683;

        Kobo.$(".kobo-logo").html(titleShort);
      } //document.title = title; we don't need to do this


      self.hasReplacedTitle = true;
    }
  };

  self.restoreTitle = function () {
    Kobo.$(".page-title-area").hide();
    Kobo.$(".kobo-logo a").show();
  };

  self.checkScreensize = function () {
    var screensize = Kobo._mediator.determineBreakpoint();

    if (screensize === "small" || screensize === "medium") {
      self.moveTitle();
    } else {
      self.restoreTitle();
    }
  };

  self.init = function () {
    self.saveHeading = Kobo.$(".kobo-logo").html();
    self.saveTitle = document.title;
    self.hasReplacedTitle = false;
    self.moveTitle();
    self.events.onResize(self.moveTitle);
    self.events.onResize(self.checkScreensize);
  };

  self.init();
};

Kobo.Gizmo.PageTitleWidget.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);