"use strict";

/*global Kobo,$,ko,Modernizr,DynamicConfiguration */
Kobo.Gizmo.AuthenticationResetPassword = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType("AuthenticationResetPassword");
  self.MIN_PASSWORD_LENGTH = 8;
  self.PASSWORD_TOO_SHORT = 'password_is_too_short';
  self.PASSWORDS_DONT_MATCH = 'passwords_dont_match';
  self.PASSWORDS_SPECIAL_CHARACTERS = 'passwords_special_characters';
  self.PASSWORDS_ASTERIXES = 'passwords_asterixes';
  self.EXPIRED_TOKEN = 'expired_token';
  self.ERROR = 'generic_error';
  self.INVALID_CHARACTER = 'invalidChar';
  self.PASSWORD_TOO_LONG = 'password_too_long';
  self.PASSWORD_STRENGTH_ERROR = 'password_strength_error';

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.showError = function (errorType) {
    switch (errorType) {
      case self.PASSWORD_TOO_SHORT:
        self.$gizmo.find('#' + options.lengthErrorId).removeClass(options.hideSelector);
        self.$newPassword.addClass(options.errorSelector);
        break;

      case self.PASSWORDS_DONT_MATCH:
        self.$gizmo.find('#' + options.mismatchedErrorId).removeClass(options.hideSelector);
        self.$confirmPassword.addClass(options.errorSelector);
        break;

      case self.EXPIRED_TOKEN:
        self.$gizmo.find('#' + options.tokenErrorId).removeClass(options.hideSelector);
        self.disableForm();
        break;

      case self.PASSWORDS_SPECIAL_CHARACTERS:
        self.$gizmo.find('#' + options.specialCharactersErrorId).removeClass(options.hideSelector);
        break;

      case self.PASSWORDS_ASTERIXES:
        self.$gizmo.find('#' + options.asterixesErrorId).removeClass(options.hideSelector);
        break;

      case self.INVALID_CHARACTER:
        self.$gizmo.find('#' + options.invalidChar).removeClass(options.hideSelector);
        break;

      case self.PASSWORD_TOO_LONG:
        self.$gizmo.find('#' + options.passwordTooLong).removeClass(options.hideSelector);
        break;

      case self.PASSWORD_STRENGTH_ERROR:
        self.$gizmo.find('#' + options.passwordStrengthError).removeClass(options.hideSelector);
        break;

      default:
        self.$gizmo.find('#' + options.genericErrorId).removeClass(options.hideSelector);
    }

    self.hideSpinner();
  };

  self.hideError = function () {
    self.$errorBoxes.addClass(options.hideSelector);
    self.$newPassword.removeClass(options.errorSelector);
    self.$confirmPassword.removeClass(options.errorSelector);
    self.enableForm();
  };

  self.disableForm = function () {
    self.$disableOverlay.removeClass(options.hideSelector);
  };

  self.enableForm = function () {
    self.$disableOverlay.addClass(options.hideSelector);
  };

  self.isPasswordLongEnough = function (password) {
    if (!password || password.length < self.MIN_PASSWORD_LENGTH) {
      return false;
    }

    return true;
  };

  self.doPasswordsMatch = function (newPassword, confirmedPassword) {
    if (newPassword !== confirmedPassword) {
      return false;
    }

    return true;
  };

  self.resetPassword = function () {
    self.showSpinner();
    self.hideError();
    var pass = self.$newPassword[0].value,
        confirmedPass = self.$confirmPassword[0].value;

    if (!self.isPasswordLongEnough(pass)) {
      self.showError(self.PASSWORD_TOO_SHORT);
      return;
    }

    if (!self.doPasswordsMatch(pass, confirmedPass)) {
      self.showError(self.PASSWORDS_DONT_MATCH);
      return;
    }

    if (pass.indexOf('<') > -1 || pass.indexOf('>') > -1) {
      self.showError(self.INVALID_CHARACTER);
      return;
    }

    if (!/^[ -~]+$/.test(pass)) {
      self.showError(self.PASSWORDS_SPECIAL_CHARACTERS);
      return;
    }

    Kobo.Ajax({
      url: options.resetCta,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {
        token: options.redirectToken,
        password: pass,
        confirmPassword: confirmedPass
      },
      success: function success(data) {
        if (data === 'undefined') {
          self.showError(self.ERROR);
        } else if (data.PasswordResetResult === 'Success') {
          window.location.href = options.redirectUrl;
        } else if (data.PasswordResetResult === 'TokenExpired') {
          self.showError(self.EXPIRED_TOKEN);
        } else if (data.PasswordResetResult === 'PasswordTooShort') {
          self.showError(self.PASSWORD_TOO_SHORT);
        } else if (data.PasswordResetResult === 'PasswordDoesNotMatch') {
          self.showError(self.PASSWORDS_DONT_MATCH);
        } else if (data.PasswordResetResult === 'PasswordSpecialCharacters') {
          self.showError(self.PASSWORDS_SPECIAL_CHARACTERS);
        } else if (data.PasswordResetResult === 'PasswordAllAsterixes') {
          self.showError(self.PASSWORDS_ASTERIXES);
        } else if (data.PasswordResetResult === 'PasswordInvalidChars') {
          self.showError(self.INVALID_CHARACTER);
        } else if (data.PasswordResetResult === 'PasswordTooLong') {
          self.showError(self.PASSWORD_TOO_LONG);
        } else if (data.PasswordResetResult === 'PasswordStrengthError') {
          self.showError(self.PASSWORD_STRENGTH_ERROR);
        } else {
          self.showError(self.ERROR);
        }

        return;
      },
      error: function error() {
        self.showError(self.ERROR);
      }
    });
  };

  self.setupHandlers = function () {
    self.$submit.on('click', self.resetPassword);
    self.$submit.add(self.$newPassword).add(self.$confirmPassword).keypress(function (event) {
      if (event.keyCode === 13) {
        self.resetPassword();
      }
    });
    self.$newPassword.blur(function () {
      var pass = self.$newPassword[0].value;
      self.hideError();

      if (!pass) {
        // if it's empty we don't bother evaluating
        return;
      }

      if (!self.isPasswordLongEnough(pass)) {
        self.showError(self.PASSWORD_TOO_SHORT);
      }
    });
  };

  init = function init() {
    self.$newPassword = self.$gizmo.find('.' + options.newPasswordSelector);
    self.$confirmPassword = self.$gizmo.find('.' + options.confirmPasswordSelector);
    self.$submit = self.$gizmo.find('.' + options.submitSelector);
    self.$errorBoxes = self.$gizmo.find('.' + options.errorBoxSelector);
    self.$disableOverlay = self.$gizmo.find('.' + options.disableOverlaySelector);
    self.setupHandlers();
    Kobo.Utilities.iePlaceholder('.' + options.newPasswordSelector, this, true);
    Kobo.Utilities.iePlaceholder('.' + options.confirmPasswordSelector, this, true);
  };

  init();
};

Kobo.Gizmo.AuthenticationResetPassword.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);