"use strict";

/*globals Kobo, ko*/
(function () {
  'use strict';

  function CreateAccountBannerVM(options) {
    var setCookie = options.setCookie;
    var createAccountUrl = options.createAccountUrl;

    this.dismissBanner = function () {
      setCookie(true);
      Kobo.$(options.createAccountWidgetClass).remove();
    };

    this.openDialog = function () {
      var cancelDialog = Kobo.$('#' + options.templateId);
      var cancelDialogContainer = Kobo.$('<div id="' + options.dialogId + '">');
      cancelDialogContainer.html(cancelDialog.html());

      Kobo._modal.open(cancelDialogContainer);

      ko.applyBindings(this, document.getElementById(options.dialogId));
    };

    this.closeDialog = function () {
      Kobo._modal.close();
    };

    this.createAccountRedirect = function () {
      window.location.href = createAccountUrl;
    };
  }

  Kobo.Utilities.assignToNamespace('Kobo.ViewModel.CreateAccountBannerVM', CreateAccountBannerVM);
})();