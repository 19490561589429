"use strict";

/*globals Kobo,ko, DynamicConfiguration*/
Kobo.Gizmo.OrangeOffers = function (el) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  var self = this,
      subscribeUrl = this.options.subscribeUrl,
      offerId = this.options.offerId,
      productId = this.options.productId,
      offerToken = this.options.offerToken;
  this.viewModel = {
    showProcessing: ko.observable(false),
    showError: ko.observable(false),
    tryAgain: function tryAgain() {
      Kobo.Utilities.reload();
    }
  };

  this.resetViewModel = function () {
    self.viewModel.showProcessing(false);
    self.viewModel.showError(false);
  };

  this.subscribeToOffer = function () {
    Kobo.$.ajax({
      type: 'POST',
      url: subscribeUrl,
      data: JSON.stringify({
        offerId: offerId,
        productId: productId,
        offerToken: offerToken
      }),
      contentType: 'application/json',
      dataType: 'json'
    }).done(function (data) {
      if (data.IsSuccess && data.RedirectSuccess) {
        Kobo.Utilities.navigateTo(data.RedirectSuccess);
      } else {
        if (data.RedirectAuth) {
          Kobo.Utilities.navigateTo(data.RedirectAuth);
        } else {
          self.resetViewModel(true);
          self.viewModel.showError(true);
        }
      }
    }).fail(function (err) {
      // eslint-disable-line no-unused-vars 
      self.viewModel.showError(true);
    });
  };

  function init() {
    self.resetViewModel(true);
    self.viewModel.showProcessing(true);
    self.subscribeToOffer();
  }

  ko.applyBindings(self.viewModel, el);
  init();
};

Kobo.Gizmo.OrangeOffers.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);