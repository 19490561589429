"use strict";

/*global Kobo, ko*/
Kobo.Gizmo.PlusPlansManage = function (element, options) {
  "use strict";

  var self = this,
      callSwitchPlan,
      MODAL_SELECTOR = 'plusplan-modal',
      init;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("PlusPlansManage");
  Kobo.$.extend(options, {
    plans: {
      ebooks: 'ebooks',
      audiobooks: 'audiobooks',
      combo: 'combo'
    },
    actions: {
      UnableToSwitch: 'UnableToSwitch',
      UnableToSwitchCancelled: 'UnableToSwitchCancelled',
      Success: 'Success',
      Error: 'Error',
      UpgradeToCombined: 'UpgradeToCombined',
      Switch: 'Switch',
      SwitchEbooks: 'SwitchEbooks',
      SwitchAudiobooks: 'SwitchAudiobooks',
      Subscribe: 'Subscribe',
      FreeTrial: 'StartFreeTrial'
    },
    templates: {
      UnableToSwitch: '#plusplans-unavailable-template',
      UnableToSwitchCancelled: '#plusplans-unavailable-cancelled-template',
      Success: '#plusplans-success-template',
      Error: '#plusplans-error-template',
      UpgradeToCombined: '#plusplans-upgrade-template',
      SwitchAudiobooks: '#plusplans-switchaudiobooks-template',
      SwitchEbooks: '#plusplans-switchebooks-template'
    }
  });

  callSwitchPlan = function callSwitchPlan(actionUrl, success, error) {
    Kobo.Spinner.showSpinnerOverlay(Kobo.$('.' + MODAL_SELECTOR));
    Kobo.$.ajax({
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      url: actionUrl
    }).done(function (data) {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$('.' + MODAL_SELECTOR));
      success(data);
    }).fail(function (err) {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$('.' + MODAL_SELECTOR));
      error(err);
    });
  };

  init = function init() {
    var dialogVM = new Kobo.Subscriptions.ViewModels.PlusPlansDialogViewModel(options); // eslint-disable-line no-unused-vars 

    Kobo._mediator.register('plusplan::switchPlan');

    Kobo._mediator.subscribe('plusplan::switchPlan', function (event, data) {
      callSwitchPlan(data.detail.actionUrl, data.detail.onSuccess, data.detail.onError);
    });

    ko.components.register('plan-call-to-action', {
      viewModel: {
        createViewModel: function createViewModel(params, componentInfo) {
          // eslint-disable-line no-unused-vars 
          Kobo.$.extend(params, options);
          return new Kobo.Subscriptions.ViewModels.PlusPlanViewModel(params);
        }
      },
      template: {
        element: 'plan-cta-component'
      }
    });
    ko.applyBindings(self, element);
  };

  init();
};

Kobo.Gizmo.PlusPlansManage.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);