"use strict";

/*global Kobo, window, ko */
Kobo.Gizmo.KoboLoveMembershipUpgrade = function (gizmo) {
  "use strict";

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  self.redeemWithPoints = function () {
    Kobo._mediator.fire('koboLove::redeemStart', {
      productId: self.settings.itemId
    });
  };

  init = function init() {
    ko.applyBindings(self, gizmo);
  };

  init();
};

Kobo.Gizmo.KoboLoveMembershipUpgrade.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);