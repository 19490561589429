"use strict";

/*global Kobo,$,ko,Modernizr*/
Kobo.Gizmo.TwoStepExpandableController = function (el, options) {
  "use strict";

  var init,
      self = this;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("TwoStepExpandableController");
  self.settings = Kobo.extend({
    outerExpandableClassName: "outer-expandable",
    innerExpandableClassName: "inner-expandable",
    fixedBottomSpacePx: 100,
    fixedSpacePercentage: 0.5
  }, options);

  self.findExpandables = function (gizmoFactory) {
    var i, l, expandable;

    if (gizmoFactory.gizmoList && gizmoFactory.gizmoList.Expandable) {
      for (i = 0, l = gizmoFactory.gizmoList.Expandable.length; i < l; i++) {
        expandable = gizmoFactory.gizmoList.Expandable[i];

        if (!self.outerExpandable && expandable.$gizmo.hasClass(self.settings.outerExpandableClassName)) {
          self.outerExpandable = expandable;
        } else if (!self.innerExpandable && expandable.$gizmo.hasClass(self.settings.innerExpandableClassName)) {
          self.innerExpandable = expandable;
        }
      }
    }
  };

  self.handleInnerExpandableResize = function (scope, data) {
    if (self.outerExpandable && self.outerExpandable.viewModel && self.innerExpandable && self.innerExpandable.viewModel) {
      if (!self.outerExpandable.viewModel.isCollapsed()) {
        self.outerExpandable.viewModel.height(self.innerExpandable.viewModel.height());
      }
    }
  };

  self.setInnerExpandableOptimalDefaultHeight = function () {
    if (!self.innerExpandable || !self.innerExpandable.viewModel) {
      return;
    }

    var optimal = self.getOptimalInnerExpandableHeight(),
        exph = parseInt(self.innerExpandable.viewModel.settings.height);

    if (Math.abs(optimal - self.innerExpandable.$innerContent.height()) < 30) {
      self.innerExpandable.viewModel.$button.hide();
    } else {
      self.innerExpandable.viewModel.$button.show();
    }

    if (exph < optimal) {
      self.innerExpandable.viewModel.settings.height = optimal + "px";
      self.innerExpandable.viewModel.height(optimal + "px");
    }
  };

  self.setupExpandables = function (scope, data) {
    var screenMode = Kobo._mediator.determineBreakpoint();

    if (!data || !data.detail || !data.detail.gizmoFactory) {
      return;
    }

    self.findExpandables(data.detail.gizmoFactory);

    if (self.innerExpandable) {
      // FIXME when inter-gizmo events are implemented
      self.register("expandableResized");
      self.subscribe("expandableResized", self.handleInnerExpandableResize);

      if (self.innerExpandable.viewModel) {
        self.innerExpandable.viewModel.registerExpandableResizedEvent();
      } // hack: this is needed because the 2 expandables are expanding asynchronously
      // jsalverda - fixed the event to only fire when it has finished
      //setTimeout(self.handleInnerExpandableResize, 500);


      self.events.onResize(function (data) {
        if (self.outerExpandable && self.outerExpandable.viewModel && self.outerExpandable.viewModel.isCollapsed() && (data.currentSize === "large" || data.currentSize === "jumbo")) {
          self.outerExpandable.viewModel.toggle();
        }

        self.setInnerExpandableOptimalDefaultHeight();
        self.handleInnerExpandableResize(null, null);
      });
      self.setInnerExpandableOptimalDefaultHeight();
    }

    if (self.outerExpandable && (screenMode === "small" || screenMode === "medium")) {
      self.outerExpandable.viewModel.toggle();
    }
  };

  self.getOptimalInnerExpandableHeightMobile = function () {
    var screenSize = Kobo.$(window).height(),
        offset = self.$gizmo.offset().top;
    return screenSize - offset - self.settings.fixedBottomSpacePx;
  };

  self.getOptimalInnerExpandableHeightDesktop = function () {
    var screenSize = Kobo.$(window).height();
    return screenSize * self.settings.fixedSpacePercentage;
  };

  self.getOptimalInnerExpandableHeight = function () {
    // optimal height is:
    // - [on small/medium] whole screen (less top offset and bottom margin)
    // - [on desktop] half the screen height or natural size (whichever is larger)
    var screenMode = Kobo._mediator.determineBreakpoint(),
        containerHeight = self.innerExpandable.defaultHeight(),
        maxListHeight,
        buttonHeight = self.innerExpandable.viewModel.$button.height();

    if (screenMode === "small" || screenMode === "medium") {
      maxListHeight = self.getOptimalInnerExpandableHeightMobile();
    } else {
      maxListHeight = self.getOptimalInnerExpandableHeightDesktop();
    }

    maxListHeight -= buttonHeight;

    if (maxListHeight > containerHeight) {
      // maxlistheight cannot be more than the height of the inner
      maxListHeight = containerHeight;
    } else {
      // revise down to nearest listitem
      self.innerExpandable.$gizmo.find('li').each(function () {
        var li = Kobo.$(this),
            newMaxListHeight = li.height() + li.position().top;

        if (newMaxListHeight > maxListHeight) {
          maxListHeight = newMaxListHeight;
          return false;
        }
      });
    }

    return maxListHeight;
  };

  self.applySizeRules = function () {
    var screenMode = Kobo._mediator.determineBreakpoint();

    if (screenMode === "small" || screenMode === "medium") {
      var topbtn = Kobo.$('.gizmo-expandable-subbutton-wrapper>div');
      topbtn.addClass('gizmo-expandable-subbutton-white');
      topbtn.removeClass('gizmo-expandable-subbutton');
      topbtn.css('-webkit-transform', 'rotate(0deg)');
    }
  };

  init = function init() {
    setTimeout(self.applySizeRules, 250);
    self.register("gizmosInitialized");
    self.subscribe("gizmosInitialized", self.setupExpandables);
  };

  init();
};

Kobo.Gizmo.TwoStepExpandableController.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);