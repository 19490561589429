"use strict";

/*global Kobo*/

/**
 * Kobo.Gizmo.Collapsible.Series
 * @description Extends Kobo.Gizmo.Collapsible.HasHeader and accounts for situations where the synopsis is not long enough to have a toggle button
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.Collapsible.Series = function () {
  'use strict';

  var self = this,
      init,
      onResize,
      sizeCheck,
      hideSize = 154,
      //height for two rows (2 * 72) + 10 buffer
  description;
  Kobo.Gizmo.Collapsible.HasHeader.apply(this, arguments);

  sizeCheck = function sizeCheck() {
    if (!self.isOpen() && description.scrollHeight <= hideSize) {
      self.$el.addClass('hide-expandable');
    } else {
      self.$el.removeClass('hide-expandable');
    }
  };

  onResize = Kobo.Utilities.Events.debounce(sizeCheck);

  self.destroy = function () {
    Kobo.$window.off('resize', onResize);
  };

  init = function init() {
    description = self.el.querySelector('.series-synopsis-description');
    Kobo.$window.on('resize', onResize);
    sizeCheck();
  };

  init();
};

Kobo.Gizmo.Collapsible.Series.prototype = Kobo.chainPrototype(Kobo.Gizmo.Collapsible.HasHeader.prototype);