"use strict";

/*global Kobo,Cookies*/
Kobo.Gizmo.ReadingListAnnouncement = function () {
  'use strict';

  var self = this,
      readingListAnnouncement = window.DynamicConfiguration.resourceStrings.readingListAnnouncement,
      showAnnouncement,
      hideAddToReadingListAnnouncement,
      init;
  Kobo.Gizmo.apply(this, arguments);

  showAnnouncement = function showAnnouncement() {
    new Kobo.Popup({
      content: readingListAnnouncement,
      parent: self.$el,
      ariaLive: 'assertive',
      focusOnOpen: true,
      closeOnClick: true,
      onClose: hideAddToReadingListAnnouncement
    }).show();
  };

  init = function init() {
    showAnnouncement();
  };

  hideAddToReadingListAnnouncement = function hideAddToReadingListAnnouncement() {
    var cookieName = self.options.hideAddToReadingListCookieName;

    if (cookieName !== undefined) {
      Cookies.set(cookieName, "true");
    }
  };

  init();
};

Kobo.Gizmo.ReadingListAnnouncement.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);