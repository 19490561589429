"use strict";

/*global Kobo*/
Kobo.Gizmo.SkipBillingInfo = function (element, options) {
  "use strict";

  var self = this,
      skipThisStep,
      setupHandlers,
      setupResourceStrings,
      getModalContent,
      modalContent,
      continueWithoutSaving,
      chargeOnFirstPurchase,
      editPaymentInfo,
      continueToKobo,
      continueUrl,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("SkipBillingInfo");

  getModalContent = function getModalContent() {
    if (modalContent) {
      return modalContent;
    }

    modalContent = document.createElement('div');
    modalContent.className = 'new-user-confirmation';

    modalContent.onclick = function (event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (target.className === 'close-button' || target.className === 'primary-link') {
        Kobo._modal.close();
      }
    };

    modalContent.innerHTML = '<button class="close-button"></button>' + '<p>' + continueWithoutSaving + '</p>' + '<p>' + chargeOnFirstPurchase + '</p>' + '<div class="bottom-links">' + '<div class="bottom-link"><span class="primary-link">' + editPaymentInfo + '</span></div>' + '<div class="bottom-link"><a href="' + continueUrl + '" class="button primary-button">' + continueToKobo + '</a></div>' + '</div>';
    return modalContent;
  };

  skipThisStep = function skipThisStep() {
    Kobo._modal.open(getModalContent(), {
      customClass: 'new-user-content'
    });
  };

  setupHandlers = function setupHandlers() {
    self.$el.on('click', '.skip-this-step', skipThisStep);
  };

  setupResourceStrings = function setupResourceStrings() {
    var dynamicConfiguration = window.DynamicConfiguration;

    if (!dynamicConfiguration) {
      return;
    }

    continueWithoutSaving = dynamicConfiguration.resourceStrings.continueWithoutSaving;
    editPaymentInfo = dynamicConfiguration.resourceStrings.editPaymentInfo;
    continueToKobo = dynamicConfiguration.resourceStrings.continueToKobo;
    chargeOnFirstPurchase = dynamicConfiguration.resourceStrings.chargeOnFirstPurchase;
  };

  init = function init() {
    setupHandlers();
    setupResourceStrings();
    continueUrl = options.continueUrl;
  };

  self.destroy = function () {
    self.$el.off('click', '.skip-this-step', skipThisStep);
  };

  init();
};

Kobo.Gizmo.SkipBillingInfo.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);