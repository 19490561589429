"use strict";

/*global Kobo, ko */
Kobo.Gizmo.PriceFacet = function (gizmo, options) {
  "use strict";

  var self = this,
      maxRangeFacet,
      minRangeFacet,
      filterByPointsFacet,
      currentBalance,
      init;
  maxRangeFacet = options.priceMaxFacet;
  minRangeFacet = options.priceMinFacet;
  filterByPointsFacet = options.filterByPointsFacet;
  currentBalance = options.currentBalance;
  self.priceRangeMax = ko.observableArray(options.priceRangeMax);
  self.priceRangeMin = ko.observableArray(options.priceRangeMin);
  self.pointRangeMax = ko.observableArray(options.pointRangeMax);
  self.pointRangeMin = ko.observableArray(options.pointRangeMin);
  self.filterByPoints = ko.observable();
  self.selectedPriceMin = ko.observable();
  self.selectedPriceMax = ko.observable();
  self.selectedPointMin = ko.observable();
  self.selectedPointMax = ko.observable();
  self.onlyFree = ko.observable(false);
  self.onlyPointsAffordable = ko.observable(false);
  self.showPointRange = ko.computed(function () {
    return self.filterByPoints() === 'yes';
  });
  self.showPriceRange = ko.computed(function () {
    return self.filterByPoints() !== 'yes';
  });
  Kobo.Gizmo.apply(this, arguments);
  self.setType("PriceFacet");

  self.getMinRangeValue = function () {
    if (self.filterByPoints() === 'yes') {
      return self.onlyPointsAffordable() ? 0 : self.selectedPointMin();
    }

    if (self.onlyFree()) {
      return 0;
    }

    return self.selectedPriceMin();
  };

  self.getMaxRangeValue = function () {
    if (self.filterByPoints() === 'yes') {
      return self.onlyPointsAffordable() ? currentBalance : self.selectedPointMax();
    }

    if (self.onlyFree()) {
      return 0;
    }

    return self.selectedPriceMax();
  };

  self.resetToDefaults = function () {
    var url = Kobo.Utilities.removeQueryStringParameter(window.location.href, filterByPointsFacet);
    url = Kobo.Utilities.removeQueryStringParameter(url, maxRangeFacet);
    url = Kobo.Utilities.removeQueryStringParameter(url, minRangeFacet);
    window.location.href = url;
  };

  self.applyFacet = function () {
    var url = Kobo.Utilities.updateQueryStringParameter(window.location.href, filterByPointsFacet, self.filterByPoints());
    url = Kobo.Utilities.updateQueryStringParameter(url, maxRangeFacet, self.getMaxRangeValue());
    url = Kobo.Utilities.updateQueryStringParameter(url, minRangeFacet, self.getMinRangeValue());
    window.location.href = url;
  };

  init = function init() {
    var facetValue = Kobo.Utilities.getQueryStringParameterByName(window.location.href, filterByPointsFacet),
        minValue = Kobo.Utilities.getQueryStringParameterByName(window.location.href, minRangeFacet),
        maxValue = Kobo.Utilities.getQueryStringParameterByName(window.location.href, maxRangeFacet);

    if (!maxValue) {
      maxValue = 'null';
    }

    if (facetValue) {
      facetValue = facetValue.toLowerCase();
    }

    if (facetValue !== 'yes') {
      facetValue = 'no';
    }

    self.filterByPoints(facetValue);

    if (self.filterByPoints() === 'yes') {
      self.onlyPointsAffordable(minValue === '0' && maxValue === currentBalance.toString());
      self.selectedPointMin(minValue);
      self.selectedPointMax(maxValue);
      self.selectedPriceMin(self.priceRangeMin()[0].Value);
      self.selectedPriceMax(self.priceRangeMax()[self.priceRangeMax().length - 1].Value);
    } else {
      self.onlyFree(minValue === '0' && maxValue === '0');
      self.selectedPriceMin(minValue);
      self.selectedPriceMax(maxValue);
      self.selectedPointMin(self.pointRangeMin()[0].Value);
      self.selectedPointMax(self.pointRangeMax()[self.pointRangeMax().length - 1].Value);
    }

    ko.applyBindings(self, gizmo);
  };

  init();

  Kobo._gizmo.fire("updateHeight");
};

Kobo.Gizmo.PriceFacet.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);