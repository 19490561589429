"use strict";

/*global Kobo, DynamicConfiguration */
Kobo.Gizmo.LovePointsRedemptionLightbox = function () {
  'use strict';

  var self = this,
      $container = Kobo.$('<div />'),
      startRedemption,
      buildGenericErrorMarkup,
      onRedeemFinished,
      onRedeemCancelled,
      init;
  Kobo.Gizmo.apply(this, arguments);

  buildGenericErrorMarkup = function buildGenericErrorMarkup() {
    var genericErrorHeader = DynamicConfiguration.resourceStrings.somethingGoneWrong,
        unableToPurchaseError = DynamicConfiguration.resourceStrings.unableToGetThis,
        pleaseTryAgainError = DynamicConfiguration.resourceStrings.pleaseTryAgain,
        pointsNotRedeemed = DynamicConfiguration.resourceStrings.pointsNotRedeemed,
        stillHavingProblems = DynamicConfiguration.resourceStrings.stillHavingProblems,
        buttonLabel = DynamicConfiguration.resourceStrings.ok,
        $genericErrorMarkup,
        clickTrackInfo = Kobo._tracker.getTrackAttribute() + '=\'{ "Section" : "klRedeemFail" }\'',
        clickTrackButton = Kobo._tracker.getTrackAttribute() + '=\'{"Description : "klRedeemFailOK"}\'';
    $genericErrorMarkup = Kobo.$('<section class="redemption-confirmation redemption-container error"' + clickTrackInfo + '>' + '<h1>' + genericErrorHeader + '</h1>' + '<div class="error-message">' + '<p>' + unableToPurchaseError + '</p>' + '<p>' + pleaseTryAgainError + '</p>' + '</div>' + '<p class="extraNote">' + pointsNotRedeemed + '</p>' + '<p class="extraNoteDetails">' + stillHavingProblems + '</p>' + '<div class="button-wrapper">' + '<button class="cancel" ' + clickTrackButton + '>' + buttonLabel + '</button>' + '</div>' + '</section>');
    $container.html($genericErrorMarkup);
    $container.find('.cancel').one('click', function () {
      Kobo._modal.close();
    });
  };

  startRedemption = function startRedemption(event, args) {
    Kobo._modal.open($container);

    Kobo.Utilities.renderOnServer($container, self.settings.startRedemptionUrl, {
      productId: args.detail.productId,
      useAbbreviatedContent: self.settings.useAbbreviatedContent,
      canAccessBillingInfo: self.settings.canAccessBillingInfo
    }, null, buildGenericErrorMarkup);
  };

  onRedeemFinished = function onRedeemFinished() {
    Kobo._modal.destroy(); // NOTE: Some pages support client side re-rendering, it may be unnecessary to reload the entire page.


    Kobo.Utilities.reload(); // to update the view and show that this book is now owned
  };

  onRedeemCancelled = function onRedeemCancelled() {
    Kobo._modal.destroy();
  };

  init = function init() {
    Kobo._mediator.register('koboLove::redeemStart');

    Kobo._mediator.subscribe('koboLove::redeemStart', startRedemption);

    Kobo._mediator.register('koboLove::redeemFinished');

    Kobo._mediator.subscribe('koboLove::redeemFinished', onRedeemFinished);

    Kobo._mediator.register('koboLove::redeemCancelled');

    Kobo._mediator.subscribe('koboLove::redeemCancelled', onRedeemCancelled);
  };

  init();
};

Kobo.Gizmo.LovePointsRedemptionLightbox.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);