"use strict";

/*global Kobo, window */
Kobo.Gizmo.LovePointsRedemptionError = function () {
  "use strict";

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  init = function init() {
    self.$gizmo.find('.cancel').on('click', function () {
      Kobo._mediator.fire('koboLove::redeemCancelled');
    });
  };

  init();
};

Kobo.Gizmo.LovePointsRedemptionError.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);