"use strict";

/*globals Kobo,ko*/
function AudiobookSubscriptionsCancelVM(data) {
  "use strict";

  var self = this;
  var CancelCodes = {
    NoMonthlyBill: 'NoMonthlyBill',
    NotEnoughTime: 'NotEnoughTime',
    DidntLikeAudiobook: 'DidntLikeAudiobook',
    CantFindAudiobooks: 'CantFindAudiobooks',
    TryItOut: 'TryItOut',
    Other: 'Other'
  };
  var ErrorReason = {
    NoSelection: 'NoSelection',
    NoFeedback: 'NoFeedback',
    General: 'General'
  };
  self.step = ko.observable(1); // start at 1

  self.steps = data.stepsConfig || [];
  self.reasons = data.reasons || [];
  self.cancelledSubscription = false;
  self.resetFocus = ko.observable(false);
  self.renewUrl = data.renewUrl || '';
  self.showSpinner = ko.observable(false);
  self.inputHasFocus = ko.observable(false);
  self.errorReasonCode = ko.observable('');
  self.showError = ko.observable(false);
  self.scrollToError = ko.observable(false);
  self.cancelReasonCode = ko.observable('');
  self.cancelReasonCode.subscribe(function (newVal) {
    if (newVal !== '') {
      self.errorReasonCode('');
      self.showError(false);
    }
  }, this);
  self.moreFeedback = ko.observable('');
  self.moreFeedback.subscribe(function () {
    // if no reason was selected, automatically check "Other" as
    // they type feedback
    if (self.cancelReasonCode() === '') {
      self.cancelReasonCode(CancelCodes.Other);
    }
  }, this);

  self.getDynamicText = function (stringCode) {
    if (!stringCode || !window.DynamicConfiguration.resourceStrings.cancelAudiobooksModal) return "";
    return window.DynamicConfiguration.resourceStrings.cancelAudiobooksModal[stringCode];
  };

  self.nextStep = function () {
    self.goToStep(self.step() + 1);
  };

  self.goToStep = function (step) {
    if (step > self.steps.length) {
      self.closeDialog();
    } else {
      // Going to Step 3 -> Cancel Subscription
      if (step === 3 && !self.cancelledSubscription) {
        self.cancel();
      } else {
        self.step(step);
        self.resetFocus(true);
      }
    }
  };

  self.errorMessage = ko.computed(function () {
    return self.errorReasonCode() === ErrorReason.NoSelection ? self.getDynamicText('NoSelectionError') : self.errorReasonCode() === ErrorReason.NoFeedback ? self.getDynamicText('NoFeedbackError') : self.getDynamicText('CancellationError');
  });

  self.showErrorMessage = function (errorCode, scroll) {
    self.showError(true);
    self.errorReasonCode(errorCode);

    if (scroll) {
      self.scrollToError(true);
    }
  };

  self.cancel = function () {
    self.showError(false); // Error if nothing was selected, or "Other" was selected but no feedback was entered

    if (self.cancelReasonCode() === '' && self.moreFeedback() === '') {
      self.showErrorMessage(ErrorReason.NoSelection, true);
    } else if (self.cancelReasonCode() === CancelCodes.Other && self.moreFeedback() === '') {
      self.showErrorMessage(ErrorReason.NoFeedback, true);
    } else {
      var cancelBody = self.cancelReasonCode();

      if (self.cancelReasonCode() === CancelCodes.CantFindAudiobooks && self.missingAudiobook !== '') {
        cancelBody += '/' + self.missingAudiobook;
      }

      if (self.moreFeedback() !== '') {
        cancelBody += '/' + self.moreFeedback();
      }

      Kobo.Ajax({
        type: 'POST',
        url: data.cancelUrl,
        data: {
          reason: cancelBody
        },
        beforeSend: function beforeSend() {
          self.showSpinner(true);
        },
        success: function success(response) {
          if (response.IsSuccess === true) {
            self.cancelledSubscription = true;
            self.nextStep();
          } else {
            self.showErrorMessage(ErrorReason.General, true);
          }
        },
        error: function error() {
          self.showErrorMessage(ErrorReason.General, true);
        },
        complete: function complete() {
          self.showSpinner(false);
        }
      });
    }
  };

  self.drawerAction = function () {
    switch (self.cancelReasonCode()) {
      case CancelCodes.NoMonthlyBill:
      case CancelCodes.NotEnoughTime:
        self.skipAMonthEvent.notifySubscribers(data.subscriptionId);
        break;
    }
  };

  self.showOptionDrawer = function (code) {
    return code === CancelCodes.NoMonthlyBill || code === CancelCodes.NotEnoughTime ? data.canSkipMonth : true;
  };

  self.embedLink = function (mainText, linkText, tracking) {
    var link = '';

    if (linkText) {
      linkText = self.getDynamicText(linkText);
      link = "<a tabindex='0' data-bind='click: $root.drawerAction, attr: { \"data-track-info\": ".concat(JSON.stringify(tracking), " }'>").concat(linkText, "</a>");
    }

    mainText = self.getDynamicText(mainText);
    return mainText.replace('{0}', link);
  };

  self.ariaStep = ko.computed(function () {
    var ariaText = self.getDynamicText(self.steps[self.step() - 1].ariaCurrentStepStringCode); // {0} is current step; {1} is total steps

    return ariaText.replace('{0}', self.step()).replace('{1}', self.steps.length);
  }, this);
  self.mainContentTemplate = ko.computed(function () {
    return self.steps[self.step() - 1].templateId;
  }, this);
  self.nextStepButtonContent = ko.computed(function () {
    return self.getDynamicText(self.steps[self.step() - 1].nextStepButton.textStringCode);
  }, this);
  self.nextStepButtonDescription = ko.computed(function () {
    return self.steps[self.step() - 1].nextStepButton.trackingInfo.replace('{0}', self.cancelReasonCode());
  }, this);
  self.stayButtonContent = ko.computed(function () {
    return self.getDynamicText(self.steps[self.step() - 1].stayButton.textStringCode);
  }, this);
  self.stayButtonDescription = ko.computed(function () {
    return self.steps[self.step() - 1].stayButton.trackingInfo.replace('{0}', self.step());
  }, this);
  self.showKoboPlusOffer = ko.computed(function () {
    // reason code to trigger showing Kobo Plus offer in step 3
    return data.isKoboPlusEnabled === 'true' && self.cancelReasonCode() === CancelCodes.TryItOut;
  }, this);
  self.showCloseButton = ko.computed(function () {
    return self.stayButtonContent() != '';
  }, this);
  self.closeDialogEvent = new ko.subscribable();

  self.closeDialog = function () {
    self.closeDialogEvent.notifySubscribers(self.cancelledSubscription);
  };

  self.skipAMonthEvent = new ko.subscribable();
  ko.bindingHandlers.dynamicText = {
    init: function init(element, valueAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      $(element).text(self.getDynamicText(value));
    }
  };
  ko.bindingHandlers.dynamicPlaceholder = {
    init: function init(element, valueAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      $(element).attr('placeholder', self.getDynamicText(value));
    }
  }; // Form and embed a link into text, and bind it with ko

  ko.bindingHandlers.boundHtml = {
    init: function init(element, valueAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      var parameters = value.params.concat();
      var processedHtml = value.source.apply(null, parameters);
      $(element).html(processedHtml);
    }
  };
}

ko.bindingHandlers.scrollTo = {
  update: function update(element, valueAccessor) {
    var _value = valueAccessor();

    var _valueUnwrapped = ko.unwrap(_value);

    if (_valueUnwrapped) {
      element.scrollIntoView({
        behavior: 'smooth'
      }); // resets the trigger value to false.

      if (ko.isWriteableObservable(_value) && typeof _valueUnwrapped === 'boolean') {
        _value(false);
      }
    }
  }
};
ko.bindingHandlers.slideOpen = {
  update: function update(element, valueAccessor) {
    var value = valueAccessor();
    var valueUnwrapped = ko.unwrap(value);

    if (valueUnwrapped) {
      $(element).slideDown();
    } else {
      $(element).slideUp();
    }
  }
};
ko.bindingHandlers.ariaExpanded = {
  update: function update(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());

    if (value.addCondition) {
      $(element).attr('aria-expanded', value.expanded);
    }
  }
};
ko.bindingHandlers.focusElement = {
  update: function update(element, valueAccessor) {
    var _value = valueAccessor();

    var _valueUnwrapped = ko.unwrap(_value);

    if (_valueUnwrapped == true) {
      element.focus();
    }
  }
};
Kobo.Utilities.assignToNamespace('Kobo.Subscriptions.ViewModels.AudiobookSubscriptionsCancelVM', AudiobookSubscriptionsCancelVM);