"use strict";

/*globals Kobo*/

/**
 * A gizmo to gently scroll the tab header for small screens.
 * Activates for mobile only.
 *
 * @param el
 * @param options
 * @param gizmo
 * @constructor
 */
Kobo.Gizmo.ScrollableTabHeader = function (el, options) {
  "use strict";

  var self = this,
      $previousButton,
      $nextButton,
      $wrapperContainer,
      $tabHeaderList,
      currentPageIndex,
      tabPages = [],
      $focusableElements = [];
  self.setDefaults({
    duration: 500,
    // how long the scroll animation takes
    easing: "swing" // the type of animation

  }, options);
  Kobo.Gizmo.apply(this, arguments);
  self.setType("ScrollableTabHeader");

  self.init = function () {
    $previousButton = self.$el.find(self.settings.previousButtonSelector);
    $nextButton = self.$el.find(self.settings.nextButtonSelector);
    $wrapperContainer = self.$el.find(self.settings.wrapperContainerSelector);
    $tabHeaderList = $wrapperContainer.find(self.settings.tabHeaderListSelector).toArray();
    $focusableElements = Kobo.Utilities.findAllFocusableElements($wrapperContainer);
    self.events.onResize(self.onResize);
    $previousButton.click(previousButtonHandler);
    $nextButton.click(nextButtonHandler);
    self.onResize();
  };

  self.getScrollPages = function () {
    var scrollPages = [];
    $tabHeaderList.reduce(function (prev, curr, index) {
      var currentItemOffset = $tabHeaderList[index].offsetLeft;
      var overflowsContainer = currentItemOffset > $wrapperContainer[0].offsetWidth;

      if (index === 0 || overflowsContainer) {
        var newPage = {
          scrollPosition: prev.scrollPosition + prev.size,
          size: $tabHeaderList[index].offsetWidth,
          pageOffset: prev.scrollPosition + prev.size
        };
        scrollPages.push({
          tab: [curr],
          scrollPosition: newPage.scrollPosition
        });
        return newPage;
      } else {
        scrollPages[scrollPages.length - 1].tab.push(curr);
        return {
          scrollPosition: prev.scrollPosition + prev.size,
          size: $tabHeaderList[index].offsetWidth,
          pageOffset: prev.pageOffset
        };
      }
    }, {
      scrollPosition: 0,
      size: 0,
      pageOffset: 0
    });
    return scrollPages;
  };

  var previousButtonHandler = function previousButtonHandler() {
    if (currentPageIndex > 0) {
      $nextButton.show();
      scrollToPage(--currentPageIndex);

      if (currentPageIndex === 0) {
        $previousButton.hide();
        Kobo.Utilities.findAllFocusableElements(tabPages[currentPageIndex].tab)[0].focus();
      }
    }
  };

  var nextButtonHandler = function nextButtonHandler() {
    if (currentPageIndex < tabPages.length - 1) {
      $previousButton.show();
      scrollToPage(++currentPageIndex);

      if (currentPageIndex === tabPages.length - 1) {
        $nextButton.hide();
        Kobo.Utilities.findAllFocusableElements(tabPages[currentPageIndex].tab)[0].focus();
      }
    }
  };

  var scrollToPage = function scrollToPage(pageIndex) {
    $wrapperContainer.animate({
      scrollLeft: tabPages[pageIndex].scrollPosition
    }, {
      duration: self.settings.duration,
      easing: self.settings.easing
    });
    setAccessibilityAttr();
  };

  var setAccessibilityAttr = function setAccessibilityAttr() {
    if (tabPages.length) {
      $focusableElements.attr('tabindex', -1).attr('aria-hidden', true);
      Kobo.Utilities.findAllFocusableElements(tabPages[currentPageIndex].tab).attr('tabindex', 0).attr('aria-hidden', false);
    }
  };

  self.onResize = function () {
    currentPageIndex = 0;

    var screensize = Kobo._mediator.determineBreakpoint();

    if (screensize === "small") {
      if (currentPageIndex === 0) {
        $previousButton.hide();
      } else {
        $previousButton.show();
      }

      tabPages = self.getScrollPages();

      if (currentPageIndex === tabPages.length - 1) {
        $nextButton.hide();
      } else {
        $nextButton.show();
      }

      setAccessibilityAttr();
    } else {
      $focusableElements.attr('tabindex', 0).attr('aria-hidden', false);
    }
  };

  self.destroy = function () {
    self.events.offResize(self.onResize);
  };

  self.init();
};

Kobo.Gizmo.ScrollableTabHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);