"use strict";

/*global Kobo*/

/**
 * Kobo.Gizmo.ReadMore
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.ReadMore = function () {
  'use strict';

  var self = this,
      init,
      sizeCheck,
      showParagraphLines = 8,
      showMobileParagraphLines = 20;
  Kobo.Gizmo.apply(this, arguments);

  sizeCheck = function sizeCheck() {
    var lineHeight = parseFloat(getComputedStyle(self.el.querySelector('.preview-read-more')).lineHeight);
    var maxHeight = window.innerWidth > 568 ? lineHeight * showParagraphLines : lineHeight * showMobileParagraphLines;
    var hiddenAuthorDescription = document.getElementsByClassName('truncated-author-description')[0];

    if (self.el.querySelector('.preview-read-more > p').offsetHeight <= maxHeight) {
      self.el.querySelector('.preview-read-more').classList.add('show-full');
      self.el.querySelector('.toggle-read-more').classList.add('hide');
      self.el.querySelector('.toggle-read-less').classList.add('hide');

      if (hiddenAuthorDescription) {
        hiddenAuthorDescription.setAttribute('aria-hidden', false);
      }
    } else {
      self.el.querySelector('.preview-read-more').classList.remove('show-full');
      self.el.querySelector('.toggle-read-more').classList.remove('hide');
      self.el.querySelector('.toggle-read-less').classList.remove('hide');

      if (hiddenAuthorDescription) {
        hiddenAuthorDescription.setAttribute('aria-hidden', true);
      }
    }
  };

  init = function init() {
    var hiddenAuthorDescription = document.getElementsByClassName('truncated-author-description')[0];
    self.el.querySelector('.toggle-read-more').addEventListener('click', function () {
      self.el.querySelector('.preview-read-more').classList.toggle('show-full');

      if (hiddenAuthorDescription) {
        hiddenAuthorDescription.setAttribute('aria-hidden', false);
      }
    });
    self.el.querySelector('.toggle-read-less').addEventListener('click', function () {
      self.el.querySelector('.preview-read-more').classList.toggle('show-full');

      if (hiddenAuthorDescription) {
        hiddenAuthorDescription.setAttribute('aria-hidden', true);
      }
    });
    sizeCheck();
  };

  init();
};

Kobo.Gizmo.ReadMore.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);