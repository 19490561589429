"use strict";

/*global Kobo, window */
Kobo.Gizmo.LovePointsRedemptionConfirmation = function () {
  "use strict";

  var self = this,
      bindEvents,
      init;
  Kobo.Gizmo.apply(this, arguments);

  bindEvents = function bindEvents($context) {
    $context.find('.cancel').on('click', function () {
      Kobo._mediator.fire('koboLove::redeemCancelled');
    });
    $context.find('.buy').on('click', function () {
      Kobo.Utilities.renderOnServer(self.$gizmo.parent(), self.settings.redeemUrl, {
        productId: self.settings.productId,
        useAbbreviatedContent: self.settings.useAbbreviatedContent,
        canAccessBillingInfo: self.settings.canAccessBillingInfo
      }, 'POST');
    });
  };

  init = function init() {
    bindEvents(self.$gizmo);
  };

  init();
};

Kobo.Gizmo.LovePointsRedemptionConfirmation.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);