"use strict";

/*global Kobo,$, el*/
Kobo.Gizmo.AudiobookCreditBalance = function (el, options) {
  "use strict";

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("AudiobookCreditBalance");

  function getAudiobookCreditBalance() {
    Kobo.Ajax({
      url: self.settings.creditBalanceUrl,
      method: 'GET',
      traditional: true,
      dataType: 'json',
      success: function success(data) {
        if (data !== null && data.CreditAmount !== null && data.CreditAmount > 0) {
          if (data.CreditAmount == 1) {
            self.$gizmo.find('.' + options.singleCreditSelector).removeClass(options.hiddenSelector);
          } else {
            var $multipleCreditElem = self.$gizmo.find('.' + options.multipleCreditSelector);
            var $creditTextElem = $multipleCreditElem.find('.' + options.creditTextSelector);
            $creditTextElem.text(Kobo.Utilities.stringFormat($creditTextElem.text(), data.CreditAmount));
            $multipleCreditElem.removeClass(options.hiddenSelector);
          }
        } else {
          self.$gizmo.find('.' + options.noCreditsSelector).removeClass(options.hiddenSelector);
        } // Adjust padding & margin on mobile/touchscreens (so it aligns with carousels)


        if ($('.carousel-treatment.navigation-arrows-enabled')[0]) {
          self.$gizmo.removeClass(options.mobileSelector);
        } else {
          self.$gizmo.addClass(options.mobileSelector);
        }

        self.$gizmo.find('.' + options.innerWrapSelector).removeClass(options.hiddenSelector);
      }
    });
  }

  init = function init() {
    getAudiobookCreditBalance();
  };

  init();
};

Kobo.Gizmo.AudiobookCreditBalance.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);