"use strict";

/*global Kobo */
Kobo.Gizmo.LovePointsRedemption = function () {
  'use strict';

  var self = this,
      endRedemption,
      init;
  Kobo.Gizmo.apply(this, arguments);

  endRedemption = function endRedemption() {
    if (self.settings.goBackUrl) {
      Kobo.Utilities.navigateTo(self.settings.goBackUrl);
    } else {
      Kobo.Utilities.goBack();
    }
  };

  init = function init() {
    Kobo._mediator.register('koboLove::redeemCancelled');

    Kobo._mediator.subscribe('koboLove::redeemCancelled', endRedemption);

    Kobo._mediator.register('koboLove::redeemFinished');

    Kobo._mediator.subscribe('koboLove::redeemFinished', endRedemption);
  };

  init();
};

Kobo.Gizmo.LovePointsRedemption.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);