"use strict";

/*global Kobo, ko */
Kobo.Gizmo.ExportLibraryItem = function (gizmo, options) {
  // eslint-disable-line no-unused-vars 
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("ExportLibraryItem");

  var self = this,
      viewModel = {},
      $dialog = Kobo.$('#' + options.templateId),
      $dialogContainer,
      $actionTrigger,
      $closeTrigger,
      setupBindings,
      _closeModal,
      _redirectToExport,
      openModal,
      loadExportDialog,
      fireShowDialogEvent,
      setupHandlers,
      LIBRARY_MODAL_CLASS = 'library-modal',
      init;

  setupBindings = function setupBindings(libraryItem) {
    viewModel.selectedFileType = ko.observable();
    viewModel.showRequiredMessage = ko.observable(false);
    viewModel.selectedFileType.subscribe(function (newValue) {
      // eslint-disable-line no-unused-vars 
      viewModel.showRequiredMessage(false);
    });
    viewModel.imageUrl = ko.observable(libraryItem.imageUrl);
    viewModel.title = ko.observable(libraryItem.title);
    viewModel.downloadUrls = ko.observableArray(libraryItem.downloadUrls);

    if (libraryItem.downloadUrls && libraryItem.downloadUrls.length === 1) {
      viewModel.selectedFileType(libraryItem.downloadUrls[0].drmType + "." + libraryItem.downloadUrls[0].format);
    }
  };

  _closeModal = function closeModal() {
    Kobo._modal.close();

    $closeTrigger.off("click", _closeModal);
  };

  _redirectToExport = function redirectToExport() {
    var exportUrl, downloadUrl, selectedFileTypeElements, drmType, format, i;

    if (!viewModel.selectedFileType()) {
      viewModel.showRequiredMessage(true);
    } else {
      _closeModal();

      $actionTrigger.off("click", _redirectToExport);

      if (viewModel.downloadUrls().length === 1) {
        exportUrl = viewModel.downloadUrls()[0].url;
      } else if (viewModel.downloadUrls().length > 1) {
        selectedFileTypeElements = viewModel.selectedFileType().split(".");
        drmType = selectedFileTypeElements[0];
        format = selectedFileTypeElements[1];

        for (i = 0; i < viewModel.downloadUrls().length; i++) {
          downloadUrl = viewModel.downloadUrls()[i];

          if (downloadUrl.drmType === drmType && downloadUrl.format === format) {
            exportUrl = downloadUrl.url;
            break;
          }
        }
      }
    }

    if (exportUrl) {
      window.location.href = exportUrl;
    }
  };

  openModal = function openModal(settings) {
    $dialogContainer = Kobo.$('<div id="' + settings.dialogId + '">');
    $dialogContainer.html($dialog.html());
    $closeTrigger = $dialogContainer.find('.' + settings.closeClass);
    $closeTrigger.on('click', _closeModal);
    $actionTrigger = $dialogContainer.find('.' + settings.actionClass);
    $actionTrigger.on('click', _redirectToExport);

    Kobo._modal.open($dialogContainer);

    $dialogContainer.closest('#' + settings.modalContentId).addClass(LIBRARY_MODAL_CLASS).addClass(settings.containerClass);
    $actionTrigger.focus();
    setupBindings(settings);
    ko.applyBindings(viewModel, $dialogContainer[0]);
  };

  loadExportDialog = function loadExportDialog(event, data) {
    var id = data.detail.id;

    if (id === self.settings.id) {
      openModal(data.detail);
    }
  };

  fireShowDialogEvent = function fireShowDialogEvent() {
    Kobo._mediator.fire("library::exportFile", self.settings);
  };

  setupHandlers = function setupHandlers() {
    Kobo._mediator.register("library::exportFile");

    Kobo._mediator.subscribe("library::exportFile", loadExportDialog);

    self.$el.on("click", fireShowDialogEvent);
  };

  init = function init() {
    setupHandlers();
  };

  init();
};

Kobo.Gizmo.ExportLibraryItem.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);