"use strict";

/*global Kobo,$,ko,Modernizr,DynamicConfiguration */
Kobo.Gizmo.AuthenticationForgotPassword = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType("AuthenticationForgotPassword");

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.showError = function () {
    self.$errorBox.removeClass(options.hideErrorBoxSelector);
    self.$email.addClass(options.errorSelector);
    self.hideSpinner();
  };

  self.hideError = function () {
    self.$errorBox.addClass(options.hideErrorBoxSelector);
    self.$email.removeClass(options.errorSelector);
  };

  self.submitEmail = function () {
    self.showSpinner();
    self.hideError();
    var enteredEmail = self.$email[0].value;

    if (!enteredEmail || enteredEmail.trim() === '') {
      self.showError();
      return;
    }

    Kobo.Ajax({
      url: options.ctaUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {
        email: enteredEmail
      },
      success: function success(data) {
        if (data === 'undefined' || data.ForgotpasswordResult !== 'Success') {
          self.showError();
        } else {
          window.location.href = options.redirectUrl;
        }

        return;
      },
      error: function error() {
        self.showError();
      }
    });
  };

  self.setupHandlers = function () {
    self.$submit.on('click', self.submitEmail);
    self.$submit.add(self.$email).keypress(function (event) {
      if (event.keyCode === 13) {
        self.submitEmail();
      }
    });
  };

  init = function init() {
    self.$email = self.$gizmo.find('.' + options.emailSelector);
    self.$submit = self.$gizmo.find('.' + options.ctaSelector);
    self.$errorBox = self.$gizmo.find('.' + options.errorBoxSelector);
    self.setupHandlers();
    Kobo.Utilities.iePlaceholder('.' + options.emailSelector, this, false);
  };

  init();
};

Kobo.Gizmo.AuthenticationForgotPassword.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);