"use strict";

/*global Kobo,ko, DynamicConfiguration*/
Kobo.Gizmo.LovePointTransactions = function () {
  "use strict";

  var self = this,
      pointsTable,
      createNoDataElement,
      dataTableConfig,
      showSpinner,
      hideSpinner,
      setupTable,
      customSelect,
      customLabel,
      wrapSelect,
      koboPaging,
      notEnoughPointsText = window.DynamicConfiguration.resourceStrings.notEnoughPoints,
      howToGetPointsText = window.DynamicConfiguration.resourceStrings.howToGetPoints,
      howToGetPointsLink,
      rowCount = 0,
      init;
  Kobo.Gizmo.apply(this, arguments);
  pointsTable = self.$el.find('#point-transactions');

  dataTableConfig = function dataTableConfig(data) {
    // the following variables are built into the DataTables plugin.
    var start = '_START_',
        end = '_END_',
        total = '_TOTAL_',
        menu = '_MENU_';
    return {
      responsive: true,
      "bAutoWidth": false,
      columnDefs: [{
        'targets': 0,
        'width': '2%',
        'render': function render() {
          return ++rowCount;
        }
      }, {
        'targets': 1,
        'width': '10%',
        'render': function render(data) {
          return new Date(data).toLocaleDateString();
        }
      }, {
        'targets': [3, 5],
        className: "highlight tally"
      }, {
        'targets': 3,
        'render': function render(data) {
          var result = data;

          if (result > 0) {
            result = "+ " + result;
          }

          return result;
        }
      }],
      createdRow: function createdRow(row, data) {
        if (data.Points < 0) {
          Kobo.$(row).addClass('redeemed');
        }
      },
      'oLanguage': {
        'sLengthMenu': Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.showingTransactionsDropdown, menu),
        'sInfo': Kobo.Utilities.stringFormat(DynamicConfiguration.resourceStrings.showingStartToEnd, start, end, total)
      },
      processing: true,
      order: [],
      lengthMenu: [[5, 10, 25, -1], [5, 10, 25, window.DynamicConfiguration.resourceStrings.all]],
      data: data.Transactions,
      searching: false,
      columns: [{
        'orderable': false
      }, {
        data: 'Date',
        'orderable': false
      }, {
        data: 'TransactionId',
        'orderable': false
      }, {
        data: 'Points',
        'orderable': false
      }, {
        data: 'Description',
        'orderable': false
      }, {
        data: 'Balance',
        'orderable': false
      }]
    };
  };

  createNoDataElement = function createNoDataElement() {
    var $el = Kobo.$('<div class="no-data">');
    $el.append("<p>" + notEnoughPointsText + "</p>");
    $el.append("<p><a href=" + howToGetPointsLink + ">" + howToGetPointsText + "</a></p>");
    return $el;
  };

  showSpinner = function showSpinner() {
    Kobo._modal.open("<div class ='ajax-spinner'></div>", {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  hideSpinner = function hideSpinner() {
    Kobo._modal.close();
  };

  wrapSelect = function wrapSelect() {
    customSelect = self.$el.find('.dataTables_length select');
    customLabel = self.$el.find('.dataTables_length label');
    customLabel.attr('id', 'point-transactions-label');
    customSelect.attr('aria-labelledby', 'point-transactions-label');
    customSelect.wrap('<div class="custom-select"></div>');
  };

  koboPaging = function koboPaging() {
    $(".dataTables_paginate").wrap("<div class='dataTables_paginate_container'></div>");
    $(".previous").removeClass("paginate_button");
    $(".next").removeClass("paginate_button");
    $(".paginate_button.current").addClass("active");
  };

  setupTable = function setupTable(data) {
    showSpinner();
    var maxRecordsExceeded = data.MaxRecordsExceeded || false;
    howToGetPointsLink = data.HowToGetPointsLink;

    if (data.Transactions.length > 0) {
      Kobo.$(pointsTable).DataTable(dataTableConfig(data));
      wrapSelect();
      koboPaging();
    } else {
      pointsTable.replaceWith(createNoDataElement());
    }

    hideSpinner();
    self.$el.removeClass('hide');
    self.maxRecordsExceeded(maxRecordsExceeded);
  };

  init = function init() {
    self.maxRecordsExceeded = ko.observable();
    setupTable(self.options);
    ko.applyBindings(self, self.el);
  };

  init();
};

Kobo.Gizmo.LovePointTransactions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);