"use strict";

/*globals Kobo, require, ko */
Kobo.Gizmo.InstantPreview = function (el, options) {
  'use strict';

  var self = this,
      defaultSettings = {},
      previewer,
      selectors = {
    templates: {}
  },
      ajaxSpinnerClass = 'ajax-spinner',
      endOfPreviewClass = options.endOfPreviewContentClass,
      customHeaderClass = 'header-wrap-instantpreview',
      customModalClass = 'modal-content-instantpreview',
      previewTitleId = options.previewTitleId,
      customMultibuttonClass = 'multibutton-instantpreview',
      multibuttonSelector = '#multibutton-drop-down',
      readerFrameSelector = null,
      maxContentHeight = 700,
      //We would be performing string matching using the error message since thats the only non-invasive way to get the cause of an error out of readium without touching the code itself.
  readerErrors = {
    ERR_BAD_FORMAT: "File format is not recognized.",
    ERR_HTTP_RANGE: "HTTP Range not supported.",
    ERR_BLOB_DOES_NOT_EXIST: "The specified blob does not exist."
  },
      readerSettings = {
    $: null,
    selectoroverlay: null,
    layoutoptions: {},
    fontoptions: {},
    isVisible: false,
    // show, hide
    columnCount: null
  },
      isNotSupported = false,
      notSupported = {},
      endOfPreview = {},
      events = {},
      bookCompleted = false,
      showingEndOfPreview = false,
      viewModel = {
    'info': {},
    'page': {}
  },
      settings,
      loadPreviewer,
      hideSpinner,
      setReaderHeight,
      setPagelayout,
      determinePagination,
      handlePageChanged,
      hideEndOfPreview,
      handleNotSupported,
      bindProductInfo,
      setupHandlers,
      setupPreviewer,
      init,
      hidePreviewContainer,
      showNotSupported,
      showEndOfPreview,
      loadPreview,
      setupReader,
      setupOptionsPane,
      setupEvents,
      onResize,
      onKeydown,
      setupBindings,
      nextPage,
      prevPage,
      close,
      toggleOptions,
      decreaseFont,
      initBuyButton,
      addBookUnderSubToReadingList,
      addBookUnderSubToReadingListCallback,
      initStartFreeTrialButton,
      initResubscribeButton,
      updateStartFreeTrialLabel,
      increaseFont,
      showPreviewContainer,
      showSpinner,
      handleError,
      setReaderSettingLocation,
      ensureReadingWidth,
      cleanup;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("AjaxButton");
  settings = Kobo.extend(defaultSettings, options);

  loadPreviewer = function loadPreviewer(d) {
    if (isNotSupported) {
      handleNotSupported(null, true);
    } else {
      //Loads Previewer
      switch (d.Previewer) {
        case "ReadiumJS":
          require(['kobo.jsepubreader'], function (p) {
            var context = d.PreviewUrl;
            readerFrameSelector = '#epub-reader-frame';
            previewer = p;
            previewer.LoadUI(context, {
              containerSelector: selectors.readingArea,
              onReaderInitialized: function onReaderInitialized() {
                var bp = Kobo._mediator.currentBreakpoint();

                setPagelayout(bp === 'small' || bp === 'medium' ? 1 : 2);
                setReaderHeight();
                bookCompleted = false;
                showingEndOfPreview = false;
                hideSpinner();
              },
              onReaderLoaded: function onReaderLoaded($iframe) {
                var ifdoc = $iframe[0].contentDocument;
                Kobo.$(ifdoc).on('click', function (e) {
                  determinePagination(e.pageX, $iframe.width() * 0.5);
                }); //epubContentIframe

                var iframeContentWindow = $iframe[0].contentWindow;
                Kobo.$(iframeContentWindow).on('focus', function () {
                  $iframe.addClass("focus-visible");
                });
                Kobo.$(iframeContentWindow).on('blur', function () {
                  $iframe.removeClass("focus-visible");
                });
                Kobo.Utilities.findAllFocusableElements(ifdoc.body).attr('tabindex', 0).on('focus', function (e) {
                  $iframe.removeClass("focus-visible");
                  Kobo.$(e.target).addClass("focus-visible");
                }).on('blur', function (e) {
                  Kobo.$(e.target).removeClass("focus-visible");
                });
              },
              onReaderPageChanged: function onReaderPageChanged(pageChangedData) {
                handlePageChanged(pageChangedData);
              },
              onReaderNextPage: function onReaderNextPage() {
                if (previewer && previewer.NextPage && !showingEndOfPreview) {
                  if (events.onNextPage) {
                    events.onNextPage();
                  }

                  return true;
                }

                return false;
              },
              onReaderPrevPage: function onReaderPrevPage() {
                if (showingEndOfPreview) {
                  hideEndOfPreview();

                  if (events.onNextPage) {
                    events.onPrevPage();
                  }

                  return true;
                }

                if (previewer && previewer.PreviousPage) {
                  if (events.onNextPage) {
                    events.onPrevPage();
                  }

                  return true;
                }

                return false;
              },
              onReaderError: function onReaderError(error) {
                handleNotSupported(error);
              },
              useRangeHeaders: settings.useRangeHeaders
            });
          }, function (err) {
            handleNotSupported(err, true);
          });

          break;

        default:
          break;
      }
    }

    bindProductInfo(d);
  };

  bindProductInfo = function bindProductInfo(d) {
    // Remove non-appropriate sections
    if (d.IsInKoboPlus) {
      Kobo.$(selectors.nonSubsArea).remove();

      if (!d.HasUserCancelledSubscription && (d.IsUserSubscribed || d.SubscriptionType === 'KoboPlusListen')) {
        Kobo.$(selectors.subsNonMemberArea).remove();

        if (d.SubscriptionType === 'KoboPlusListen') {
          Kobo.$(selectors.subsReadArea).remove();
          Kobo.$(selectors.subsReadListenArea).remove();

          if (d.isExternalSubscription) {
            Kobo.$(selectors.subsListenArea).remove();
          } else {
            Kobo.$(selectors.subsExternalListenArea).remove();
          }
        } else if (d.SubscriptionType === 'KoboPlusReadAndListen') {
          Kobo.$(selectors.subsListenArea).remove();
          Kobo.$(selectors.subsReadArea).remove();
          Kobo.$(selectors.subsExternalListenArea).remove();
        } else if (d.SubscriptionType === 'KoboPlusRead') {
          Kobo.$(selectors.subsReadListenArea).remove();
          Kobo.$(selectors.subsListenArea).remove();
          Kobo.$(selectors.subsExternalListenArea).remove();
        }
      } else {
        Kobo.$(selectors.subsMemberArea).remove();

        if (d.HasUserCancelledSubscription && d.IsUserSubscribed) {
          Kobo.$(selectors.startFreeTrialContainer).remove();
          Kobo.$(selectors.resubscribeContainer).remove();
        } else if (d.HasUserCancelledSubscription && !d.IsUserSubscribed) {
          Kobo.$(selectors.subsCancelledActiveArea).remove();
          Kobo.$(selectors.startFreeTrialContainer).remove();
        } else {
          Kobo.$(selectors.subsCancelledActiveArea).remove();
          Kobo.$(selectors.resubscribeContainer).remove();
        }
      }
    } else {
      Kobo.$(selectors.subsArea).remove();
    } //Setup Instant Previewer at the same time.


    if (d.ItemActionsMarkup) {
      initBuyButton(d.ItemActionsMarkup);
    } //Setup subscriptions buttons


    if (d.AddToReadingListUrl) {
      self.settings.AddToReadingListUrl = d.AddToReadingListUrl;
      self.settings.ProductId = d.ProductId;
      self.settings.SubscriptionCrossRevisionId = d.SubscriptionCrossRevisionId;
      self.settings.contentDownloadEventData = d.ContentDownloadEventData;
      Kobo.$(selectors.addToReadingListButton).on('click', addBookUnderSubToReadingList);
    } //Setup free trial options


    if (d.StartFreeTrialUrl) {
      initStartFreeTrialButton(d.StartFreeTrialUrl);
    } //Setup resubscribe options


    if (d.ResubscribeUrl) {
      initResubscribeButton(d.ResubscribeUrl);
    }

    updateStartFreeTrialLabel(d.FreeTrialDurationInWords);
    viewModel.info({
      'title': d.Title.length > 50 ? d.Title.substring(0, 50) + "..." : d.Title,
      'author': d.Author.length > 25 ? d.Author.substring(0, 25) + "..." : d.Author,
      'imageurl': d.TitleImageUrl,
      'track': '{ "productId": "' + d.ProductId + '", "section": "instantPreview" }'
    }); // setup the price areas

    var $priceArea = Kobo.$(selectors.mainPriceArea),
        $eopPriceArea = Kobo.$(selectors.eopPriceArea),
        $el = Kobo.$('<div class="price-wrap"></div>'),
        $koboLovePrice = Kobo.$('<p class="price kobo-love-price">' + d.KoboLovePrice + '</p>'),
        $regularPriceEl = Kobo.$('<p class="price">' + d.DisplayPrice + '</p>'),
        $koboSubscriptionsPrice = Kobo.$('<p class="subs-price">' + d.DisplayPrice + '</p>'),
        $discountNotice = Kobo.$('<p class="amount-saved">' + d.KoboLoveDiscountLabel + '</p>');

    if (d.IsKoboLove) {
      $el.addClass('two-lines');
      $el.append($koboLovePrice).append($discountNotice);
      $priceArea.prepend($el);
      $eopPriceArea.prepend($el.clone());
    } else if (d.IsKoboSubscriptions) {
      $el.append($koboSubscriptionsPrice);
      $priceArea.prepend($el);
      $eopPriceArea.prepend($el.clone());
    } else {
      $el.append($regularPriceEl);
      $priceArea.prepend($el);
      $eopPriceArea.prepend($el.clone());
    }
  };

  initBuyButton = function initBuyButton(actions) {
    var $buybutton = Kobo.$(selectors.buy),
        $eopBuyButton,
        $addToCart,
        $header;

    if ($buybutton.length > 0) {
      $buybutton.attr('data-kobo-gizmo', 'MultiButton').attr('data-kobo-gizmo-config', '{"menuItems":' + actions + '}').addClass("multi-button-container").addClass("kobo-gizmo");

      Kobo._gizmo.initGizmo($buybutton);
    }

    $eopBuyButton = Kobo.$(endOfPreview.buy);

    if ($eopBuyButton.length > 0) {
      $eopBuyButton.attr('data-kobo-gizmo', 'MultiButton').attr('data-kobo-gizmo-config', '{"menuItems":' + actions + '}').addClass("multi-button-container").addClass("kobo-gizmo");

      Kobo._gizmo.initGizmo($eopBuyButton);
    }

    $addToCart = Kobo.$(multibuttonSelector);
    $header = Kobo.$('header');

    if ($addToCart.length > 0) {
      $addToCart.addClass(customMultibuttonClass);
      $header.addClass(customHeaderClass);
    }
  };

  addBookUnderSubToReadingList = function addBookUnderSubToReadingList() {
    showSpinner();
    self.fire('productAction::addToReadingListUnderSubscription', {
      actionUrl: self.settings.AddToReadingListUrl,
      productId: self.settings.ProductId,
      subscriptionId: self.settings.SubscriptionCrossRevisionId,
      completeCallback: addBookUnderSubToReadingListCallback
    });
  };

  addBookUnderSubToReadingListCallback = function addBookUnderSubToReadingListCallback(result) {
    if (result && result.result === 'success') {
      if (self.settings.contentDownloadEventData) {
        Kobo.event.fireDeviceEvent(self.settings.contentDownloadEventData);
      }
    }
  };

  initStartFreeTrialButton = function initStartFreeTrialButton(url) {
    var $startFreeTrialButton = Kobo.$(selectors.startFreeTrialButton);

    if ($startFreeTrialButton.length > 0) {
      $startFreeTrialButton.attr('href', url);
    }
  };

  initResubscribeButton = function initResubscribeButton(url) {
    var $resubscribeButton = Kobo.$(selectors.resubscribeButton);

    if ($resubscribeButton.length > 0) {
      $resubscribeButton.attr('href', url);
    }
  };

  updateStartFreeTrialLabel = function updateStartFreeTrialLabel(label) {
    var $startFreeTrialLabel = Kobo.$(selectors.startFreeTrialLabel);

    if ($startFreeTrialLabel.length > 0) {
      var $freeTriaElement = Kobo.$(selectors.trialLengthElement);
      $freeTriaElement.html(label);
    }
  };

  hidePreviewContainer = Kobo._modal.close;

  showNotSupported = function showNotSupported() {
    Kobo.$(selectors.content).hide();
    Kobo.$(notSupported.container).show();
  };

  showEndOfPreview = function showEndOfPreview() {
    Kobo.$(selectors.readingArea).hide();
    Kobo.$(selectors.footerArea).hide();
    Kobo.$(endOfPreview.container).show();
    Kobo.$(selectors.next).addClass('disabled');
    showingEndOfPreview = true;
  };

  hideEndOfPreview = function hideEndOfPreview() {
    Kobo.$('.' + endOfPreviewClass).hide();
    Kobo.$(selectors.readingArea).show();
    Kobo.$(selectors.footerArea).show();
    Kobo.$(selectors.next).removeClass('disabled');
    showingEndOfPreview = false;
  };

  handleError = function handleError(error, message) {
    var errorModalOptions = {
      role: 'alertdialog',
      ariaLabel: window.DynamicConfiguration.resourceStrings.GenericErrorMessage
    },
        closeModalButton;
    self.error(error, 'Kobo.Gizmo.Instantpreview', self, {
      msg: message
    });
    close();
    errorModalOptions.customClass = 'error-dialog';

    Kobo._modal.open('<div class="error-content"><button aria-label="' + window.DynamicConfiguration.resourceStrings.close + '" class="close-button"></button><p>' + message + '</p></div>', errorModalOptions);

    closeModalButton = Kobo.$('.close-button');
    closeModalButton.on('click', function () {
      Kobo._modal.close();
    });
  };

  handleNotSupported = function handleNotSupported(error, force) {
    if (force || error === readerErrors.ERR_BAD_FORMAT || error === readerErrors.ERR_HTTP_RANGE || error === readerErrors.ERR_BLOB_DOES_NOT_EXIST) {
      isNotSupported = true;
      hideSpinner();
      showNotSupported();
      Kobo.$(window).off('keydown', onKeydown);
    }
  };

  handlePageChanged = function handlePageChanged(pageChangedData) {
    var openpages = pageChangedData.paginationInfo.openPages,
        pagecount = pageChangedData.paginationInfo.spineItemCount;

    if (openpages[openpages.length - 1].spineItemIndex === pagecount - 1 && openpages[openpages.length - 1].spineItemPageIndex === openpages[openpages.length - 1].spineItemPageCount - 1) {
      if (!bookCompleted && !showingEndOfPreview) {
        showEndOfPreview();
      }

      bookCompleted = true;
    } else {
      bookCompleted = false;
    }

    viewModel.page({
      'currentPage': openpages[openpages.length - 1].spineItemIndex + 1,
      'totalPages': pagecount
    });
  };

  nextPage = function nextPage() {
    previewer.NextPage();
  };

  prevPage = function prevPage() {
    previewer.PreviousPage();
  };

  toggleOptions = function toggleOptions() {
    if (readerSettings.isVisible) {
      Kobo.$(readerSettings.selectoroverlay).removeClass('showmodal');
      Kobo.$("button" + selectors.options).removeClass('on').attr('aria-expanded', false);
      readerSettings.$.hide(0, function () {
        readerSettings.isVisible = !readerSettings.isVisible;
      });
    } else {
      Kobo.$(readerSettings.selectoroverlay).addClass('showmodal');
      Kobo.$("button" + selectors.options).addClass('on').attr('aria-expanded', true);
      readerSettings.$.css('top', Kobo.$(selectors.options).position().top + Kobo.$(selectors.options).height()).css('left', Kobo.$(selectors.options).position().left - readerSettings.$.width()).show(0, function () {
        readerSettings.isVisible = !readerSettings.isVisible;
      });
    }

    setReaderSettingLocation();
  };

  setPagelayout = function setPagelayout(cols) {
    if (previewer && previewer.ShowOnePage && readerSettings.columnCount !== 1 && cols === 1) {
      readerSettings.$.find(readerSettings.layoutoptions.selector).removeClass('selected');
      previewer.ShowOnePage();
      readerSettings.$.find(readerSettings.layoutoptions.selector1col).addClass('selected');
      readerSettings.columnCount = 1;
    } else if (previewer && previewer.ShowTwoPages && readerSettings.columnCount !== 2 && cols === 2) {
      readerSettings.$.find(readerSettings.layoutoptions.selector).removeClass('selected');
      previewer.ShowTwoPages();
      readerSettings.columnCount = 2;
      readerSettings.$.find(readerSettings.layoutoptions.selector2col).addClass('selected');
    }
  };

  decreaseFont = function decreaseFont() {
    if (previewer && previewer.DecreaseFont) {
      previewer.DecreaseFont();
    }
  };

  increaseFont = function increaseFont() {
    if (previewer && previewer.IncreaseFont) {
      previewer.IncreaseFont();
    }
  };

  onResize = function onResize() {
    var screenSize = Kobo._mediator.currentBreakpoint();

    ensureReadingWidth();

    if (readerSettings.columnCount === 2 && (screenSize === 'medium' || screenSize === 'small')) {
      setPagelayout(1);
    } else if (readerSettings.columnCount === 1 && (screenSize === 'large' || screenSize === 'jumbo')) {
      setPagelayout(2);
    }

    setReaderHeight();
    setReaderSettingLocation();
  };

  ensureReadingWidth = function ensureReadingWidth() {
    //nullify inline width to get true width
    Kobo.$(selectors.readingArea).css("width", "auto");
    var w = Kobo.$(selectors.readingArea).width(); //set true width if odd.

    if (w % 2 !== 0) {
      Kobo.$(selectors.readingArea).width(w - 1);
    }
  };

  setReaderHeight = function setReaderHeight() {
    var contentpositiontop = Kobo.$(selectors.content).position().top + Kobo.$(selectors.content).offsetParent().position().top,
        contentheight = Kobo.$(selectors.content).height(),
        windowheight = Kobo.$(window).height(),
        readerheight = Kobo.$(selectors.readingArea).height(),
        spaceneeded = contentheight - readerheight,
        diff; // NOTE: WEB-26719 - FF instant preview gets initialized with too small
    // of a height because something makes the window scroll to the very bottom
    // before all of the calculations you see here. When contentpositiontop is
    // retrieved from jQuery using position(), and its top property is not
    // explicitly set, which is the case here, jQuery returns an object where
    // top === window.pageYOffset. This conditional here is an ugly hack to
    // resolve this issue.

    if (contentpositiontop === window.pageYOffset) {
      contentpositiontop = 0;
    }

    if (windowheight <= contentpositiontop + contentheight) {
      diff = contentpositiontop + contentheight - windowheight;

      if (contentheight - diff > spaceneeded + 100) {
        Kobo.$(selectors.content).height(contentheight - diff);
        Kobo.$(selectors.readingArea).height(readerheight - diff);
        Kobo.$(readerFrameSelector).height(readerheight - diff);
        Kobo.$(endOfPreview.container).height(readerheight - diff);
        Kobo.$(notSupported.container).height(contentheight - diff);
      } else {
        Kobo.$(selectors.content).height(spaceneeded + 100);
        Kobo.$(selectors.readingArea).height(100);
        Kobo.$(readerFrameSelector).height(100);
        Kobo.$(endOfPreview.container).height(100);
        Kobo.$(notSupported.container).height(spaceneeded + 100);
      }
    } else if (contentheight < maxContentHeight) {
      Kobo.$(selectors.content).height(maxContentHeight);
      Kobo.$(selectors.readingArea).height(maxContentHeight - spaceneeded);
      Kobo.$(readerFrameSelector).height(maxContentHeight - spaceneeded);
      Kobo.$(endOfPreview.container).height(maxContentHeight - spaceneeded);
      Kobo.$(notSupported.container).height(maxContentHeight);
    }
  };

  setReaderSettingLocation = function setReaderSettingLocation() {
    var screenSize = Kobo._mediator.currentBreakpoint();

    if (screenSize === 'small' && readerSettings.isVisible) {
      readerSettings.$.css('top', Kobo.$(window).height() - readerSettings.$.height());
    } else if (readerSettings.isVisible) {
      readerSettings.$.css('top', Kobo.$(selectors.options).eq(1).position().top + Kobo.$(selectors.options).eq(1).height()).css('left', Kobo.$(selectors.options).eq(1).position().left - readerSettings.$.width());
    }
  };

  onKeydown = function onKeydown(e) {
    switch (e.keyCode) {
      case 37:
        //Left Key
        prevPage();
        e.preventDefault();
        break;

      case 39:
        //Right Key
        nextPage();
        e.preventDefault();
        break;

      case 27:
        //Exit
        close();
        break;

      default:
        break;
    }
  };

  determinePagination = function determinePagination(pt, halfwaypt) {
    if (pt > halfwaypt) {
      nextPage();
    } else {
      prevPage();
    }
  };

  setupBindings = function setupBindings() {
    viewModel.info = ko.observable({
      'title': '',
      'author': '',
      'imageurl': '',
      'track': '' // information used for click-tracking

    });
    viewModel.page = ko.observable({
      'currentPage': '-',
      'totalPages': '-'
    });
    viewModel.page.subscribe(function () {
      var _viewModel$page = viewModel.page(),
          currentPage = _viewModel$page.currentPage,
          totalPages = _viewModel$page.totalPages;

      if (currentPage === 1 || currentPage === '-') {
        Kobo.$(selectors.prev).attr('disabled', true).attr('aria-disabled', true).addClass("disabled");
      } else {
        Kobo.$(selectors.prev).removeAttr('disabled').removeAttr('aria-disabled').removeClass("disabled");
      }

      if (currentPage === totalPages) {
        Kobo.$(selectors.next).attr('disabled', true).attr('aria-disabled', true).addClass("disabled");
      } else {
        Kobo.$(selectors.next).removeAttr('disabled').removeAttr('aria-disabled').removeClass("disabled");
      }
    });
  };

  setupReader = function setupReader() {
    selectors.templates.container = '#' + options.instantpreviewTemplateId;
    selectors.templates.options = '#' + options.optionsTemplateId;
    selectors.templates.endofpreview = '#' + options.endOfPreviewTemplateId;
    selectors.templates.notsupported = '#' + options.notSupportedTemplateId; //Main Area Selectors

    selectors.content = '#' + options.mainContentId;
    selectors.readingArea = '#' + options.mainReadingAreaId;
    selectors.next = '#' + options.mainNextButtonId;
    selectors.prev = '#' + options.mainPrevButtonId;
    selectors.close = '.' + options.mainCloseButtonClass;
    selectors.options = '.' + options.mainOptionsButtonClass;
    selectors.buy = '#' + options.mainBuyButtonId;
    selectors.mainPriceArea = '#' + options.mainPriceAreaId;
    selectors.eopPriceArea = '#' + options.eopPriceAreaId;
    selectors.footerArea = '.' + options.previewFooterClass; //Subscriptions selectors

    selectors.addToReadingListButton = '#' + options.addToReadingListButtonId;
    selectors.startFreeTrialButton = '#' + options.startFreeTrialButtonId;
    selectors.resubscribeButton = '.' + options.resubscribeButtonId;
    selectors.startFreeTrialLabel = '#' + options.startFreeTrialLabelId;
    selectors.startFreeTrialContainer = '.' + options.startFreeTrialContainer;
    selectors.resubscribeContainer = '.' + options.resubscribeContainer;
    selectors.trialLengthElement = '#' + options.trialLengthId;
    selectors.nonSubsArea = '.' + options.nonSubsClass;
    selectors.subsArea = '.' + options.subsClass;
    selectors.subsNonMemberArea = '.' + options.nonMemberClass;
    selectors.subsMemberArea = '.' + options.memberClass;
    selectors.subsListenArea = '.' + options.listenClass;
    selectors.subsReadArea = '.' + options.readClass;
    selectors.subsReadListenArea = '.' + options.readListenClass;
    selectors.subsExternalListenArea = '.' + options.externalListenClass;
    selectors.subsCancelledActiveArea = '.' + options.cancelledActive; //Reader Settings Selectors

    readerSettings.container = '#' + options.optionsReaderSettingsId;
    readerSettings.selectoroverlay = '#' + options.optionsOverlayId;
    readerSettings.fontoptions.selectorsmall = '#' + options.optionsFontSmallId;
    readerSettings.fontoptions.selectorlarge = '#' + options.optionsFontLargeId;
    readerSettings.layoutoptions.selector = '.' + options.optionsLayoutHeaderClass;
    readerSettings.layoutoptions.selector1col = '#' + options.optionsLayout1ColId;
    readerSettings.layoutoptions.selector2col = '#' + options.optionsLayout1ColId;
    endOfPreview.container = '.' + options.endOfPreviewContentClass;
    endOfPreview.buy = '#' + options.endOfPreviewBuyButtonId;
    endOfPreview.navExempt = '.' + options.endOfPreviewNavExempt;
    notSupported.container = '.' + options.notSupportedContainerClass;
    maxContentHeight = options.maxContentHeight;
    events.onNextPage = options.onNextPage && typeof options.onNextPage === "function" ? options.onNextPage : null;
    events.onPrevPage = options.onPrevPage && typeof options.onPrevPage === "function" ? options.onPrevPage : null;
    ko.cleanNode(el);
    Kobo.$(el).html(Kobo.$(selectors.templates.container).html());
    Kobo.$(selectors.readingArea).after(Kobo.$(selectors.templates.endofpreview).html());
    Kobo.$(endOfPreview.container).hide();
    Kobo.$(selectors.content).after(Kobo.$(selectors.templates.notsupported).html());
    Kobo.$(notSupported.container).hide();
    setupBindings();
    ko.applyBindings(viewModel, el);
  };

  setupOptionsPane = function setupOptionsPane() {
    Kobo.$(selectors.content).before(Kobo.$(selectors.templates.options).html());
    readerSettings.$ = Kobo.$(readerSettings.container);
    readerSettings.$.css('position', 'fixed').hide();
    readerSettings.isVisible = false;
  };

  setupEvents = function setupEvents() {
    Kobo.$(selectors.next).on('click', function () {
      nextPage();
    });
    Kobo.$(selectors.prev).on('click', function () {
      prevPage();
    });
    Kobo.$(selectors.close).on('click', function () {
      close();
    });
    Kobo.$(selectors.options).on('click', function () {
      toggleOptions();
    });
    Kobo.$(endOfPreview.container).on('click', function (e) {
      determinePagination(e.pageX, Kobo.$(endOfPreview.container).width() * 0.5);
    });
    Kobo.$(endOfPreview.navExempt).on('click', function (e) {
      e.stopPropagation();
    });
    Kobo.$(readerSettings.fontoptions.selectorsmall).on('click', function () {
      decreaseFont();
    });
    Kobo.$(readerSettings.fontoptions.selectorlarge).on('click', function () {
      increaseFont();
    });
    Kobo.$(readerSettings.layoutoptions.selector1col).on('click', function () {
      setPagelayout(1);
    });
    Kobo.$(readerSettings.layoutoptions.selector2col).on('click', function () {
      setPagelayout(2);
    }); //If options overlay is clicked on, close options pane

    Kobo.$(readerSettings.selectoroverlay).on('click', function () {
      toggleOptions();
    });
  };

  showSpinner = function showSpinner() {
    Kobo.$(selectors.content).hide();
    Kobo.$(selectors.content).after('<div class="' + ajaxSpinnerClass + '"></div>');
  };

  hideSpinner = function hideSpinner() {
    Kobo.$('.' + ajaxSpinnerClass).remove();
    Kobo.$(selectors.content).show();
  };

  setupPreviewer = function setupPreviewer() {
    setupReader();
    setupOptionsPane();
    setupEvents();
    self.events.onResize(onResize);
    Kobo.$(window).on('keydown', onKeydown);
  };

  showPreviewContainer = function showPreviewContainer() {
    Kobo._modal.open(el, {
      theme: Kobo._modal.themes.INSTANT_PREVIEW,
      customClass: customModalClass,
      ariaLabelledBy: previewTitleId
    });
  };

  loadPreview = function loadPreview(event, data) {
    if (data && data.detail.productId) {
      showPreviewContainer();
      setupPreviewer();
      showSpinner(); //if previewer has already been loaded, destroy or show.

      Kobo.Ajax({
        url: settings.loadInstantPreviewMetadataUrl,
        method: 'GET',
        dataType: 'json',
        data: {
          productId: data.detail.productId
        },
        success: function success(d) {
          if (d.data) {
            loadPreviewer(d.data);
          }
        },
        error: function error(e) {
          // eslint-disable-line no-unused-vars 
          handleError('Data.Ajax.Failure', window.DynamicConfiguration.resourceStrings.errorFailedToRetriveMetadata);
          hideSpinner();
        }
      });
    }
  };

  setupHandlers = function setupHandlers() {
    self.register('instantPreview::load');
    self.subscribe('instantPreview::load', loadPreview);
    self.subscribe('toast::info', close);
    self.subscribe('toast::error', close);
  };

  init = function init() {
    setupHandlers();
  };

  close = function close() {
    //Clean up Multibutton
    var $addToCart = Kobo.$(multibuttonSelector),
        $header;
    $header = Kobo.$('header');

    if ($addToCart.length > 0) {
      $addToCart.removeClass(customMultibuttonClass);
      $header.removeClass(customHeaderClass);
    }

    hidePreviewContainer();
    cleanup();
  };

  cleanup = function cleanup() {
    Kobo.$(selectors.next).off();
    Kobo.$(selectors.prev).off();
    Kobo.$(selectors.close).off();
    Kobo.$(selectors.options).off();
    Kobo.$(readerSettings.fontoptions.selectorsmall).off();
    Kobo.$(readerSettings.fontoptions.selectorlarge).off();
    Kobo.$(readerSettings.layoutoptions.selector1col).off();
    Kobo.$(readerSettings.layoutoptions.selector2col).off();
    Kobo.$(readerSettings.selectoroverlay).off();
    self.events.offResize(onResize);
    Kobo.$(window).off('keydown', onKeydown);
    var $iframe = Kobo.$('#epubContentIframe');
    var iframeContentWindow = $iframe.length && $iframe[0].contentWindow;
    Kobo.$(iframeContentWindow).off('focus', function () {
      $iframe.addClass("focus-visible");
    });
    Kobo.$(iframeContentWindow).off('blur', function () {
      $iframe.removeClass("focus-visible");
    });
    Kobo.$(el).remove();
  };

  self.destroy = function () {
    cleanup();
    self.unSubscribe('instantPreview::load', loadPreview);
    self.unSubscribe('toast::info', close);
    self.unSubscribe('toast::error', close);
  };

  init();
};

Kobo.Gizmo.InstantPreview.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);