"use strict";

/*global Kobo, ko*/
Kobo.Gizmo.SubscriptionPlanSelection = function (element, options) {
  "use strict";

  var self = this,
      init,
      errorBoxElement,
      errorMessageBox = element.querySelector('#error-message-box'),
      targetInputSelector = element.querySelector('#user-reward-claim-input'),
      claimCta = element.querySelector('#user-reward-claim-cta');
  Kobo.Gizmo.apply(this, arguments);
  this.setType("SubscriptionPlanSelection");
  Kobo.$.extend(options, {
    actions: {
      UnableToSwitch: 'UnableToSwitch',
      UnableToSwitchCancelled: 'UnableToSwitchCancelled',
      Success: 'Success',
      Error: 'Error',
      UpgradeToCombined: 'UpgradeToCombined',
      Switch: 'Switch',
      SwitchEbooks: 'SwitchEbooks',
      SwitchAudiobooks: 'SwitchAudiobooks',
      Subscribe: 'Subscribe',
      FreeTrial: 'StartFreeTrial'
    },
    templates: {
      UnableToSwitch: '#plusplans-unavailable-template',
      UnableToSwitchCancelled: '#plusplans-unavailable-cancelled-template',
      Success: '#plusplans-success-template',
      Error: '#plusplans-error-template',
      UpgradeToCombined: '#plusplans-upgrade-template',
      SwitchAudiobooks: '#plusplans-switchaudiobooks-template',
      SwitchEbooks: '#plusplans-switchebooks-template'
    }
  });

  self.destroy = function () {
    self.unSubscribe('SubscriptionPlanSelection::error', self.showError);
    self.unSubscribe('SubscriptionPlanSelection::validateAndStartProcess', self.validateAndStartProcess);
    self.unSubscribe('SubscriptionPlanSelection::updateClaimBox', self.updateState);
  };

  self.setupHandlers = function () {
    self.register('SubscriptionPlanSelection::error');
    self.register('SubscriptionPlanSelection::validateAndStartProcess');
    self.register('SubscriptionPlanSelection::updateClaimBox');
    self.subscribe('SubscriptionPlanSelection::error', self.showError);
    self.subscribe('SubscriptionPlanSelection::validateAndStartProcess', self.validateAndStartProcess);
    self.subscribe('SubscriptionPlanSelection::updateClaimBox', self.updateState);
  };

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.showError = function (errorMessage) {
    errorMessageBox.innerHTML = errorMessage;
    $('#user-reward-claim-input').removeClass("error-box-hide");
    $('#user-reward-claim-input').addClass("error-box");
    self.hideSpinner();
    errorBoxElement.addClass("error-box-display").hide().fadeIn('slow');
  };

  self.hideError = function () {
    errorBoxElement.fadeOut('fast');
    $('#user-reward-claim-input').removeClass("error-box");
    $('#user-reward-claim-input').addClass("error-box-hide");
  };

  self.validateAndStartProcess = function () {
    self.hideError();
    self.showSpinner();
    var selectedPlanId = document.querySelector('input[name="fav_language"]:checked');

    if (selectedPlanId === null || selectedPlanId.value === 'undefined') {
      self.showError(options.selectAPlan);
      return;
    }

    if (typeof targetInputSelector === 'undefined') {
      self.showError(options.invalidVoucherError);
      return;
    }

    var voucherCodeEntered = targetInputSelector.value.trim();

    if (voucherCodeEntered === '') {
      self.showError(options.invalidVoucherError);
      return;
    }

    Kobo.Ajax({
      url: options.validateCampaignUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      data: {
        voucherCode: voucherCodeEntered,
        rewardId: selectedPlanId.value,
        campaignKey: options.campaignKey
      },
      success: function success(data) {
        if (!data) {
          self.showError(options.invalidVoucherError);
          return;
        }

        if (data.isValid) {
          window.location.href = data.redirectTo;
        } else {
          if (data.errorReason === 'alreadyHadATrial') {
            self.showError(options.alreadyHadATrial);
            return;
          } else {
            self.showError(options.invalidVoucherError);
            return;
          }
        }
      },
      error: function error() {
        self.showError(options.invalidVoucherError);
        return;
      }
    });
  };

  init = function init() {
    if (options.isCampaignAvailable) {
      ko.components.register('plan-call-to-action', {
        viewModel: {
          createViewModel: function createViewModel(params, componentInfo) {
            // eslint-disable-line no-unused-vars 
            Kobo.$.extend(params, options);
            return new Kobo.ViewModel.SubscriptionPlanSelectionViewModel(params);
          }
        },
        template: {
          element: 'plan-cta-component'
        }
      });
      errorBoxElement = $('#voucher-error-claim');
      ko.applyBindings(self, element);
      self.setupHandlers();
      claimCta.addEventListener("click", self.validateAndStartProcess);
    }

    window.addEventListener("unload", self.hideSpinner);
  };

  $('.plusplan').click(function () {
    $('.plusplan').removeClass("plan-plus-bg-parent");
    $('.plusplan').addClass("plan-plus-bg");
    $(this).removeClass("plusplanBG");
    $(this).addClass("plan-plus-bg-parent");
    $(this).find('input').prop('checked', true);
  });
  init();
};

Kobo.Gizmo.SubscriptionPlanSelection.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);