"use strict";

/*global Kobo, ko, DynamicConfiguration */
Kobo.Gizmo.NotebookActions = function (el, options) {
  // eslint-disable-line no-unused-vars
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('NotebookActions');
  var self = this,
      viewModel = {},
      selectors = {
    removeNotebookHeading: '#remove-notebook-heading',
    removeNotebookModalBody: '[data-remove-notebook-modal-body]',
    buttons: {
      closeModalButton: '[data-close-button]',
      confirmNotebookRemoval: '[data-confirm-remove-button]'
    }
  },
      templates = {
    removeNotebookModal: '#remove-notebook-template'
  },
      urls = {
    removeNotebook: options.removeNotebookUrl,
    removeAllNotebooks: options.removeAllUserNotebooksUrl
  },
      resources = {
    notebookHasBeenRemovedSuccessfully: options.resources.notebookHasBeenRemovedSuccessfully,
    notebooksHaveBeenRemovedSuccessfully: options.resources.notebooksHaveBeenRemovedSuccessfully,
    genericErrorMessage: options.resources.genericErrorMessage
  };

  var setupBindings = function setupBindings(notebook, isRemoveAllMode) {
    if (notebook) {
      viewModel.id = ko.observable(notebook.id);
      viewModel.etag = ko.observable(notebook.etag);
    }

    viewModel.removed = ko.observable(false);
    viewModel.error = ko.observable(false);
    viewModel.removeAll = ko.observable(isRemoveAllMode);
    viewModel.errorMessage = ko.observable('');
    ko.applyBindings(viewModel, Kobo.$(selectors.removeNotebookModalBody)[0]);
  };

  var removeNotebook = function removeNotebook(event, data) {
    startRemoveNotebookFlow(event, data);
  };

  var removeAllNotebooks = function removeAllNotebooks(event, data) {
    startRemoveNotebookFlow(event, data, true);
  };

  var startRemoveNotebookFlow = function startRemoveNotebookFlow(event, data, isRemoveAllMode) {
    if (data && data.detail && data.detail.notebookId) {
      openRemoveNotebookModal();
      setupBindings({
        id: data.detail.notebookId,
        etag: data.detail.notebookEtag
      }, false);
    }

    if (isRemoveAllMode) {
      openRemoveNotebookModal();
      setupBindings({}, true);
    }
  };

  var openRemoveNotebookModal = function openRemoveNotebookModal() {
    var modalOptions = {
      role: 'alertdialog',
      ariaLabelledBy: selectors.removeNotebookHeading,
      customClass: 'remove-notebook-modal'
    };

    Kobo._modal.open(Kobo.$(templates.removeNotebookModal).html(), modalOptions);

    var closeModalButtons = Kobo.$(selectors.buttons.closeModalButton);
    closeModalButtons.off('click').on('click', closeModal);
    Kobo.$(selectors.removeNotebookModalBody).find(selectors.buttons.confirmNotebookRemoval).off('click').on('click', function () {
      executeNotebookRemoval();
    });
  };

  var executeNotebookRemoval = function executeNotebookRemoval() {
    var removeUrl = viewModel.removeAll() ? urls.removeAllNotebooks : urls.removeNotebook;
    Kobo.Ajax({
      type: 'POST',
      dataType: 'text',
      contentType: 'application/json; charset=utf-8',
      beforeSend: function beforeSend() {
        Kobo.Spinner.showSpinnerOverlay(Kobo.$(selectors.removeNotebookModalBody));
      },
      url: removeUrl,
      headers: {
        __RequestVerificationToken: Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        notebookId: viewModel.id(),
        etag: viewModel.etag()
      })
    }).done(function (response) {
      var responseObject = JSON.parse(response);

      if (responseObject.result === 'failure') {
        handleError({});
      } else {
        handleSuccessfulNotebookRemoval();
      }
    }).fail(handleError);
  };

  var closeModal = function closeModal() {
    Kobo._modal.close();
  };

  var handleError = function handleError(response) {
    viewModel.errorMessage(response && response.message ? response.message : resources.genericErrorMessage);
    viewModel.error(true);
    Kobo.Spinner.hideSpinnerOverlay(Kobo.$(selectors.removeNotebookModalBody));
  };

  var handleSuccessfulNotebookRemoval = function handleSuccessfulNotebookRemoval() {
    viewModel.removed(true);
    sessionStorage.setItem('notebooksGrid.successMessage', viewModel.removeAll() ? resources.notebooksHaveBeenRemovedSuccessfully : resources.notebookHasBeenRemovedSuccessfully);
    window.location.reload();
  };

  var setupHandlers = function setupHandlers() {
    self.register('notebooks::remove');
    self.subscribe('notebooks::remove', removeNotebook);
    self.register('notebooks::removeAll');
    self.subscribe('notebooks::removeAll', removeAllNotebooks);
  };

  var init = function init() {
    setupHandlers();
  };

  init();
};

Kobo.Gizmo.NotebookActions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);