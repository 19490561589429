"use strict";

/*global Kobo, $, ko */
Kobo.Gizmo.NotebookPreview = function (el, options) {
  'use strict';

  var self = this,
      $el = Kobo.$(el),
      viewModel = {},
      selectors = {
    pagination: {
      previousPageContainer: '#notebook-preview-previous-page',
      nextPageContainer: '#notebook-preview-next-page',
      paginationControl: '[data-pagination-control]',
      paginationControlTooltip: '.pagination-button-tooltip',
      slider: '#notebook-preview-pagination-slider',
      sliderInput: 'input[data-pagination-slider]',
      sliderTooltip: '#pagination-slider-tooltip'
    },
    container: '#notebook-preview-template',
    content: '#notebook-preview-content',
    contentWindow: '.notebook-preview-content-window',
    previewContainer: '#notebook-preview-container',
    contentContainer: '#notebook-preview-content-container',
    notebookPage: '[data-notebook-page]',
    close: '.notebook-preview-header-back',
    errorModalWindow: {
      close: '.close-button'
    },
    loadingSpinner: '#notebook-spinner'
  },
      translations = {
    notebookPageNumber: options.noteBookPageNumberTranslation,
    generalErrorMessage: options.generalErrorMessageTranslation
  },
      spinnerAnimationData = 'red-loading-spinner',
      customModalClass = 'modal-content-notebook-preview',
      templates = {
    notSupportedNotebook: '#unmigrated-notebook-template'
  },
      queryParameters = {
    hideTopNav: 'hideTopNav',
    hideNavigation: 'hideNav',
    notebookId: 'nid'
  },
      defaultAnimationDuration = 200;
  Kobo.Gizmo.apply(this, arguments);

  self.destroy = function () {};

  var showPreviewContainer = function showPreviewContainer() {
    Kobo._modal.open(el, {
      theme: Kobo._modal.themes.INSTANT_PREVIEW,
      customClass: customModalClass
    });

    ko.cleanNode(Kobo.$(selectors.previewContainer)[0]);
    ko.applyBindings(viewModel, Kobo.$(selectors.previewContainer)[0]);
    $el.show();
  };

  var setupBindings = function setupBindings() {
    viewModel.notebookName = ko.observable('');
    viewModel.selectedPageIndex = ko.observable(0);
    viewModel.pagesCount = ko.observable(1);
    viewModel.notebookId = ko.observable('');
    viewModel.notebookETag = ko.observable('');
    viewModel.notebookContentType = ko.observable('');
    viewModel.notebookPages = ko.observableArray([]);
    viewModel.isLoading = ko.observable(false);
    viewModel.isHideNavigationMode = ko.observable(false);
    viewModel.selectedPageIndex.subscribe(updateSliderUi);
  };

  var showSpinner = function showSpinner() {
    Kobo.$(selectors.loadingSpinner).remove();
    Kobo.$(selectors.content).after("<div id=\"".concat(selectors.loadingSpinner.slice(1), "\"></div>"));
    window.lottie.loadAnimation({
      container: Kobo.$(selectors.loadingSpinner)[0],
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Kobo.LottiePlayer.AnimationObject[spinnerAnimationData]
    });
  };

  var hideSpinner = function hideSpinner() {
    Kobo.$(selectors.loadingSpinner).remove();
  };

  var setupHandlers = function setupHandlers() {
    self.register('notebookPreview::load');
    self.subscribe('notebookPreview::load', loadPreview);
  };

  var hidePreviewContainer = Kobo._modal.close;

  var cleanup = function cleanup() {
    viewModel.selectedPageIndex(0);
    viewModel.isLoading(false);
    viewModel.notebookPages([]);
    viewModel.notebookContentType('');
    viewModel.notebookETag('');
    Kobo.$(selectors.close).off();
    Kobo.$(selectors.pagination.nextPageContainer).off();
    Kobo.$(selectors.pagination.previousPageContainer).off();
    Kobo.$(selectors.pagination.sliderInput).off();
    ko.cleanNode(el);
    Kobo.$(selectors.contentContainer).empty();
    Kobo.$(selectors.loadingSpinner).remove();
    Kobo.$(el).off();
    Kobo.$(el).remove();
    removeNotebookQueryParameterFromUrl();
  };

  var close = function close() {
    cleanup();
    hidePreviewContainer();
  };

  var loadPreview = function loadPreview(event, data) {
    if (data && data.detail.notebookId) {
      viewModel.isHideNavigationMode(data.detail.hideNavigation);

      if (data.detail.canBePreviewed === 'False') {
        handleUnsupportedNotebook();
        return;
      }

      addNotebookQueryParameterToUrl(data.detail.notebookId, data.detail.pushToHistoryState);
      viewModel.notebookId(data.detail.notebookId);
      viewModel.pagesCount(1);
      viewModel.selectedPageIndex(0);
      viewModel.isLoading(false);
      showPreviewContainer();
      setupModalWindowEvents();
      showSpinner();
      loadMetadata(function (d) {
        if (!d.data.CanBePreviewed) {
          handleUnsupportedNotebook();
        } else {
          viewModel.notebookETag(d.data.ETag);
          viewModel.notebookContentType(d.data.ContentType);
          viewModel.notebookName(d.data.DisplayName);
          viewModel.pagesCount(d.data.TotalPages);
          loadPage(0, handleInitialLoad);
        }
      }, function () {
        handleError();
      });
    }
  };

  var setupNotebookPreviewer = function setupNotebookPreviewer() {
    setupPaginationUiEvents();
    selectPage(0);
    hideSpinner();
    handlePaginationElementsSize();
  };

  var buildNotebookPageIFrame = function buildNotebookPageIFrame(notebookContent, index) {
    var notebookIFrameId = "notebook-page-iframe-".concat(index);
    var notebookIFrameAriaLabel = translations.notebookPageNumber.replace('{0}', index + 1);
    var notebookIFrameTemplate = $("<iframe \n                data-notebook-page\n                data-notebook-page-index='".concat(index, "'\n                id=\"").concat(notebookIFrameId, "\"\n                aria-label=\"").concat(notebookIFrameAriaLabel, "\"\n                tabindex=\"-1\"\n                height=500 />"));
    Kobo.$(selectors.contentContainer).append(notebookIFrameTemplate);
    var notebookIFrame = Kobo.$('#' + notebookIFrameId);
    var notebookIFrameElement = notebookIFrame[0];

    if (notebookIFrameElement && notebookIFrameElement.contentDocument) {
      var idoc = notebookIFrameElement.contentDocument;
      idoc.open();

      try {
        idoc.write(notebookContent);
      } catch (error) {// the only possible error is that original HTML of notebook is bad formatted
        // in this case we can ignore it.
      }

      idoc.close();
    }

    registerIFrameSwipeEvents(notebookIFrame); // TODO: revisit setTimeout. Might be possible to avoid it

    setTimeout(function () {
      resizeIFrameToFitContent(notebookIFrameElement);
    }, 0);
  };

  var loadMetadata = function loadMetadata(successCallback, errorCallback) {
    Kobo.Ajax({
      url: "".concat(options.loadNotebookMetadataUrl, "?notebookId=").concat(viewModel.notebookId()),
      method: 'GET',
      dataType: 'json',
      success: successCallback,
      error: errorCallback
    });
  };

  var loadPage = function loadPage(pageIndex, successCallback) {
    if (viewModel.isLoading()) {
      return;
    }

    showSpinner();
    viewModel.isLoading(true);
    Kobo.Ajax({
      url: "".concat(options.loadNotebookContentUrl, "?notebookId=").concat(viewModel.notebookId(), "&page=").concat(pageIndex, "&etag=").concat(viewModel.notebookETag()),
      method: 'GET',
      dataType: 'json',
      success: function success(d) {
        if (d.result === 'failure') {
          onError();
        } else {
          successCallback(d);
          hideSpinner();
          viewModel.isLoading(false);
        }
      },
      error: function error() {
        onError();
      }
    });
  };

  var onError = function onError() {
    handleError('Data.Ajax.Failure', translations.generalErrorMessage);
    hideSpinner();
    viewModel.isLoading(false);
  };

  var handleInitialLoad = function handleInitialLoad(httpResponse) {
    if (httpResponse.data) {
      // load and show the first page
      buildNotebookPageIFrame(httpResponse.data.notebookContent, 0);
      viewModel.notebookPages.push({
        index: 0,
        isLoaded: true
      });
      setupNotebookPreviewer();
    }
  };

  var ensurePageIsLoaded = function ensurePageIsLoaded(pageIndex, callback) {
    var notebookPage = ko.utils.arrayFilter(viewModel.notebookPages(), function (page) {
      return page.index === pageIndex && page.isLoaded;
    })[0];

    if (!notebookPage) {
      loadPage(pageIndex, function (d) {
        buildNotebookPageIFrame(d.data.notebookContent, pageIndex);
        viewModel.notebookPages.push({
          index: pageIndex,
          isLoaded: true
        });
        callback();
      });
    } else {
      callback();
    }
  };

  var selectPage = function selectPage(pageIndex) {
    ensurePageIsLoaded(pageIndex, function () {
      viewModel.selectedPageIndex(pageIndex);
      var notebookPages = Kobo.$(selectors.notebookPage);
      notebookPages.each(function () {
        var $notebookPage = $(this);

        if ($notebookPage.data('notebookPageIndex') === pageIndex) {
          $notebookPage.show();
          setTimeout(function () {
            resizeIFrameToFitContent($notebookPage[0]);
          }, 0);
        } else {
          $notebookPage.hide();
        }
      });
    });
  };

  var resizeIFrameToFitContent = function resizeIFrameToFitContent(iFrame) {
    var iframeHeightBuffer = 60;

    if (!iFrame.contentWindow) {
      return;
    } // basic notebooks have to be scaled to fit their height to viewport


    if (options.basicNotebookContentTypes.indexOf(viewModel.notebookContentType()) !== -1) {
      var currentHeight = iFrame.contentWindow.document.body.scrollHeight;
      var viewportHeight = Kobo.$(selectors.contentWindow).prop('scrollHeight');

      if (currentHeight > viewportHeight) {
        iFrame.style.width = (viewportHeight - iframeHeightBuffer) / currentHeight * 100 + '%';
      }
    }

    iFrame.height = iFrame.contentWindow.document.body.scrollHeight + iframeHeightBuffer;
  };

  var setupModalWindowEvents = function setupModalWindowEvents() {
    setupOnEnterOnSpacebarAndOnClickEvent(Kobo.$(selectors.close), handleCloseWithBrowserState);
  };

  var handleCloseWithBrowserState = function handleCloseWithBrowserState() {
    // if history state is not empty, then this page was opened by user clicking on notebook card.
    // thus we have to clean it from browser history stack.
    if (history.state) {
      history.back();
    } else {
      close();
    }
  };

  var setupPaginationUiEvents = function setupPaginationUiEvents() {
    Kobo.$("".concat(selectors.pagination.previousPageContainer, ", ").concat(selectors.pagination.nextPageContainer)).on('mouseenter focus', function () {
      var paginationControl = $(this).find(selectors.pagination.paginationControl);
      var paginationControlTooltip = $(this).find(selectors.pagination.paginationControlTooltip);
      fadeInAndShowElements(paginationControl);
      setTimeout(function () {
        fadeInAndShowElements(paginationControlTooltip);
      }, defaultAnimationDuration);
    });
    Kobo.$("".concat(selectors.pagination.previousPageContainer, ", ").concat(selectors.pagination.nextPageContainer)).on('mouseleave blur', function () {
      var paginationControl = $(this).find(selectors.pagination.paginationControl);
      var paginationControlTooltip = $(this).find(selectors.pagination.paginationControlTooltip);
      fadeOutAndHideElements(paginationControl);
      fadeOutAndHideElements(paginationControlTooltip);
    });
    Kobo.$(selectors.pagination.previousPageContainer).on('click', goToPreviousPage);
    Kobo.$(selectors.pagination.nextPageContainer).on('click', goToNextPage);
    Kobo.$(selectors.pagination.slider).find(selectors.pagination.sliderInput).off('keydown').on('keydown', function (e) {
      e.preventDefault();
    }).off('change').on('change', function () {
      selectPage(Math.round($(this).val()) - 1);
      Kobo.Gizmo.Slider.updateSlider($(this));
    }).off('input').on('input', function () {
      Kobo.Gizmo.Slider.updateSlider($(this));
      var targetPageNumber = Math.round($(this).val() - 1);

      if (targetPageNumber !== viewModel.selectedPageIndex()) {
        selectPage(targetPageNumber, true);
      }
    }).off('mousemove').on('mousemove', function (e) {
      var $tooltip = Kobo.$(selectors.pagination.sliderTooltip);
      $tooltip.show();
      var rect = e.target.getBoundingClientRect();
      var offset = e.pageX - rect.left; // placing the tooltip over the cursor, on the slider

      $tooltip.css('left', offset + 'px');
      $tooltip.text(1 + Math.round((offset - 8) / e.target.clientWidth * (viewModel.pagesCount() - 1)));
    }).off('mouseout').on('mouseout', function () {
      var $tooltip = Kobo.$(selectors.pagination.sliderTooltip);
      $tooltip.hide();
    });
    Kobo.$(selectors.contentContainer).swipe({
      swipeLeft: function swipeLeft() {
        goToNextPage();
      },
      swipeRight: function swipeRight() {
        goToPreviousPage();
      }
    });
    Kobo.$('#modal').off('keydown').on('keydown', handleOnKeyDownEvent);
  };

  var handlePaginationElementsSize = function handlePaginationElementsSize() {
    var previousPage = Kobo.$(selectors.pagination.previousPageContainer);
    var nextPage = Kobo.$(selectors.pagination.nextPageContainer);
    var notebookContent = Kobo.$(selectors.contentContainer);
    var windowWidth = Kobo.$(window).width();
    var paginationElementWidth = (windowWidth - notebookContent.width()) / 2;
    var clickableAreaPercentage = 0.8;
    previousPage.width(paginationElementWidth * clickableAreaPercentage);
    nextPage.width(paginationElementWidth * clickableAreaPercentage);
  };

  var registerIFrameSwipeEvents = function registerIFrameSwipeEvents($iframe) {
    $iframe.contents().find('body').swipe({
      preventDefaultEvents: false,
      swipeLeft: function swipeLeft() {
        goToNextPage();
      },
      swipeRight: function swipeRight() {
        goToPreviousPage();
      }
    }).off('keydown').on('keydown', function (e) {
      handleOnKeyDownEvent(e);
    });
  };

  var handleOnKeyDownEvent = function handleOnKeyDownEvent(event) {
    // On TAB show all navigation buttons to make them visible to screen-reader.
    if (event.keyCode === Kobo.KeyCodes.TAB) {
      showAllNavigationButtons();
    } // on ESCAPE - modal window is closed, clean is needed.


    if (event.keyCode === Kobo.KeyCodes.ESC) {
      handleCloseWithBrowserState();
    }

    handleArrowKeyEvents(event);
  };

  var handleArrowKeyEvents = function handleArrowKeyEvents(event) {
    // on ARROW LEFT - go to the previous page.
    if (event.keyCode === Kobo.KeyCodes.LEFT) {
      goToPreviousPage();
    } // on ARROW RIGHT - go to the next page.


    if (event.keyCode === Kobo.KeyCodes.RIGHT) {
      goToNextPage();
    }
  };

  var goToPreviousPage = function goToPreviousPage() {
    if (viewModel.selectedPageIndex() !== 0) {
      selectPage(viewModel.selectedPageIndex() - 1);
    }
  };

  var goToNextPage = function goToNextPage() {
    if (viewModel.selectedPageIndex() !== viewModel.pagesCount() - 1) {
      selectPage(viewModel.selectedPageIndex() + 1);
    }
  };

  var updateSliderUi = function updateSliderUi(newValue) {
    var sliderInput = Kobo.$(selectors.pagination.slider).find(selectors.pagination.sliderInput);
    sliderInput.val(newValue + 1);
    Kobo.Gizmo.Slider.updateSlider(sliderInput);
  };

  var showAllNavigationButtons = function showAllNavigationButtons() {
    fadeInAndShowElements($el.find(selectors.pagination.paginationControl));
  };

  var fadeOutAndHideElements = function fadeOutAndHideElements($element) {
    $element.fadeTo(defaultAnimationDuration, 0);
  };

  var fadeInAndShowElements = function fadeInAndShowElements($element) {
    $element.fadeTo(defaultAnimationDuration, 1);
  };

  var handleError = function handleError(error, message) {
    var errorModalOptions = {
      role: 'alertdialog',
      ariaLabel: window.DynamicConfiguration.resourceStrings.GenericErrorMessage
    },
        closeModalButton;
    close();
    errorModalOptions.customClass = 'error-dialog';

    Kobo._modal.open('<div class="error-content"><button aria-label="' + window.DynamicConfiguration.resourceStrings.close + '" class="close-button"></button><p>' + message + '</p></div>', errorModalOptions);

    closeModalButton = Kobo.$(selectors.errorModalWindow.close);

    if (viewModel.isHideNavigationMode()) {
      closeModalButton.remove();
    } else {
      closeModalButton.on('click', function () {
        Kobo._modal.close();
      });
    }
  };

  var handleUnsupportedNotebook = function handleUnsupportedNotebook() {
    var modalOptions = {
      role: 'alertdialog',
      ariaLabel: window.DynamicConfiguration.resourceStrings.GenericErrorMessage
    },
        closeModalButton;
    close();
    modalOptions.customClass = 'unsuported-notebook-dialog';
    var notSupportedNotebookTemplate = Kobo.$(Kobo.$(templates.notSupportedNotebook).html());

    if (viewModel.isHideNavigationMode()) {
      notSupportedNotebookTemplate.find(selectors.errorModalWindow.close).remove();
    }

    Kobo._modal.open(notSupportedNotebookTemplate.prop('outerHTML'), modalOptions);

    closeModalButton = Kobo.$(selectors.errorModalWindow.close);
    closeModalButton.on('click', function () {
      Kobo._modal.close();
    });
  };

  var setupEvents = function setupEvents() {
    Kobo.$(window).on('resize', function () {
      handlePaginationElementsSize();
      var notebookPages = Kobo.$(selectors.notebookPage);
      notebookPages.each(function () {
        var $notebookPage = $(this);
        resizeIFrameToFitContent($notebookPage[0]);
      });
    });
    window.onpopstate = handleWindowPopStateEvent;
  };

  var handleWindowPopStateEvent = function handleWindowPopStateEvent(event) {
    if (event.state.notebooksGridOpened) {
      close();
    }
  };

  var setupOnEnterOnSpacebarAndOnClickEvent = function setupOnEnterOnSpacebarAndOnClickEvent($element, eventHandler) {
    $element.click(eventHandler);
    $element.on('keydown', function (e) {
      var keyD = e.key !== undefined ? e.key : e.keyCode;

      if (keyD === 'Enter' || keyD === 13 || ['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32) {
        e.preventDefault();
        this.click();
      }
    });
  };

  var addNotebookQueryParameterToUrl = function addNotebookQueryParameterToUrl(notebookId, pushToHistoryState) {
    var url = new URL(location);
    url.searchParams.set(queryParameters.notebookId, notebookId);

    if (pushToHistoryState) {
      history.pushState({
        notebooksGridOpened: false
      }, '', url);
    }
  };

  var removeNotebookQueryParameterFromUrl = function removeNotebookQueryParameterFromUrl() {
    var url = new URL(location);
    url.searchParams["delete"](queryParameters.notebookId);
    history.replaceState({
      notebooksGridOpened: true
    }, '', url);
  };

  var init = function init() {
    setupBindings();
    setupHandlers();
    setupEvents();
    var urlParams = new URLSearchParams(window.location.search);
    var notebookIdParam = urlParams.get(queryParameters.notebookId);
    var hideNavigation = urlParams.has(queryParameters.hideTopNav) || urlParams.has(queryParameters.hideNavigation);

    if (notebookIdParam) {
      // notebookId is present in query parameters, proceed with loading.
      Kobo._mediator.fire('notebookPreview::load', {
        notebookId: notebookIdParam,
        hideNavigation: hideNavigation,
        pushToHistoryState: false
      });
    } else {
      var url = new URL(location);
      history.replaceState({
        notebooksGridOpened: true
      }, '', url);
    }
  };

  init();
};

Kobo.Gizmo.NotebookPreview.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);