"use strict";

// Gizmo written by Sean Team 42
// Adds check box to background of a Facet List <li> When Selected
Kobo.Gizmo.FacetCheckBox = function () {
  'use strict';

  var self = this;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("FacetCheckBox");

  function init() {
    var $items = self.$el.find('.facet-list-view-widget');
    var key = 'fclanguages';
    var languageFacet = $items.parent('ul[data-field-name="' + key + '"]');
    $items.each(function () {
      var $this = Kobo.$(this);

      if (languageFacet.length) {
        $this.click(function () {
          var preferred = {
            preferred: $this.find('a').attr('href').match(key + '=.*').toString().replace(key + '=', '')
          };
          var cookieVal = decodeURIComponent($.param(preferred));
          Cookies.set('searchlanguage', cookieVal, {
            expires: BotTracker.addDays(new Date(), 31),
            path: '/',
            raw: true
          });
        });
      }
    });
  }

  init();
};

Kobo.Gizmo.FacetCheckBox.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);