"use strict";

/*global Kobo, window */
Kobo.Gizmo.KoboLoveAutoRenewal = function () {
  "use strict";

  var self = this,
      bindEvents,
      isAutoRenewOn,
      init,
      postToServer,
      toggleButtonUsingServerResponse,
      markOddAndEvenRows,
      $toggleButton,
      $toggleOnLabel,
      $toggleOffLabel,
      $autoRenewalOffMessage,
      $autoRenewalOnMessage;
  Kobo.Gizmo.apply(this, arguments);

  bindEvents = function bindEvents($context) {
    $toggleButton.on('click', function () {
      postToServer($context.find('.toggle-section'), self.settings.setAutoRenewalUrl, {
        newAutoRenewalStatus: !isAutoRenewOn
      }, "POST");
    });
  };

  init = function init() {
    isAutoRenewOn = Kobo.Object.parseBool(self.settings.isAutoRenewOn);
    $toggleButton = self.$gizmo.find('.toggle-button');
    $toggleOnLabel = self.$gizmo.find('.toggle-on');
    $toggleOffLabel = self.$gizmo.find('.toggle-off');
    $autoRenewalOffMessage = self.$gizmo.find('.auto-renewal-off-message');
    $autoRenewalOnMessage = self.$gizmo.find('.auto-renewal-on-message');

    if (isAutoRenewOn === true) {
      $toggleButton.toggleClass('toggle-button-selected');
      $toggleOffLabel.toggleClass('hide');
      $autoRenewalOffMessage.toggleClass('hide');
    } else {
      $toggleOnLabel.toggleClass('hide');
      $autoRenewalOnMessage.toggleClass('hide');
    }

    markOddAndEvenRows();
    bindEvents(self.$gizmo);

    Kobo._mediator.register('koboLove::autoRenewToggled');
  };

  toggleButtonUsingServerResponse = function toggleButtonUsingServerResponse() {
    $toggleButton.toggleClass('toggle-button-selected');
    $toggleOffLabel.toggleClass('hide');
    $toggleOnLabel.toggleClass('hide');
    $autoRenewalOffMessage.toggleClass('hide');
    $autoRenewalOnMessage.toggleClass('hide');
  };

  postToServer = function postToServer($destination, url, data, httpMethod) {
    httpMethod = httpMethod || 'GET';
    $destination.children().hide();
    Kobo.Spinner.showSpinner($destination);
    return Kobo.$.ajax({
      method: httpMethod,
      url: url,
      data: data,
      dataType: 'json',
      cache: false,
      success: function success(response) {
        if (response.AutoRenewalStatusResponse !== undefined) {
          if (isAutoRenewOn !== response.AutoRenewalStatusResponse) {
            isAutoRenewOn = response.AutoRenewalStatusResponse;
            toggleButtonUsingServerResponse();
            markOddAndEvenRows();

            Kobo._mediator.fire('koboLove::autoRenewToggled', {
              isAutoRenewOn: isAutoRenewOn
            });
          }
        }
      },
      complete: function complete() {
        Kobo.Spinner.hideSpinner($destination);
        $destination.children().show();
      }
    });
  }; // The number of visible rows in the table is dynamic, but we want the styling to alternate on
  // even and odd rows. Css isn't capable of handling this (doesn't restyle when rows
  // hidden/shown) so we need to use javascript.


  markOddAndEvenRows = function markOddAndEvenRows() {
    var $autoRenewalTable = self.$gizmo.find('.auto-renewal-table');
    $autoRenewalTable.find('td:visible:odd').toggleClass('even', false);
    $autoRenewalTable.find('td:visible:even').toggleClass('even', true);
  };

  Kobo._mediator.events.onResize(function (event) {
    if (event.currentSize === 'small') {
      markOddAndEvenRows();
    }
  });

  init();
};

Kobo.Gizmo.KoboLoveAutoRenewal.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);