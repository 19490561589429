"use strict";

/*global Kobo, $ */
Kobo.Gizmo.PreviewLibraryItem = function (el, options) {
  'use strict';

  var $el = Kobo.$(el),
      launchInstantPreview,
      triggerAttribute = "[data-action-preview-trigger]",
      init;
  Kobo.Gizmo.apply(this, arguments);

  launchInstantPreview = function launchInstantPreview() {
    if (options.devicePreviewUrl) {
      Kobo.Utilities.navigateTo(options.devicePreviewUrl);
    } else {
      Kobo._mediator.fire('instantPreview::load', {
        productId: options.productId
      });
    }
  };

  init = function init() {
    if ($el.is(triggerAttribute)) {
      $el.on("click", launchInstantPreview);
    }

    $el.find(triggerAttribute).on("click", launchInstantPreview);
  };

  init();
};

Kobo.Gizmo.PreviewLibraryItem.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);