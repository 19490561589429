"use strict";

/*globals Kobo,ko,DynamicConfiguration,Cookies */
Kobo.Gizmo.AudiobookCreditBalanceBanner = function (el, options) {
  'use strict';

  var self = this,
      viewModel,
      setAudiobookCreditBalance,
      setCookie,
      getCookie,
      updateCookie,
      isBannerDismissed,
      setInitialVisibility,
      handleSmallSearch,
      init;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("AudiobookCreditBalanceBanner");

  setCookie = function setCookie(data) {
    var cookieOptions = {
      expires: 365,
      path: '/',
      raw: true
    };
    Cookies.set('AudiobookCreditBalanceCookie', JSON.stringify(data), cookieOptions);
  };

  getCookie = function getCookie() {
    var cookie = Cookies.get('AudiobookCreditBalanceCookie');
    return cookie ? JSON.parse(cookie) : null;
  };

  updateCookie = function updateCookie() {
    var storedBannerSettings = getCookie();
    var currentCreditBalance = viewModel.creditBalance();
    var isDismissed = false;

    if (storedBannerSettings) {
      // we should unhide the banner if the stored credit count is lower than our new credit count
      var previousCreditBalance = storedBannerSettings.creditBalance;
      isDismissed = storedBannerSettings.isDismissed && previousCreditBalance >= currentCreditBalance;
      viewModel.isDismissed(isDismissed);
    }

    setCookie({
      isDismissed: isDismissed,
      creditBalance: currentCreditBalance
    });
  };

  viewModel = new Kobo.ViewModel.AudiobookCreditBalanceBannerVM({
    hasHadAudiobooksSubscription: options.hasHadAudiobooksSubscription,
    hasAudiobooksSubscription: options.hasAudiobooksSubscription,
    getMoreCreditsUrl: options.getMoreCreditsUrl,
    setCookie: setCookie
  }); // setAudiobookCreditBalance is a callback to the audiobookCreditBalance::get event.
  // It receives the new credit balance and then updates the viewModel accordingly.

  setAudiobookCreditBalance = function setAudiobookCreditBalance(event, data) {
    if (data !== null && data.detail !== null && data.detail.creditAmount !== null) {
      viewModel.creditBalance(data.detail.creditAmount);
      viewModel.creditsInitialized(true);
      updateCookie();
      setInitialVisibility();
    }
  }; // returns true if the banner has been dismissed, false if there are no cookie to check or if the banner hasn't been dismissed


  isBannerDismissed = function isBannerDismissed() {
    var setting = getCookie();
    return !!setting && setting.isDismissed;
  };

  setInitialVisibility = function setInitialVisibility() {
    // there's a requirement to cover up daily deals, so originally we were going
    // to absolutely position the banner wrapper on the page, directly below
    // the header. But when the "Get the free app" banner shows up on the page,
    // this banner gets hidden. The fastest, cleanest way to solve this is to
    // update the _SmartBanner.cshtml file and add the audiobook-banner-container
    // element to the pushSelector parameters.
    //
    // However, we now have a new requirement to ensure that the banner is sticky, 
    // because the initial workaround is something that Business got used to. This
    // doesn't play nicely with the new countryStore banner too. So, what we need to
    // do is use CSS classes to shuffle stuff around, and if this banner is not meant
    // to be displayed, then add .hidden to its parent Widget div.
    //
    // This way, we don't need to care if the daily deals banner is present.
    if (viewModel.isHidden()) {
      Kobo.$(el).parent().addClass('hidden');
    } else {
      Kobo.$(el).parent().removeClass('hidden');
    }
  }; // This adjusts the behaviour of the search bar in small view, which needs to push the daily deal
  // below the actual bar. When the search bar is toggled, we add .search-bar-open to the parent
  // widget div, and then handle the appearance in CSS.


  handleSmallSearch = function handleSmallSearch(event, data) {
    if (data.detail && data.detail.name === 'searchBar') {
      switch (event.type) {
        case 'collapsible::open':
          Kobo.$(el).parent().addClass('search-bar-open');
          break;

        case 'collapsible::close':
          Kobo.$(el).parent().removeClass('search-bar-open');
          break;

        case 'collapsible::toggle':
          Kobo.$(el).parent().toggleClass('search-bar-open');
          break;

        default:
          // Do nothing, we don't recognize this event (this is probably a programmer error)
          break;
      }
    }
  };

  init = function init() {
    Kobo._mediator.subscribe('audiobookCreditBalance::get', setAudiobookCreditBalance);

    Kobo._mediator.subscribe('collapsible::toggle collapsible::open collapsible::close', handleSmallSearch);

    var bannerIsDismissed = isBannerDismissed();
    viewModel.isDismissed(bannerIsDismissed);
  };

  ko.applyBindings(viewModel, self.el);
  init();
  setInitialVisibility();

  self.destroy = function () {
    Kobo._mediator.unSubscribe('audiobookCreditBalance::get', setAudiobookCreditBalance);

    Kobo._mediator.unSubscribe('collapsible::toggle collapsible::open collapsible::close', handleSmallSearch);
  };
};

Kobo.Gizmo.AudiobookCreditBalanceBanner.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);