"use strict";

/*global Kobo,$*/
if (Kobo.Gizmo.FacetList === undefined) {
  Kobo.Gizmo.FacetList = {};
}

Kobo.Gizmo.FacetList.Filter = function (gizmo, options) {
  "use strict";

  var self = this;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("FacetList.Filter");

  self.dispatchFilterChangedEvent = function () {
    var fieldName = self.$gizmo.data("field-name"),
        fieldValue = Kobo.$(this).data("field-value");
    self.fire("facet-filter-changed", {
      fieldName: fieldValue
    });
  };

  self.setupHandlers = function () {//        self.$gizmo.find('li').on("click", self.dispatchFilterChangedEvent);
  };

  self.init = function () {
    self.setupHandlers();
  };

  self.init();
};

Kobo.Gizmo.FacetList.Filter.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);