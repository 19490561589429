"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.SubscriptionsManage = function (element, options) {
  "use strict";

  var self = this,
      cancelModalVM = {},
      skipModalVM = {},
      modalSubscriptions = [],
      $cancelDialog,
      $skipDialog,
      $cancelDialogContainer,
      $skipDialogContainer,
      $closeModal,
      $mainActionTrigger,
      $undoActionTrigger,
      $cancelModalTrigger,
      $skipModalTrigger,
      isCancelAudiobook,
      cancelPopupData,
      getData,
      getContainerClass,
      getDialogId,
      getTemplateId,
      getActionClass,
      setupCancelEBookModal,
      setupCancelAudiobookModal,
      openCancellationModal,
      openSkipNextBillingModal,
      closeCancellationModal,
      _closeCancellationEBookModal,
      _closeSkipNextBillingModal,
      cancelEBookSubscription,
      continueToShowEBookCancellationReasons,
      skipNextMonthCredit,
      callCancelEBookSubscription,
      callSkipNextMonthCredit,
      setupCancelEBookModalBindings,
      setupSkipModalBindings,
      setupCancelEBookModalTriggers,
      setupHandlers,
      setupTriggers,
      SUBS_MODAL_CLASS = 'subs-modal',
      init;

  var ACTIONS = {
    cancelEBookSubscription: 0,
    skipAMonth: 1,
    cancelAudiobookSubscription: 2
  };
  Kobo.Gizmo.apply(this, arguments);
  this.setType("SubscriptionsManage");
  /* #Region: Skip a Month */

  setupSkipModalBindings = function setupSkipModalBindings(popupData, isCancellationDeflection) {
    skipModalVM.stage = ko.observable("start");
    skipModalVM.isCancellationDeflection = isCancellationDeflection;
  };

  _closeSkipNextBillingModal = function closeSkipNextBillingModal() {
    $closeModal.off('click', _closeSkipNextBillingModal);
    $mainActionTrigger.off('click', skipNextMonthCredit);
    $undoActionTrigger.off('click', _closeSkipNextBillingModal);

    Kobo._modal.close();

    if (skipModalVM.stage() === 'skipped') {
      Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
      window.location = window.location.href;
    }
  };

  callSkipNextMonthCredit = function callSkipNextMonthCredit() {
    Kobo._mediator.fire('subscription::skip');
  };

  openSkipNextBillingModal = function openSkipNextBillingModal(elem, isCancellationDeflection) {
    $skipDialog = Kobo.$('#' + getTemplateId(ACTIONS.skipAMonth));

    if ($skipDialog.length) {
      var popupData = getData(elem);

      if (popupData !== undefined) {
        var skipNextMonthDialogId = getDialogId(ACTIONS.skipAMonth);
        $skipDialogContainer = Kobo.$('<div id="' + skipNextMonthDialogId + '">');
        $skipDialogContainer.html($skipDialog.html()); // load data

        setupSkipModalBindings(popupData, isCancellationDeflection);
        ko.applyBindings(skipModalVM, $skipDialogContainer[0]); // set up click events
        // close button

        $closeModal = $skipDialogContainer.find('.' + options.closeClass);
        $closeModal.on('click', _closeSkipNextBillingModal); // skip next month subscription

        $mainActionTrigger = $skipDialogContainer.find('.' + getActionClass(ACTIONS.skipAMonth));
        $mainActionTrigger.on('click', function () {
          callSkipNextMonthCredit();
        }); // stay in current billing

        $undoActionTrigger = $skipDialogContainer.find('.' + options.undoSkipNextBill);
        $undoActionTrigger.on('click', _closeSkipNextBillingModal);
        $skipDialogContainer.on('keypress', function (e) {
          // Enter key press
          if (e.which == 13 && e.target.classList.contains(options.undoSkipNextBill)) {
            _closeSkipNextBillingModal();
          }
        }); // display modal

        Kobo._modal.open($skipDialogContainer);

        $skipDialogContainer.closest('#' + options.modalContentId).addClass(SUBS_MODAL_CLASS).addClass(getContainerClass(ACTIONS.skipAMonth)); // focus on action button

        $mainActionTrigger.focus();
      }
    }
  };

  skipNextMonthCredit = function skipNextMonthCredit() {
    var url = Kobo.$(element).find('.' + options.skipMonthButtonClass).data('popup-skipmonthlurl');
    Kobo.Ajax({
      type: 'POST',
      url: url,
      data: {
        isCancellationDeflection: skipModalVM.isCancellationDeflection
      },
      beforeSend: function beforeSend() {
        Kobo.Spinner.showSpinnerOverlay(Kobo.$('.skip-next-month-subscription-dialog'));
      },
      success: function success(response) {
        if (response.IsSuccess === true) {
          skipModalVM.stage("skipped");
        } else {
          skipModalVM.stage("error");
        }
      },
      error: function error() {
        skipModalVM.stage("error");
      },
      complete: function complete() {
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$('.skip-next-month-subscription-dialog'));
        Kobo.$(element).find('.' + options.skipMonthButtonClass).hide();
      }
    });
  };
  /* #Endregion */

  /* #Region: Cancel EBook Subscription */


  setupCancelEBookModalBindings = function setupCancelEBookModalBindings(data) {
    // reset
    cancelModalVM = {};
    cancelModalVM.base = ko.observable(true);
    cancelModalVM.showMemberBenefits = ko.observable(false);
    cancelModalVM.cancelled = ko.observable(false);
    cancelModalVM.skipped = ko.observable(false);
    cancelModalVM.title = ko.observable(data.title);
    cancelModalVM.subscriptionId = ko.observable(data.subscriptionId);
    cancelModalVM.imgUrl = ko.observable(data.imageUrl);
    cancelModalVM.reasonCode = ko.observable();
    cancelModalVM.otherReasonText = ko.observable('');
    cancelModalVM.errorNoReasonSelection = ko.observable(false);
    cancelModalVM.errorNoReasonOther = ko.observable(false);
    cancelModalVM.isBook = ko.observable(false);

    if (data.cancelUrl) {
      cancelModalVM.cancelUrl = ko.observable(data.cancelUrl);
    }

    if (data.isBook) {
      cancelModalVM.isBook = ko.observable(data.isBook);
    }

    if (data.isAudiobook) {
      cancelModalVM.base(false);
      cancelModalVM.showMemberBenefits(true);
    }

    cancelModalVM.shouldRenderMemberBenefits = ko.pureComputed(function () {
      return !cancelModalVM.base() && cancelModalVM.showMemberBenefits();
    });
    cancelModalVM.shouldRenderSubscriptionCancel = ko.pureComputed(function () {
      return !cancelModalVM.cancelled() && !cancelModalVM.base() && !cancelModalVM.showMemberBenefits();
    });
    cancelModalVM.showReasons = ko.observable(data.isAudiobook || data.isBook);
    cancelModalVM.cancelRequestBody = ko.pureComputed(function () {
      if (cancelModalVM.showReasons()) {
        return {
          reason: cancelModalVM.reasonCode() === 'Other' ? cancelModalVM.otherReasonText() : cancelModalVM.reasonCode()
        };
      }

      return null;
    });
  };

  _closeCancellationEBookModal = function closeCancellationEBookModal() {
    $closeModal.off('click', _closeCancellationEBookModal);
    $mainActionTrigger.off('click', cancelEBookSubscription);
    $undoActionTrigger.off('click', _closeCancellationEBookModal);
    closeCancellationModal(cancelModalVM.cancelled());
  };

  callCancelEBookSubscription = function callCancelEBookSubscription() {
    if (cancelModalVM.showReasons()) {
      cancelModalVM.errorNoReasonSelection(false);
      cancelModalVM.errorNoReasonOther(false);

      if (!cancelModalVM.reasonCode || cancelModalVM.reasonCode() === '') {
        cancelModalVM.errorNoReasonSelection(true);
        return;
      } else if (cancelModalVM.reasonCode() === 'Other' && (!cancelModalVM.otherReasonText || cancelModalVM.otherReasonText() === '')) {
        cancelModalVM.errorNoReasonOther(true);
        return;
      }
    }

    Kobo._mediator.fire('subscription::cancel');
  };

  setupCancelEBookModal = function setupCancelEBookModal(popupData) {
    // load data
    setupCancelEBookModalBindings(popupData);
  };

  setupCancelEBookModalTriggers = function setupCancelEBookModalTriggers() {
    if (!$cancelDialogContainer) return; // set up click events
    // close button

    $closeModal = $cancelDialogContainer.find('.' + options.closeClass);
    $closeModal.on('click', _closeCancellationEBookModal); // cancel subscription

    $mainActionTrigger = $cancelDialogContainer.find('.' + getActionClass(ACTIONS.cancelEBookSubscription));
    $mainActionTrigger.on('click', function () {
      callCancelEBookSubscription();
    }); // undo cancellation process

    $undoActionTrigger = $cancelDialogContainer.find('.' + options.continueCancellationActionClass);
    $undoActionTrigger.on('click', function () {
      continueToShowEBookCancellationReasons();
    }); // stay subscribed

    $undoActionTrigger = $cancelDialogContainer.find('.' + options.undoClass);
    $undoActionTrigger.on('click', _closeCancellationEBookModal);
  };

  cancelEBookSubscription = function cancelEBookSubscription() {
    var cancelUrl = cancelModalVM.cancelUrl();
    Kobo.Ajax({
      type: 'POST',
      url: cancelUrl,
      data: cancelModalVM.cancelRequestBody(),
      beforeSend: Kobo.Spinner.showSpinnerOverlay(Kobo.$('.cancel-subscription-dialog')),
      success: function success(response) {
        if (response.IsSuccess === true) {
          cancelModalVM.cancelled(true);
        } else {
          cancelModalVM.cancelled(false);
        }
      },
      error: function error() {
        cancelModalVM.cancelled(false);
      },
      complete: function complete() {
        cancelModalVM.base(false);
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$('.cancel-subscription-dialog'));
      }
    });
  };

  continueToShowEBookCancellationReasons = function continueToShowEBookCancellationReasons() {
    cancelModalVM.base(true);
    cancelModalVM.showMemberBenefits(false);
  };
  /* #Endregion */

  /* #Region: Cancel Audiobook Subscriptions */


  setupCancelAudiobookModal = function setupCancelAudiobookModal(popupData, stepsData) {
    cancelModalVM = new AudiobookSubscriptionsCancelVM({
      steps: stepsData.stepsConfig.length,
      stepsConfig: stepsData.stepsConfig,
      reasons: stepsData.reasons,
      subscriptionName: popupData.title,
      subscriptionId: popupData.subscriptionId,
      cancelUrl: popupData.cancelUrl,
      renewUrl: popupData.renewUrl,
      canSkipMonth: popupData.canSkipMonth,
      isKoboPlusEnabled: options.isKoboPlusEnabled
    });
    var closeSubs = cancelModalVM.closeDialogEvent.subscribe(function (wasCancelled) {
      closeCancellationModal(wasCancelled);
    });
    var skipSubs = cancelModalVM.skipAMonthEvent.subscribe(function (subscriptionId) {
      closeCancellationModal();
      var selectedItem = Kobo.$.find("[data-popup-subscription-id='".concat(subscriptionId, "']"));
      openSkipNextBillingModal(selectedItem, true);
    });
    modalSubscriptions.push(closeSubs, skipSubs);
  };
  /* #Endregion */


  closeCancellationModal = function closeCancellationModal(wasCancelled) {
    if (modalSubscriptions) {
      modalSubscriptions.forEach(function (subs) {
        return subs.dispose();
      });
    }

    Kobo._modal.close();

    $cancelDialogContainer = null;

    if (wasCancelled) {
      Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
      window.location = window.location.href;
    }
  };

  openCancellationModal = function openCancellationModal(elem) {
    cancelPopupData = getData(elem);

    if (!cancelPopupData) {
      return;
    }

    var action = isCancelAudiobook() ? ACTIONS.cancelAudiobookSubscription : ACTIONS.cancelEBookSubscription;
    var dialogID = getDialogId(action);
    var templateID = getTemplateId(action);
    $cancelDialog = Kobo.$('#' + templateID);

    if (!$cancelDialog || $cancelDialog.length === 0) {
      return;
    }

    closeCancellationModal(); // find modal template and load

    $cancelDialogContainer = Kobo.$('<div id="' + dialogID + '">');
    $cancelDialogContainer.html($cancelDialog.html());

    if (isCancelAudiobook()) {
      var dialogConfig = $cancelDialogContainer.find('.' + options.contentClass).data();
      setupCancelAudiobookModal(cancelPopupData, dialogConfig);
    } else {
      setupCancelEBookModal(cancelPopupData);
      setupCancelEBookModalTriggers();
    }

    ko.applyBindings(cancelModalVM, $cancelDialogContainer[0]); // open modal

    Kobo._modal.open($cancelDialogContainer);

    $cancelDialogContainer.closest('#' + options.modalContentId).addClass(SUBS_MODAL_CLASS).addClass(getContainerClass(action));

    if (cancelPopupData.ariaDialog) {
      $cancelDialogContainer.closest('#' + options.modalContentId).attr('aria-label', cancelPopupData.ariaDialog.replace('{0}', cancelPopupData.title));
    } // focus on main action button


    $mainActionTrigger = $cancelDialogContainer.find('.' + getActionClass(action));
    $mainActionTrigger.focus(); // ABridal: force redraw of the close button to fix weird bug

    $('.modal-x').css('padding', '1px');
  };

  getData = function getData(elem) {
    if (elem === undefined) {
      return null;
    }

    var $elem = Kobo.$(elem);
    var popupData = {
      title: $elem.data('popup-title'),
      subscriptionId: $elem.data('popup-subscription-id'),
      imageUrl: $elem.data('popup-img')
    };

    if ($elem.data('popup-cancelurl') && $elem.data('popup-cancelurl').length > 0) {
      popupData.cancelUrl = $elem.data('popup-cancelurl');
    }

    if ($elem.data('popup-ariadialog') && $elem.data('popup-ariadialog').length > 0) {
      popupData.ariaDialog = $elem.data('popup-ariadialog');
    }

    popupData.isBook = false;

    if ($elem.data('popup-book') && $elem.data('popup-book').length > 0) {
      popupData.isBook = $elem.data('popup-book');
    }

    popupData.isAudiobook = false;

    if ($elem.data('popup-audiobook') && $elem.data('popup-audiobook').length > 0) {
      popupData.isAudiobook = $elem.data('popup-audiobook');
    }

    if ($elem.data('popup-fc')) {
      popupData.isFCEnabled = $elem.data('popup-fc');
    }

    if ($elem.data('popup-renewurl')) {
      popupData.renewUrl = $elem.data('popup-renewurl');
    }

    popupData.canSkipMonth = false;

    if ($elem.data('popup-canskip')) {
      popupData.canSkipMonth = $elem.data('popup-canskip');
    }

    return popupData;
  };

  getContainerClass = function getContainerClass(action) {
    return options.containerClass.split(',')[action];
  };

  getDialogId = function getDialogId(action) {
    return options.dialogId.split(',')[action];
  };

  getTemplateId = function getTemplateId(action) {
    return options.templateId.split(',')[action];
  };

  getActionClass = function getActionClass(action) {
    return options.actionClass.split(',')[action];
  }; // Use the audiobook cancellation flow if it's an Audiobooks subscription and the FC is enabled


  isCancelAudiobook = function isCancelAudiobook() {
    if (!cancelPopupData) {
      return false;
    }

    return options.useAudiobookCancellationFlow === 'True' && cancelPopupData.isAudiobook;
  };

  setupHandlers = function setupHandlers() {
    Kobo._mediator.register('subscription::cancel');

    Kobo._mediator.subscribe('subscription::cancel', cancelEBookSubscription);

    Kobo._mediator.register('subscription::skip');

    Kobo._mediator.subscribe('subscription::skip', skipNextMonthCredit);
  };

  setupTriggers = function setupTriggers() {
    $cancelModalTrigger = Kobo.$(element).find('.' + options.cancelButtonClass);
    $cancelModalTrigger.on('click', function (e) {
      var selectedItem = Kobo.$(e.target).closest('.' + options.subscriptionItemClass);
      openCancellationModal(selectedItem);
    });
    $skipModalTrigger = Kobo.$(element).find('.' + options.skipMonthButtonClass);
    $skipModalTrigger.click(function (e) {
      var selectedItem = Kobo.$(e.target).closest('.' + options.subscriptionItemClass);
      openSkipNextBillingModal(selectedItem);
    });
  };

  init = function init() {
    setupHandlers();
    setupTriggers();
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('subscription::cancel', cancelEBookSubscription);

    Kobo._mediator.unSubscribe('subscription::skip', skipNextMonthCredit);
  };

  init();
};

Kobo.Gizmo.SubscriptionsManage.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);