"use strict";

Kobo.Gizmo.EnterCodePromotionalReward = function (el, options) {
  var self = this,
      inputElement,
      errorBoxElement,
      inputWrapperElement,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType("EnterCodePromotionalReward");

  var adjustToPlaceholder = function adjustToPlaceholder() {
    var offset = 5,
        // Firefox tends to cutoff the text slightly
    calculatorElem = Kobo.$('<span>' + inputElement.attr('placeholder') + '</span>').css({
      'display': 'none',
      'font-family': inputElement.css('font-family'),
      'font-size': inputElement.css('font-size'),
      'padding-left': inputElement.css('padding-left'),
      'padding-right': inputElement.css('padding-right'),
      'margin-left': inputElement.css('margin-left'),
      'margin-right': inputElement.css('margin-right')
    }).appendTo('body');
    inputWrapperElement.width(calculatorElem.outerWidth(true) + offset);
    calculatorElem.remove();
    inputElement.show('slow');
  };

  self.showError = function () {
    errorBoxElement.fadeIn('slow');
    inputElement.addClass(options.errorInputClass);
  };

  self.setUpHandlers = function () {
    self.register('enterCodePromotionalReward::error');
    self.subscribe('enterCodePromotionalReward::error', self.showError); // trigger claim reward event when user presses return

    inputElement.keypress(function (event) {
      if (event.keyCode === 13) {
        self.fire('userRewards::claimReward');
      }
    });
  };

  self.destroy = function () {
    self.unSubscribe('enterCodePromotionalReward::error', self.showError);
  };

  init = function init() {
    inputElement = Kobo.$('#' + options.inputSelectorId);
    errorBoxElement = Kobo.$('.' + options.errorSelector);
    inputWrapperElement = Kobo.$('.' + options.inputWrapperSelector);
    self.setUpHandlers(); // resize the wrapper element according to the placeholder. Small hack to allow for long translations.

    adjustToPlaceholder();
  };

  init();
};

Kobo.Gizmo.EnterCodePromotionalReward.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);