"use strict";

/*global Kobo*/

/**
 * Kobo.Gizmo.ViewMore
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.ViewMore = function () {
  'use strict';

  var self = this,
      init,
      onResize,
      sizeCheck,
      showParagraphLines = 5,
      showMobileParagraphLines = 20;
  Kobo.Gizmo.apply(this, arguments);

  sizeCheck = function sizeCheck() {
    var lineHeight = parseFloat(getComputedStyle(self.el.querySelector('.preview-view-more')).lineHeight);
    var maxHeight = window.innerWidth > 568 ? lineHeight * showParagraphLines : lineHeight * showMobileParagraphLines;

    if (self.el.querySelector('.preview-view-more > div').offsetHeight <= maxHeight) {
      self.el.classList.add('show-full');
    } else {
      self.el.classList.remove('show-full');
    }
  };

  onResize = Kobo.Utilities.Events.debounce(sizeCheck);

  self.destroy = function () {
    Kobo.$window.off('resize', onResize);
  };

  init = function init() {
    self.el.querySelector('.toggle-view-more').addEventListener('click', function () {
      self.el.classList.toggle('show-full');
    });
    self.el.querySelector('.toggle-view-less').addEventListener('click', function () {
      self.el.classList.toggle('show-full');
    });
    Kobo.$window.on('resize', onResize);
    sizeCheck();
  };

  init();
};

Kobo.Gizmo.ViewMore.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);