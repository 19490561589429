"use strict";

/*global Kobo,$,ko,Modernizr,DynamicConfiguration */
Kobo.Gizmo.UserRewardSelector = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType("UserRewardSelector");

  self.stickyRelocate = function () {
    var windowTop = Kobo.$(window).scrollTop();
    var divTop = self.$gizmo.find('#' + options.stickyAnchorId).offset().top;

    if (windowTop > divTop) {
      self.$promoCodeBox.addClass(options.promoBoxFloatSelector);
    } else {
      self.$promoCodeBox.removeClass(options.promoBoxFloatSelector);
    }
  };

  self.setupHandlers = function () {
    Kobo.$(window).resize(function () {
      self.stickyRelocate();
    });
    Kobo.$(window).on('scroll', self.stickyRelocate);
  };

  init = function init() {
    self.$promoCodeBox = self.$gizmo.find('.' + options.stickyBoxSelector);
    self.$rewardList = self.$gizmo.find('.' + options.rewardsListSelector);
    self.setupHandlers();
    self.selectorViewModel = new Kobo.ViewModel.UserRewardSelector(self.$rewardList, self.options, self.$gizmo);
    self.claimViewModel = new Kobo.ViewModel.UserRewardSelectorClaim(self.$promoCodeBox, self.options, self.$gizmo);
  };

  init();
};

Kobo.Gizmo.UserRewardSelector.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);