"use strict";

Kobo.Gizmo.UserRewards = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType('UserRewards');

  self.showSpinner = function () {
    Kobo._modal.open('<div class="ajax-spinner"></div>', {
      theme: Kobo._modal.themes.LIGHT
    });
  };

  self.hideSpinner = function () {
    Kobo._modal.close();
  };

  self.HideErrors = function () {
    $("#invalid_vouchercode").fadeOut('fast');
    $("#limit_Reached").fadeOut('fast');
    var inputElem = Kobo.$('#' + self.inputElementSelector);
    inputElem.removeClass("error");
  };

  self.showError = function () {
    $("#invalid_vouchercode").fadeIn('slow');
    var inputElem = Kobo.$('#' + self.inputElementSelector);
    inputElem.addClass("error");
  };

  self.showlimitError = function () {
    $("#limit_Reached").fadeIn('slow');
    var inputElem = Kobo.$('#' + self.inputElementSelector);
    inputElem.addClass("error");
  };

  self.triggerGaEvent = function () {
    self.$gizmo.append(self.gaEvent);
  };

  self.claimReward = function () {
    self.HideErrors();

    if (typeof self.isUserAuthenticated === 'undefined' || self.isUserAuthenticated.toLowerCase().trim() === 'false') {
      window.location.href = self.claimRewardUrl;
    }

    var inputElem = Kobo.$('#' + self.inputElementSelector);

    if (typeof inputElem === 'undefined') {
      self.showError();
      return;
    }

    var voucherCodeEntered = inputElem.val().trim();

    if (voucherCodeEntered === '') {
      self.showError();
      return;
    }

    self.showSpinner();
    self.triggerGaEvent();
    Kobo.Ajax({
      url: self.claimRewardUrl,
      method: 'POST',
      dataType: 'json',
      data: {
        voucherCode: voucherCodeEntered
      },
      success: function success(data) {
        if (typeof data === 'undefined' || data.isInvalidCode && data.redirectTo === '') {
          self.showError();
          self.hideSpinner();
          return;
        }

        if (typeof data === 'undefined' || data.limitReached && data.redirectTo === '') {
          self.showlimitError();
          self.hideSpinner();
          return;
        }

        if (data.redirectTo !== '') {
          window.location.href = data.redirectTo;
        }
      },
      error: function error() {
        self.showError();
        self.hideSpinner();
      }
    });
  };

  self.setupHandlers = function () {
    self.register('userRewards::claimReward');
    self.subscribe('userRewards::claimReward', self.claimReward);
    Kobo.$('#' + self.ctaSelectorId).on('click', self.claimReward);
  };

  self.destroy = function () {
    self.unSubscribe('userRewards::claimReward', self.claimReward);
  };

  init = function init() {
    self.gaEvent = options.GAEvent;
    self.ctaSelectorId = options.eventTriggerId;
    self.claimRewardUrl = options.claimRewardUrl;
    self.isUserAuthenticated = options.userAuthenticated;
    self.inputElementSelector = options.targetInputSelector;
    self.setupHandlers();
  };

  init();
};

Kobo.Gizmo.UserRewards.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);