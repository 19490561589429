"use strict";

/*global Kobo, ko, DynamicConfiguration */
Kobo.Gizmo.CollectionActions = function (el, options) {
  // eslint-disable-line no-unused-vars
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('CollectionActions');

  var self = this,
      renameViewModel = {},
      removeViewModel = {},
      formatIdSelector = function formatIdSelector(prefix, name) {
    return '#' + prefix + '-' + name;
  },
      selectors = {
    renameCollectionHeading: formatIdSelector(options.idPrefix, 'rename-collection-heading'),
    removeCollectionHeading: formatIdSelector(options.idPrefix, 'remove-collection-heading'),
    actionsContainer: formatIdSelector(options.idPrefix, 'collection-actions'),
    removeCollectionModalContainer: formatIdSelector(options.idPrefix, 'removeCollectionModalContainer'),
    renameCollectionModalContainer: formatIdSelector(options.idPrefix, 'renameCollectionModalContainer'),
    buttons: {
      openRemoveCollectionModalButton: formatIdSelector(options.idPrefix, 'btnOpenRemoveCollectionModal'),
      openRenameCollectionModalButton: formatIdSelector(options.idPrefix, 'btnOpenRenameCollectionModal'),
      okRenameModalButton: formatIdSelector(options.idPrefix, 'btnConfirmRenameCollection'),
      closeRenameModalButton: formatIdSelector(options.idPrefix, 'btnCancelRenameCollection'),
      okRemoveModalButton: formatIdSelector(options.idPrefix, 'btnConfirmRemoveCollection'),
      closeRemoveModalButton: formatIdSelector(options.idPrefix, 'btnCancelRemoveCollection')
    }
  },
      templates = {
    renameCollectionModal: formatIdSelector(options.idPrefix, 'section-rename-collection-template'),
    removeCollectionModal: formatIdSelector(options.idPrefix, 'section-remove-collection-template')
  },
      urls = {
    collectionRenameReload: options.collectionReloadUrl,
    collectionRemoveReload: options.collectionsReloadUrl,
    collectionRename: options.renameCollectionUrl,
    collectionRemove: options.removeCollectionUrl
  };

  var getCollectionRemovalRequest = function getCollectionRemovalRequest() {
    return {
      type: 'POST',
      dataType: 'text',
      contentType: 'application/json; charset=utf-8',
      url: urls.collectionRemove,
      headers: {
        __RequestVerificationToken: Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        collectionId: removeViewModel.id()
      }),
      beforeSend: function beforeSend() {
        Kobo.Spinner.showSpinnerOverlay(Kobo.$(templates.removeCollectionModal));
      }
    };
  };

  var getCollectionRenameRequest = function getCollectionRenameRequest() {
    return {
      type: 'POST',
      dataType: 'text',
      contentType: 'application/json; charset=utf-8',
      url: urls.collectionRename,
      headers: {
        __RequestVerificationToken: Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify({
        collectionId: renameViewModel.id(),
        name: renameViewModel.name()
      }),
      beforeSend: function beforeSend() {
        Kobo.Spinner.showSpinnerOverlay(Kobo.$(templates.renameCollectionModal));
      }
    };
  };

  var executeCollectionRemoval = function executeCollectionRemoval() {
    Kobo.Ajax(getCollectionRemovalRequest()).done(function (response) {
      var responseObject = JSON.parse(response);

      if (responseObject.result === 'failure') {
        handleError(responseObject, removeViewModel, templates.removeCollectionModal);
      } else {
        handleSuccess(urls.collectionRemoveReload);
      }
    });
  };

  var executeCollectionRename = function executeCollectionRename() {
    Kobo.Ajax(getCollectionRenameRequest()).done(function (response) {
      var responseObject = JSON.parse(response);

      if (responseObject.result === 'failure') {
        handleError(responseObject, renameViewModel, templates.renameCollectionModal);
      } else {
        handleSuccess(urls.collectionRenameReload);
      }
    });
  };

  var handleSuccess = function handleSuccess(reloadUrl) {
    var baseUrl = window.location.origin;
    var currentUrl = window.location.href;
    var fullUrl; // Check if reloadUrl is the same as the current page's URL

    if (reloadUrl === currentUrl) {
      // Preserve the current query string
      fullUrl = currentUrl;
    } else {
      // Append the reloadUrl and preserve the query string if it exists
      fullUrl = baseUrl + reloadUrl + window.location.search;
    }

    window.location.href = fullUrl;
  };

  var handleError = function handleError(response, viewModel, selector) {
    var errorMessage = response && response.message ? response.message : "&#9888; Unexpected error";
    viewModel.errorMessage(errorMessage);
    viewModel.hasError(true);
    Kobo.Spinner.hideSpinnerOverlay(Kobo.$(selector));
  };

  var setupRemoveCollectionBindings = function setupRemoveCollectionBindings(collection) {
    if (collection) {
      removeViewModel.id = ko.observable(collection.id);
    }

    removeViewModel.hasError = ko.observable(false);
    removeViewModel.errorMessage = ko.observable('');
    ko.applyBindings(removeViewModel, Kobo.$(selectors.removeCollectionModalContainer)[0]);
  };

  var setupRenameCollectionBindings = function setupRenameCollectionBindings(collection) {
    if (collection) {
      renameViewModel.id = ko.observable(collection.id);
      renameViewModel.name = ko.observable(collection.name);
    }

    renameViewModel.hasError = ko.observable(false);
    renameViewModel.errorMessage = ko.observable('');
    ko.applyBindings(renameViewModel, Kobo.$(selectors.renameCollectionModalContainer)[0]);
  };

  var openRemoveCollectionModal = function openRemoveCollectionModal() {
    var modalOptions = {
      role: 'alertDialog',
      ariaLabelledBy: selectors.removeCollectionHeading,
      customClass: 'remove-collection-modal'
    };

    Kobo._modal.open(Kobo.$(templates.removeCollectionModal).html(), modalOptions);

    var closeModalButtons = Kobo.$(selectors.buttons.closeRemoveModalButton);
    closeModalButtons.off('click').on('click', function () {
      Kobo._modal.close();
    });
    Kobo.$(selectors.buttons.okRemoveModalButton).off("click").on("click", function () {
      executeCollectionRemoval();
    });
  };

  var openRenameCollectionModal = function openRenameCollectionModal() {
    var modalOptions = {
      role: 'alertDialog',
      ariaLabelledBy: selectors.renameCollectionHeading,
      customClass: 'rename-collection-modal'
    };

    Kobo._modal.open(Kobo.$(templates.renameCollectionModal).html(), modalOptions);

    var closeModalButtons = Kobo.$(selectors.buttons.closeRenameModalButton);
    closeModalButtons.off('click').on('click', function () {
      Kobo._modal.close();
    });
    Kobo.$(selectors.buttons.okRenameModalButton).off("click").on("click", function () {
      executeCollectionRename();
    });
  };

  var removeCollection = function removeCollection(event, data) {
    if (data && data.detail && data.detail.collectionId) {
      openRemoveCollectionModal();
      setupRemoveCollectionBindings({
        id: data.detail.collectionId
      });
    }
  };

  var renameCollection = function renameCollection(event, data) {
    if (data && data.detail && data.detail.collectionId && data.detail.collectionName) {
      openRenameCollectionModal();
      setupRenameCollectionBindings({
        id: data.detail.collectionId,
        name: data.detail.collectionName
      });
    }
  };

  var setupRemoveCollectionEvents = function setupRemoveCollectionEvents() {
    var $btn = Kobo.$(selectors.buttons.openRemoveCollectionModalButton);
    var collection = Kobo.$(selectors.actionsContainer);
    $btn.off('click').on('click', function () {
      Kobo._mediator.fire('collection::remove', {
        collectionId: collection.data('collectionId')
      });
    });
  };

  var setupRenameCollectionEvents = function setupRenameCollectionEvents() {
    var $btn = Kobo.$(selectors.buttons.openRenameCollectionModalButton);
    var collection = Kobo.$(selectors.actionsContainer);
    $btn.off("click").on("click", function () {
      Kobo._mediator.fire("collection::rename", {
        collectionId: collection.data("collectionId"),
        collectionName: collection.data("collectionName")
      });
    });
  };

  var setupHandlers = function setupHandlers() {
    self.register('collection::remove');
    self.subscribe('collection::remove', removeCollection);
    self.register('collection::rename');
    self.subscribe('collection::rename', renameCollection);
  };

  var setupEvents = function setupEvents() {
    setupRemoveCollectionEvents();
    setupRenameCollectionEvents();
  };

  var init = function init() {
    setupEvents();
    setupHandlers();
  };

  init();
};

Kobo.Gizmo.CollectionActions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);