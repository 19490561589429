"use strict";

/*global Kobo, Cookies, ko*/
Kobo.Gizmo.CreateAccountBanner = function (element, options) {
  "use strict";

  var self = this,
      $openModalTrigger,
      viewModel,
      setupTriggers,
      setCookie,
      getCookie,
      setInitialVisibility,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("CreateAccountBanner");

  setupTriggers = function setupTriggers() {
    $openModalTrigger = Kobo.$(element).find('.' + options.showDialogBtnClass);
    $openModalTrigger.on('click', function () {
      viewModel.openDialog();
    });
  };

  setCookie = function setCookie(data) {
    var cookieOptions = {
      expires: 365,
      path: '/',
      raw: true
    };
    Cookies.set(options.hideBannerCookie, JSON.stringify(data), cookieOptions);
  };

  getCookie = function getCookie() {
    var cookie = Cookies.get(options.hideBannerCookie);
    return cookie ? JSON.parse(cookie) : null;
  };

  setInitialVisibility = function setInitialVisibility() {
    var setting = getCookie();

    if (!!setting && setting === true) {
      Kobo.$(element).parent().addClass('hidden');
    } else {
      Kobo.$(element).parent().removeClass('hidden');
    }
  };

  viewModel = new Kobo.ViewModel.CreateAccountBannerVM({
    setCookie: setCookie,
    createAccountUrl: options.createAccountUrl,
    templateId: options.templateId,
    dialogId: options.dialogId,
    createAccountWidgetClass: options.createAccountWidgetClass
  });

  init = function init() {
    setupTriggers();
  };

  setInitialVisibility();
  ko.applyBindings(viewModel, self.el);
  init();
};

Kobo.Gizmo.CreateAccountBanner.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);