"use strict";

/*global Kobo, window */
Kobo.Gizmo.LovePointsRedemptionSuccess = function () {
  "use strict";

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments);

  init = function init() {
    self.$gizmo.find('.continue-shopping').on('click', function () {
      Kobo._mediator.fire('koboLove::redeemFinished');
    });
  };

  init();
};

Kobo.Gizmo.LovePointsRedemptionSuccess.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);