"use strict";

/*global Kobo, ko */
Kobo.Gizmo.NotebooksGrid = function (el) {
  // eslint-disable-line no-unused-vars
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('NotebooksGrid');
  var $el = $(el),
      selectors = {
    notebookCard: '[data-notebook-id]',
    notebookTitle: '[data-notebook-title]',
    notebookImage: '[data-notebook-image]',
    notebookLastModifedDate: '[data-notebook-last-modified-utc]',
    notebookActions: '[data-notebook-actions]',
    buttons: {
      removeNotebook: '[data-remove-notebook-button]',
      removeAllNotebooks: '[data-remove-all-notebooks-button]'
    }
  },
      toastMessagesKeys = {
    success: 'notebooksGrid.successMessage'
  },
      init;

  var setupEvents = function setupEvents() {
    $el.find(selectors.notebookCard).each(function () {
      setupNotebookCardEvents($(this));
    });
    setupRemoveNotebooksEvents();
  };

  var fillNotebookLastModifiedDates = function fillNotebookLastModifiedDates() {
    $el.find(selectors.notebookLastModifedDate).each(function () {
      var dateField = $(this);
      var localDate = new Date(dateField.data('notebookLastModifiedUtc'));
      var lastModifiedText = dateField.text();
      dateField.text(lastModifiedText.replace('{date}', localDate.toLocaleDateString()));
    });
  };

  var setupRemoveNotebooksEvents = function setupRemoveNotebooksEvents() {
    $el.find(selectors.buttons.removeNotebook).each(function () {
      var $btn = $(this);
      var notebook = $btn.closest(selectors.notebookActions);
      $btn.off('click').on('click', function () {
        Kobo._mediator.fire('notebooks::remove', {
          notebookId: notebook.data('notebookId'),
          notebookEtag: notebook.data('notebookEtag')
        });
      });
    });
    $el.find(selectors.buttons.removeAllNotebooks).off('click').on('click', function () {
      Kobo._mediator.fire('notebooks::removeAll');
    });
  };

  var setupNotebookCardEvents = function setupNotebookCardEvents($notebookCard) {
    var ENTER_KEY = 13;
    $notebookCard.on('click keypress', function (e) {
      if (Kobo.$(e.target).is('[data-notebook-more-actions]') || Kobo.$(e.target).is('[data-remove-notebook-button]')) {
        return;
      }

      if (e.which == ENTER_KEY || e.type == 'click') {
        var notebookId = $notebookCard.data('notebookId');

        Kobo._mediator.fire('notebookPreview::load', {
          notebookId: notebookId,
          pushToHistoryState: true
        });
      }
    });
  };

  var checkToastMessages = function checkToastMessages() {
    var successMessage = sessionStorage.getItem(toastMessagesKeys.success);

    if (successMessage) {
      sessionStorage.clear(toastMessagesKeys.success);

      Kobo._mediator.fire('toast::success', {
        message: successMessage
      });
    }
  };

  init = function init() {
    setupEvents();
    fillNotebookLastModifiedDates();
    checkToastMessages();
  };

  init();
};

Kobo.Gizmo.NotebooksGrid.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);