"use strict";

/*global Kobo,ko*/
Kobo.Gizmo.ExtraBillingInfoForPricing = function (element, options) {
  "use strict";

  var self = this,
      viewModel = {},
      states = options['Fields'][0],
      country = options['Fields'][1],
      zip = options['Fields'][2],
      redirectTo = options.RedirectTo,
      saveBillingInfoUrl = options.SaveBillingInfoUrl,
      strings = window.DynamicConfiguration.resourceStrings.paymentInformation,
      resetViewModel,
      setViewModelByGeo,
      setupViewModel,
      postCodePattern,
      saveBillingInfoHandler,
      errorMsgBox = element.querySelector('#error-message-box'),
      showMsgBox,
      hideMsgBox,
      zipErrorBox = element.querySelector("#postal-error-box");
  Kobo.Gizmo.apply(this, arguments);
  this.setType("ExtraBillingInfoForPricing");

  resetViewModel = function resetViewModel() {
    viewModel.showProvince(viewModel.state().length > 0);
    viewModel.provinceLabel(strings.provinceStateRegion);
    viewModel.zipLabel(strings.postalZipCode);
    viewModel.toggleZipError(false);
    hideMsgBox();
  };

  setViewModelByGeo = function setViewModelByGeo() {
    switch (viewModel.selectedCountry()) {
      case "CA":
        viewModel.provinceLabel(strings.provinceOnly);
        viewModel.zipLabel(strings.postalOnly);
        viewModel.errorMsg(strings.CAPostalCodeErrorMessage);
        break;

      case "US":
        viewModel.provinceLabel(strings.stateOnly);
        viewModel.zipLabel(strings.zipOnly);
        viewModel.errorMsg(strings.USPostalCodeErrorMessage);
        break;

      default:
        viewModel.errorMsg(strings.GeneralPostalCodeErrorMessageInvalidChars);
        break;
    }
  };

  saveBillingInfoHandler = function saveBillingInfoHandler() {
    Kobo.Spinner.showSpinnerOverlay(Kobo.$('body'));
    Kobo.$.ajax({
      type: 'POST',
      url: saveBillingInfoUrl,
      data: {
        Country: viewModel.selectedCountry(),
        State: viewModel.selectedState(),
        PostalCode: viewModel.zip()
      },
      dataType: 'json',
      success: function success(response) {
        if (response.ResultType === 'Success') {
          showMsgBox(strings.InfomationChangeSuccessfullyMsg, true);
          window.location.href = redirectTo;
        } else {
          showMsgBox(response.errorMessage, false);
          setTimeout(function () {
            Kobo.Utilities.reload();
          }, 500);
        }

        Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
      },
      error: function error(_error) {
        showMsgBox(_error, false);
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$('body'));
      }
    });
  };

  showMsgBox = function showMsgBox(msg, isSuccess) {
    viewModel.isSaveSuccess(isSuccess);
    viewModel.showMsgBox(true);
    viewModel.generalMsg(msg);
    errorMsgBox.classList.remove('error-msg-disappear');
    setTimeout(hideMsgBox, 2000);
  };

  hideMsgBox = function hideMsgBox() {
    errorMsgBox.classList.add('error-msg-disappear');
    setTimeout(function () {
      viewModel.showMsgBox(false);
      viewModel.isSaveSuccess(false);
      viewModel.generalMsg('');
    }, 1000);
  };

  setupViewModel = function setupViewModel(viewModel) {
    viewModel.state = ko.observableArray(states.SelectableOptions);
    viewModel.zip = ko.observable(zip.Value);
    viewModel.selectedCountry = ko.observable(country.Value);
    viewModel.selectedState = ko.observable(states.Value);
    viewModel.showZip = ko.observable(true);
    viewModel.showProvince = ko.observable(false);
    viewModel.provinceLabel = ko.observable(strings.provinceStateRegion);
    viewModel.zipLabel = ko.observable(strings.postalZipCode);
    viewModel.hasZipError = ko.observable(false);
    viewModel.errorMsg = ko.observable('');
    viewModel.showMsgBox = ko.observable(false);
    viewModel.generalMsg = ko.observable('');
    viewModel.isSaveSuccess = ko.observable(false);
    viewModel.isZipNotNull = ko.computed(function () {
      return !!viewModel.zip();
    });

    viewModel.toggleZipError = function (display) {
      if (display) {
        zipErrorBox.classList.remove('hidden');
        zipErrorBox.classList.add('active');
      } else {
        zipErrorBox.classList.remove('active');
        zipErrorBox.classList.add('hidden');
      }

      viewModel.hasZipError(display);
    };

    viewModel.isZipValid = ko.computed(function () {
      if (postCodePattern.hasOwnProperty(viewModel.selectedCountry())) {
        if (postCodePattern[viewModel.selectedCountry()].test(viewModel.zip())) {
          viewModel.toggleZipError(false);
          return true;
        } else {
          viewModel.toggleZipError(true);
          return false;
        }
      } else if (!postCodePattern['default'].test(viewModel.zip())) {
        viewModel.toggleZipError(true);
        return false;
      }

      viewModel.toggleZipError(false);
      return true;
    });
    viewModel.hasInput = ko.computed(function () {
      return viewModel.isZipNotNull();
    });
    viewModel.allFieldsRequried = ko.computed(function () {
      return viewModel.isZipNotNull();
    });

    viewModel.saveBtnHandler = function (data, event) {
      event.preventDefault();

      if (!viewModel.isZipValid()) {
        viewModel.toggleZipError(true);
      } else {
        saveBillingInfoHandler();
      }
    };

    viewModel.isTooltipClicked = ko.observable(false);
    viewModel.showTooltipContent = ko.observable(false);
  };

  postCodePattern = {
    CA: /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i,
    AU: /^\d{4}$/,
    US: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
    NZ: /^\d{4}$/,
    BR: /^\d{5}[-]?\d{3}$/,
    "default": /^[A-Za-z0-9\s-]*$/
  };

  function init() {
    var element = self.el;
    setupViewModel(viewModel);
    ko.applyBindings(viewModel, element);
    resetViewModel();
    setViewModelByGeo();
    self.viewModel = viewModel;
  }

  init();
};

Kobo.Gizmo.ExtraBillingInfoForPricing.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);