"use strict";

/*global Kobo, ko,Cookies */

/* eslint-disable no-unused-vars */
Kobo.Gizmo.LibraryActions = function (gizmo, options) {
  'use strict';

  var self = this,
      viewModel = {},
      eventNames = {
    addToReadingListEvent: 'library::addToReadingList',
    removeFromReadingListEvent: 'library::removeFromReadingList',
    markAsUnreadEvent: 'library::markAsUnread',
    markAsFinishedEvent: 'library::markAsFinished',
    moveToArchiveEvent: 'library::moveToArchive',
    deleteEvent: 'library::delete',
    restoreToLibraryEvent: 'library::restoreToLibrary',
    annotationsExportEvent: 'library::annotationsExport',
    collapsibleIsOpenEvent: 'collapsible::isOpen'
  },
      handlesEvent,
      $dialog = Kobo.$('#' + options.templateId),
      $dialogContainer,
      $closeTrigger,
      $actionTrigger,
      $undoTrigger,
      setupBindings,
      libraryAjaxCall,
      libraryAjaxCallWithJsonReturn,
      executeAddToReadingList,
      executeResetReadingStatus,
      executeUpdateReadingStatus,
      executeRemoveFromReadingList,
      executeUndoRemoveFromReadingList,
      executeMoveToArchive,
      executeUndoMoveToArchive,
      executeRestoreToLibrary,
      executeUndoRestoreToLibrary,
      executeDelete,
      executeAnnotationsExport,
      mainAction,
      undoAction,
      hasModal = false,
      openModal,
      _closeModal,
      reloadList,
      loadDialog,
      fireEvent,
      setupHandlers,
      setupTriggers,
      setupToastNotifications,
      setAnnotationsExportBindingOptions,
      getAnnotationsExportFileFormatFromLocalStorage,
      setAnnotationsExportFileFormatInLocalStorage,
      spinnerLocation,
      LIBRARY_MODAL_CLASS = 'library-modal',
      LIBRARY_CONTENT_AREA = 'library-container',
      LIBRARY_MODAL_CONTENT_AREA = options.dialogId,
      init,
      addedToReadingListToast = window.DynamicConfiguration.resourceStrings.addedToReadingListToast,
      errorAddingToReadingListToast = window.DynamicConfiguration.resourceStrings.errorAddingToReadingListToast,
      annotationsExportSuccessToast = window.DynamicConfiguration.resourceStrings.annotationsExportSuccessToast,
      annotationsExportSuccessToastUrl = window.DynamicConfiguration.resourceStrings.annotationsExportSuccessToastUrl,
      annotationsExportSuccessToastUrlView = window.DynamicConfiguration.resourceStrings.annotationsExportSuccessToastUrlView;

  Kobo.Gizmo.apply(this, arguments);
  this.setType('LibraryActions');

  setupBindings = function setupBindings(settings) {
    viewModel.removed = ko.observable(false);
    viewModel.restored = ko.observable(false);
    viewModel.id = ko.observable(settings.id);
    viewModel.imageUrl = ko.observable(settings.imageUrl);
    viewModel.title = ko.observable(settings.title);
    viewModel.subtitle = ko.observable(settings.subtitle);
    viewModel.author = ko.observable(settings.author);
    viewModel.seriesName = ko.observable(settings.seriesName);
    viewModel.seriesNumber = ko.observable(settings.seriesNumber);
    viewModel.actionType = ko.observable(settings.actionType);

    viewModel.showSection = function (type) {
      return type === this.actionType();
    };

    setAnnotationsExportBindingOptions(settings);
  };

  reloadList = function reloadList() {
    if (Cookies.get('LibraryStats') !== undefined && Cookies.get('LibraryStats').match(/v=/)) {
      var valuesForCookie = Cookies.get('LibraryStats').replace(/(v=)[\w]*/, '$1' + 'false');
      var components = window.location.hostname.split(".");
      var domain = "." + components.slice(components.length - 2, components.length).join(".");
      Cookies.set('LibraryStats', valuesForCookie, {
        raw: true,
        domain: domain,
        path: '/'
      });
    }

    window.location.href = options.reloadUrl;
  };

  setAnnotationsExportBindingOptions = function setAnnotationsExportBindingOptions(settings) {
    if (settings.actionType == 'exportAnnotations') {
      var preSelectedFileFormat = getAnnotationsExportFileFormatFromLocalStorage(settings);
      viewModel.annotationsExportFileFormatOptions = ko.observableArray(settings.annotationsExportFileFormatOptions);
      viewModel.selectedAnnotationsExportFileFormatOption = ko.observable(preSelectedFileFormat);
    } else {
      viewModel.annotationsExportFileFormatOptions = ko.observableArray([]);
      viewModel.selectedAnnotationsExportFileFormatOption = ko.observable();
    }
  };

  getAnnotationsExportFileFormatFromLocalStorage = function getAnnotationsExportFileFormatFromLocalStorage(settings) {
    var exportAnnotationsOptionsJson = localStorage.getItem('exportAnnotationsOptions');

    if (exportAnnotationsOptionsJson) {
      var exportAnnotationsOptionsData = JSON.parse(exportAnnotationsOptionsJson);

      if (exportAnnotationsOptionsData && exportAnnotationsOptionsData.previouslySelectedFileFormat) {
        return exportAnnotationsOptionsData.previouslySelectedFileFormat;
      }
    }

    return settings.annotationsExportFileFormatOptions[0];
  };

  setAnnotationsExportFileFormatInLocalStorage = function setAnnotationsExportFileFormatInLocalStorage(selectedAnnotationsExportOption) {
    var exportAnnotationsOptionsData = {
      previouslySelectedFileFormat: selectedAnnotationsExportOption
    };
    var exportAnnotationsOptionsJson = JSON.stringify(exportAnnotationsOptionsData);
    localStorage.setItem('exportAnnotationsOptions', exportAnnotationsOptionsJson);
  };

  libraryAjaxCall = function libraryAjaxCall(input, callback, error) {
    Kobo.$.ajax({
      type: 'POST',
      dataType: 'text',
      contentType: 'application/json; charset=utf-8',
      beforeSend: Kobo.Spinner.showSpinnerOverlay(Kobo.$(input.spinner)),
      url: input.url,
      headers: {
        __RequestVerificationToken: Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify(input.data)
    }).done(callback).fail(error);
  };

  libraryAjaxCallWithJsonReturn = function libraryAjaxCallWithJsonReturn(input, callback, error) {
    Kobo.$.ajax({
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      beforeSend: Kobo.Spinner.showSpinnerOverlay(Kobo.$(input.spinner)),
      url: input.url,
      headers: {
        __RequestVerificationToken: Kobo.Utilities.getAntiForgeryToken()
      },
      data: JSON.stringify(input.data)
    }).done(callback).fail(error);
  };

  executeAddToReadingList = function executeAddToReadingList(e, d) {
    if (d.detail.id !== self.options.id || handlesEvent !== eventNames.addToReadingListEvent) {
      return;
    }

    spinnerLocation = '.' + LIBRARY_CONTENT_AREA;
    libraryAjaxCallWithJsonReturn({
      url: options.addAction,
      data: {
        id: options.id,
        productId: options.productId,
        isFinished: options.isFinished
      },
      spinner: spinnerLocation
    }, function (data) {
      if (data !== null && data.Result === "Error") {
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));

        Kobo._mediator.fire("toast::error", {
          message: errorAddingToReadingListToast,
          position: 'centered'
        });

        return;
      }

      if (options.isFinished) {
        reloadList();
      } else {
        Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));

        Kobo._mediator.fire("toast::success", {
          message: addedToReadingListToast,
          position: 'centered'
        });
      }
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error adding to reading list: ' + options.id);
    });
  };

  executeResetReadingStatus = function executeResetReadingStatus(e, d) {
    if (d.detail.id !== options.id || handlesEvent !== eventNames.markAsUnreadEvent) {
      return;
    }

    spinnerLocation = '.' + LIBRARY_CONTENT_AREA;
    libraryAjaxCall({
      url: options.resetReadingStatusAction,
      data: {
        id: options.id
      },
      spinner: spinnerLocation
    }, function () {
      reloadList();
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error marking as unread: ' + options.id);
    });
  };

  executeUpdateReadingStatus = function executeUpdateReadingStatus(e, d) {
    if (d.detail.id !== options.id || handlesEvent !== eventNames.markAsFinishedEvent) {
      return;
    }

    spinnerLocation = '.' + LIBRARY_CONTENT_AREA;
    libraryAjaxCall({
      url: options.updateReadingStatusAction,
      data: {
        id: options.id,
        productId: options.productId
      },
      spinner: spinnerLocation
    }, function () {
      reloadList();
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error marking as finished: ' + options.id);
    });
  };

  executeRemoveFromReadingList = function executeRemoveFromReadingList(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.removeFromReadingListAction,
      data: {
        productId: options.productId
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.removed(true);
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error removing from reading list: ' + options.productId);
    });
  };

  executeUndoRemoveFromReadingList = function executeUndoRemoveFromReadingList(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.undoRemoveFromReadingListAction,
      data: {
        id: options.id,
        productId: options.productId
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.removed(false);

      _closeModal();
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error re-adding to reading list: ' + options.productId);
    });
  };

  executeMoveToArchive = function executeMoveToArchive(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.moveToArchiveAction,
      data: {
        id: options.id
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.removed(true);
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error removing from library: ' + options.id);
    });
  };

  executeDelete = function executeDelete(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.deleteAction,
      data: {
        id: options.id
      },
      spinner: spinnerLocation
    }, function (data) {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.removed(true);
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error deleting: ' + options.id);
    });
  };

  executeAnnotationsExport = function executeAnnotationsExport(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    var selectedFileFormatOption = viewModel.selectedAnnotationsExportFileFormatOption();
    libraryAjaxCall({
      url: options.exportAnnotationsAction,
      data: {
        id: options.id,
        productId: options.productId,
        fileFormat: selectedFileFormatOption
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));

      _closeModal();

      setAnnotationsExportFileFormatInLocalStorage(selectedFileFormatOption);
      var annotationsUrl = "<a href=".concat(annotationsExportSuccessToastUrl, " class=\"annotations-export-link\">").concat(annotationsExportSuccessToastUrlView, "</a>");
      var exportSuccessMessage = annotationsExportSuccessToast.replace("{0}", annotationsUrl);

      Kobo._mediator.fire("toast::info", {
        message: exportSuccessMessage,
        duration: 8000
      });
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error exporting annotations: ' + options.productId);
    });
  };

  executeUndoMoveToArchive = function executeUndoMoveToArchive(e) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.undoMoveToArchiveAction,
      data: {
        id: options.id
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.removed(false);

      _closeModal();
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error re-adding to library: ' + options.id);
    });
  };

  executeRestoreToLibrary = function executeRestoreToLibrary(e, d) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.restoreToLibraryAction,
      data: {
        id: options.id,
        productId: options.productId
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.restored(true);
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error restoring to Library: ' + options.id);
    });
  };

  executeUndoRestoreToLibrary = function executeUndoRestoreToLibrary(e, d) {
    spinnerLocation = '#' + LIBRARY_MODAL_CONTENT_AREA;
    libraryAjaxCall({
      url: options.undoRestoreToLibraryAction,
      data: {
        id: options.id,
        productId: options.productId
      },
      spinner: spinnerLocation
    }, function () {
      Kobo.Spinner.hideSpinnerOverlay(Kobo.$(spinnerLocation));
      viewModel.restored(false);

      _closeModal();
    }, function (jqXHR, textStatus, errorThrown) {
      throw new Error('Error restoring to Library: ' + options.id);
    });
  };

  openModal = function openModal(settings) {
    $dialogContainer = Kobo.$('<div id="' + settings.dialogId + '">');
    $dialogContainer.html($dialog.html()); // Apply the bindings first, because they reveal the action buttons, etc.

    setupBindings(settings);
    ko.applyBindings(viewModel, $dialogContainer[0]);
    $closeTrigger = $dialogContainer.find('.' + settings.closeClass);
    $closeTrigger.on('click', _closeModal);
    $actionTrigger = $dialogContainer.find('.' + settings.actionClass);
    $actionTrigger.on('click', mainAction);
    $undoTrigger = $dialogContainer.find('.' + settings.undoClass);
    $undoTrigger.on('click', undoAction);

    Kobo._modal.open($dialogContainer);

    $dialogContainer.closest('#' + settings.modalContentId).addClass(LIBRARY_MODAL_CLASS).addClass(settings.containerClass);
    $actionTrigger.trigger("focus");
  };

  _closeModal = function closeModal() {
    $closeTrigger.off('click', _closeModal);
    $actionTrigger.off('click', mainAction);
    $undoTrigger.off('click', undoAction);

    if (viewModel.removed() || viewModel.restored() && handlesEvent === eventNames.restoreToLibraryEvent) {
      reloadList();
    } else {
      Kobo._modal.close();
    }
  };

  loadDialog = function loadDialog(event, data) {
    if (data.detail.id !== options.id || data.detail.actionType !== options.actionType || !hasModal) {
      return;
    }

    openModal(data.detail);
  };

  fireEvent = function fireEvent(e) {
    Kobo._mediator.fire(handlesEvent, self.settings);

    e.preventDefault();
  };

  setupHandlers = function setupHandlers() {
    Kobo._mediator.register(eventNames.moveToArchiveEvent);

    Kobo._mediator.subscribe(eventNames.moveToArchiveEvent, loadDialog);

    Kobo._mediator.register(eventNames.deleteEvent);

    Kobo._mediator.subscribe(eventNames.deleteEvent, loadDialog);

    Kobo._mediator.register(eventNames.removeFromReadingListEvent);

    Kobo._mediator.subscribe(eventNames.removeFromReadingListEvent, loadDialog);

    Kobo._mediator.register(eventNames.addToReadingListEvent);

    Kobo._mediator.subscribe(eventNames.addToReadingListEvent, executeAddToReadingList);

    Kobo._mediator.register(eventNames.markAsUnreadEvent);

    Kobo._mediator.subscribe(eventNames.markAsUnreadEvent, executeResetReadingStatus);

    Kobo._mediator.register(eventNames.markAsFinishedEvent);

    Kobo._mediator.subscribe(eventNames.markAsFinishedEvent, executeUpdateReadingStatus);

    Kobo._mediator.register(eventNames.restoreToLibraryEvent);

    Kobo._mediator.subscribe(eventNames.restoreToLibraryEvent, loadDialog);

    Kobo._mediator.register(eventNames.annotationsExportEvent);

    Kobo._mediator.subscribe(eventNames.annotationsExportEvent, loadDialog);
  };

  setupTriggers = function setupTriggers() {
    if (!self.options.actionType) {
      throw new Error('No action specified: ' + self.$gizmo);
    }

    switch (self.options.actionType) {
      case 'addToReadingList':
        handlesEvent = eventNames.addToReadingListEvent;
        break;

      case 'removeFromReadingList':
        handlesEvent = eventNames.removeFromReadingListEvent;
        mainAction = executeRemoveFromReadingList;
        undoAction = executeUndoRemoveFromReadingList;
        hasModal = true;
        break;

      case 'markAsUnread':
        handlesEvent = eventNames.markAsUnreadEvent;
        break;

      case 'markAsFinished':
        handlesEvent = eventNames.markAsFinishedEvent;
        break;

      case 'moveToArchive':
        handlesEvent = eventNames.moveToArchiveEvent;
        mainAction = executeMoveToArchive;
        undoAction = executeUndoMoveToArchive;
        hasModal = true;
        break;

      case 'deleteFromLibrary':
      case 'permanentlyRemove':
        handlesEvent = eventNames.deleteEvent;
        mainAction = executeDelete;
        hasModal = true;
        break;

      case 'restoreToLibrary':
        handlesEvent = eventNames.restoreToLibraryEvent;
        mainAction = executeRestoreToLibrary;
        undoAction = executeUndoRestoreToLibrary;
        hasModal = true;
        break;

      case 'exportAnnotations':
        handlesEvent = eventNames.annotationsExportEvent;
        mainAction = executeAnnotationsExport;
        hasModal = true;
        break;

      default:
        throw new Error('Unrecognised action - ' + self.options.actionType + ': ' + self.$gizmo);
    }

    self.$gizmo.on('click', fireEvent);
  };

  var setupExportAnnotationsToast = function setupExportAnnotationsToast() {
    if (self.options.actionType !== 'exportAnnotations') {
      return;
    }

    var exportAnnotationsToastJsonData = localStorage.getItem('exportAnnotationsToast');
    var exportAnnotationsToastData; // if we have data for exportAnnotationsToast in localstorage, we read it

    if (exportAnnotationsToastJsonData) {
      exportAnnotationsToastData = JSON.parse(exportAnnotationsToastJsonData);
    } else {
      // Otherwise we add 4 months to the current date to compute the expiration date
      var toastExpirationDate = new Date();
      toastExpirationDate.setMonth(toastExpirationDate.getMonth() + 4);
      exportAnnotationsToastData = {
        showToast: true,
        expirationDate: toastExpirationDate
      }; // And we set the toast info in localstorage

      exportAnnotationsToastJsonData = JSON.stringify(exportAnnotationsToastData);
      localStorage.setItem('exportAnnotationsToast', exportAnnotationsToastJsonData);
    } // we check if the toast should be shown


    if (exportAnnotationsToastData.showToast === true && new Date(exportAnnotationsToastData.expirationDate).getTime() > new Date().getTime()) {
      // show the toast
      var $exportAnnotationsToastMessageContainer = Kobo.$('.' + options.exportAnnotationsToastNotificationContainerClass);

      if ($exportAnnotationsToastMessageContainer) {
        $exportAnnotationsToastMessageContainer.css('display', 'flex'); // hook the button click event handler

        var $exportAnnotationsGotItButton = Kobo.$('.' + options.exportAnnotationsGotItButtonClass);

        if ($exportAnnotationsGotItButton) {
          $exportAnnotationsGotItButton.off('click').on('click', executeAnnotationsExportGotIt);
        } // hook the closest library actions ... button click


        var $libraryActionsMoreButton = $exportAnnotationsToastMessageContainer.siblings().filter('.' + options.libraryActionsMoreButtonContainerClass).find('button.' + options.libraryActionsMoreButtonClass);

        if ($libraryActionsMoreButton) {
          $libraryActionsMoreButton.on('click', executeAnnotationsExportGotIt);
        }
      }
    }
  };

  var executeAnnotationsExportGotIt = function executeAnnotationsExportGotIt() {
    // hide the toast when the message is acknowledged
    var $exportAnnotationsToastMessageContainer = Kobo.$('.' + options.exportAnnotationsToastNotificationContainerClass);

    if ($exportAnnotationsToastMessageContainer) {
      $exportAnnotationsToastMessageContainer.hide(); // set info in localstorage

      var exportAnnotationsToastData = {
        showToast: false,
        expirationDate: new Date()
      };
      var exportAnnotationsToastJsonData = JSON.stringify(exportAnnotationsToastData);
      localStorage.setItem('exportAnnotationsToast', exportAnnotationsToastJsonData);
    }
  };

  setupToastNotifications = function setupToastNotifications() {
    setupExportAnnotationsToast();
  };

  init = function init() {
    setupHandlers();
    setupTriggers();
    setupToastNotifications();
  };

  init();
};

Kobo.Gizmo.LibraryActions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);