"use strict";

/**
 * A gizmo to gently scroll the screen on page load so that it snaps to the top of the gizmo's owner element.
 * Activates for mobile only.
 *
 * @param el
 * @param options
 * @param gizmo
 * @constructor
 */
Kobo.Gizmo.ScrollSnap = function (el, options, gizmo) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("ScrollSnap");
  self.settings = Kobo.extend({
    delay: 2000,
    // delay after page load before we snap to this gizmo's element
    duration: 500,
    // how long the scroll animation takes
    easing: "swing" // the type of animation

  }, options);

  init = function init() {
    var screensize = Kobo._mediator.determineBreakpoint(),
        scrollTop,
        to;

    if (screensize === "small" || screensize === "medium") {
      setTimeout(function () {
        scrollTop = Kobo.$('body').scrollTop();

        if (scrollTop === 0) {
          to = Math.floor($el.offset().top - Kobo.$("header").outerHeight());
          Kobo.$('html,body').animate({
            scrollTop: to
          }, {
            duration: self.settings.duration,
            easing: self.settings.easing
          });
        }
      }, self.settings.delay);
    }
  };

  init();
};

Kobo.Gizmo.ScrollSnap.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);