"use strict";

/*globals Kobo,ko, DynamicConfiguration*/
Kobo.Gizmo.OrangeInterstitial = function (el) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  var self = this,
      subscribeWithOrangeUrl = this.options.subscribeWithOrangeUrl,
      subscribeWithKoboUrl = this.options.subscribeWithKoboUrl,
      orangeActionContainer = document.querySelector('.orange-action-container'),
      radioArray = Array.prototype.slice.call(document.querySelectorAll('input[type="radio"]')),
      customRadioForm = document.querySelector('.custom-radio'),
      availablePaymentMethods = [],
      creditCardType = {
    MasterCard: 'mastercard',
    Visa: 'visa',
    AmericanExpress: 'amex',
    Discover: 'discover',
    JCB: 'jcb',
    DinersClub: 'DinersClub',
    EnRoute: 'EnRoute'
  },
      offerId = this.options.offerId,
      productId = this.options.productId,
      offerToken = this.options.offerToken,
      INCLUSION_OFFER = '570AA895-ACF4-4793-B4DD-77FFB1845D9C',
      ONE_CREDIT_PER_MONTH_OFFER = 'F16CFC88-8D9D-4B21-92F1-038170991BA7',
      BOOK2_OFFER = 'book2',
      INTERNET_OFFER = 'web/service/books',
      NO_TRIAL_OFFER = 'book0',
      noOffer = this.options.noOffer;

  this.handleSubscription = function () {
    var subscriptionProvider = document.querySelector("input[name='audiobook-subscription']:checked");

    if (subscriptionProvider.value === 'subscribe-with-kobo') {
      self.handleKoboSubscription();
    } else {
      self.handleOrangeSubscription();
    }
  };

  this.viewModel = {
    radioButtons: ko.observableArray(radioArray),
    fadeIn: ko.observable(true),
    showNoTrialOffer: ko.observable(false),
    showOneCreditOffer: ko.observable(false),
    showInclusionOffer: ko.observable(false),
    showOffer2: ko.observable(false),
    showGetOffers: ko.observable(false),
    showNoOffers: ko.observable(false),
    showSpinner: ko.observable(false),
    showError: ko.observable(false),
    disableKobo: ko.observable(false),
    enableContinue: ko.observable(false),
    handleSubscription: self.handleSubscription
  };

  this.preSelectKobo = function () {
    var orangeSelect = document.querySelector('#orange-audiobook-sub');
    document.querySelector('#kobo-audiobook-sub').click();
    orangeSelect.setAttribute('disabled', true);
    orangeActionContainer.querySelector('.custom-radio').style.display = "none";
  };

  function convertEnumToCardType(type) {
    switch (type) {
      case 12:
        return creditCardType.Visa;

      case 13:
        return creditCardType.MasterCard;

      case 14:
        return creditCardType.Discover;

      case 15:
        return creditCardType.AmericanExpress;

      case 16:
        return creditCardType.JCB;

      case 17:
        return creditCardType.DinersClub;

      case 18:
        return creditCardType.EnRoute;

      default:
        return null;
    }
  }

  this.chooseOfferView = function (productId, offerId) {
    var offer;
    var orangeOffer;

    if (productId) {
      offer = productId.toUpperCase();
    }

    if (offerId) {
      orangeOffer = offerId.toLowerCase();
    }

    switch (orangeOffer) {
      case BOOK2_OFFER:
        return BOOK2_OFFER;

      case INTERNET_OFFER:
        return INTERNET_OFFER;

      case NO_TRIAL_OFFER:
        return NO_TRIAL_OFFER;
    }

    switch (offer) {
      case ONE_CREDIT_PER_MONTH_OFFER:
        return ONE_CREDIT_PER_MONTH_OFFER;

      case INCLUSION_OFFER:
        return INCLUSION_OFFER;

      default:
        return null;
    }
  };

  function mapPaymentEnumsToType(paymentTypeArray) {
    var i;

    for (i = 0; i < paymentTypeArray.length; i++) {
      availablePaymentMethods.push(convertEnumToCardType(paymentTypeArray[i]));
    }
  }

  function highlightCard() {
    var i;
    self.enableContinueAction(true);

    for (i = 0; i < radioArray.length; i++) {
      if (radioArray[i].checked) {
        radioArray[i].parentElement.classList.add('highlight');
      } else {
        radioArray[i].parentElement.classList.remove('highlight');
      }
    }
  }

  function removeMinWidthHeight() {
    setTimeout(function () {
      orangeActionContainer.removeAttribute('style');
    }, 250);
  }

  function clearContainer(actionContainer) {
    var elementWidthMinusPadding = actionContainer.offsetWidth - 82; // magic number matches padding and border

    var elementHeightMinusPadding = actionContainer.offsetHeight - 82; // magic number matches padding and border

    actionContainer.style.minWidth = elementWidthMinusPadding + "px";
    actionContainer.style.minHeight = elementHeightMinusPadding + "px";
    self.resetViewModel();
    self.viewModel.showSpinner(true);
  }

  this.enableContinueAction = function () {
    self.viewModel.enableContinue(true);
  };

  this.handleOrangeSubscription = function () {
    clearContainer(orangeActionContainer);
    Kobo.$.ajax({
      type: 'POST',
      url: subscribeWithOrangeUrl,
      data: JSON.stringify({
        offerId: offerId,
        productId: productId,
        offerToken: offerToken
      }),
      contentType: 'application/json',
      dataType: 'json'
    }).done(function (data) {
      self.resetViewModel();

      if (data.IsSuccess && data.RedirectSuccess) {
        Kobo.Utilities.navigateTo(data.RedirectSuccess);
      } else {
        if (data.RedirectAuth) {
          Kobo.Utilities.navigateTo(data.RedirectAuth);
        } else {
          self.viewModel.showError(true);
        }
      }
    }).fail(function (err) {
      // eslint-disable-line no-unused-vars 
      self.viewModel.showError(true);
    });
  };

  this.handleKoboSubscription = function () {
    Kobo.$.ajax({
      type: 'POST',
      url: subscribeWithKoboUrl,
      contentType: 'application/json',
      dataType: 'json'
    }).done(function (data) {
      if (data.RedirectUrl) {
        Kobo.Utilities.navigateTo(data.RedirectUrl);
      }
    });
  };

  function addPaymentListItems(availablePaymentMethods) {
    var hook = document.querySelector('.payment-options');

    if (hook) {
      var i;
      var item;

      for (i = 0; i < availablePaymentMethods.length; i++) {
        item = document.createElement('span');
        item.classList.add('payment-method');
        item.classList.add(availablePaymentMethods[i]);
        item.appendChild(document.createTextNode(availablePaymentMethods[i]));
        hook.appendChild(item);
      }
    } else {
      Kobo.log('Hook element not available');
    }
  }

  this.setView = function () {
    self.resetViewModel();
    var product = productId || null;
    var offer = offerId || null;
    var view = self.chooseOfferView(product, offer);

    switch (view) {
      case NO_TRIAL_OFFER:
        self.viewModel.showNoTrialOffer(true);
        break;

      case ONE_CREDIT_PER_MONTH_OFFER:
        self.viewModel.showOneCreditOffer(true);
        break;

      case INCLUSION_OFFER:
        self.viewModel.showInclusionOffer(true);
        break;

      case BOOK2_OFFER:
        self.viewModel.showOffer2(true);
        break;

      case INTERNET_OFFER:
        // this offer is the same as book2 but for Orange's internet customers, so the book2 view is used
        self.viewModel.showOffer2(true);
        break;

      case null:
        if (noOffer) {
          self.viewModel.showNoOffers(true);
          self.preSelectKobo();
          return;
        }

        self.viewModel.showGetOffers(true);
        break;

      default:
        self.viewModel.showGetOffers(true);
        break;
    }
  };

  this.resetViewModel = function () {
    self.viewModel.showSpinner(false);
    self.viewModel.showNoTrialOffer(false);
    self.viewModel.showOneCreditOffer(false);
    self.viewModel.showInclusionOffer(false);
    self.viewModel.showOffer2(false);
    self.viewModel.showGetOffers(false);
    self.viewModel.showNoOffers(false);
    self.viewModel.showError(false);
  };

  function init() {
    if (document.querySelector('.highlight')) {
      highlightCard();
    }

    mapPaymentEnumsToType(self.settings.paymentOptions);
    addPaymentListItems(availablePaymentMethods);
    customRadioForm.addEventListener('click', highlightCard);
    self.setView();
  }

  ko.applyBindings(this.viewModel, el);

  Kobo._mediator.subscribe(Kobo._mediator.defaultEventNames.resize, removeMinWidthHeight);

  init();
};

Kobo.Gizmo.OrangeInterstitial.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);