"use strict";

/*global Kobo, ko*/
function SubscriptionPlanSelectionViewModel(options) {
  'use strict';

  var self = this;
  self.options = options;
  self.attrs = {
    isActive: false,
    isUnableToSwitch: false,
    isUpgradeToCombined: false,
    isSwitch: false,
    isSubscribe: false,
    isFreeTrial: false,
    isPartnerOwned: false,
    actionUrl: '',
    planName: '',
    ariaLabels: {}
  };
  this.init();

  this.showModal = function () {
    Kobo._mediator.fire('plusplan::openmodal', {
      action: self.options.planAction,
      planType: self.options.planType
    });
  };
}

SubscriptionPlanSelectionViewModel.prototype.init = function () {
  if (typeof this.options.anyCancellations === 'string') {
    this.options.anyCancellations = this.options.anyCancellations.toLowerCase() == 'true';
  }

  if (typeof this.options.isPartnerOwned === 'string') {
    this.attrs.isPartnerOwned = this.options.isPartnerOwned.toLowerCase() == 'true';
  }

  if (this.options.anyCancellations && this.options.planAction === this.options.actions.UnableToSwitch) {
    this.options.planAction = this.options.actions.UnableToSwitchCancelled;
  }

  if (this.options.planAction === this.options.actions.Switch) {
    this.attrs.isSwitch = true;

    if (this.options.planType.toLowerCase() == this.options.plans.ebooks) {
      this.options.planAction = this.options.actions.SwitchEbooks;
    } else {
      this.options.planAction = this.options.actions.SwitchAudiobooks;
    }
  }

  this.attrs.planName = this.options.plan.Name;
  this.attrs.actionUrl = this.options.plan.ActionUrl;
  this.attrs.isActive = this.options.plan.IsUserCurrentPlan;
  this.attrs.isUnableToSwitch = this.options.planAction == this.options.actions.UnableToSwitch || this.options.planAction == this.options.actions.UnableToSwitchCancelled;
  this.attrs.isUpgradeToCombined = this.options.planAction == this.options.actions.UpgradeToCombined;
  this.attrs.isSubscribe = this.options.planAction == this.options.actions.Subscribe;
  this.attrs.isFreeTrial = this.options.planAction == this.options.actions.FreeTrial;
  this.setAriaLabels(this.attrs.planName);
};

SubscriptionPlanSelectionViewModel.prototype.setAriaLabels = function (planName) {
  this.attrs.ariaLabels = {
    startFreeTrialFor: window.DynamicConfiguration.resourceStrings.plusPlans.ariaLabels.startFreeTrialFor.replace('{0}', planName),
    subscribeTo: window.DynamicConfiguration.resourceStrings.plusPlans.ariaLabels.subscribeTo.replace('{0}', planName),
    switchPlanTo: window.DynamicConfiguration.resourceStrings.plusPlans.ariaLabels.switchPlanTo.replace('{0}', planName),
    upgradeToAudioAndBooks: window.DynamicConfiguration.resourceStrings.plusPlans.ariaLabels.upgradeToAudioAndBooks.replace('{0}', planName),
    learnWhyCantSwitchTo: window.DynamicConfiguration.resourceStrings.plusPlans.ariaLabels.learnWhyCantSwitchTo.replace('{0}', planName)
  };
};

Kobo.Utilities.assignToNamespace('Kobo.ViewModel.SubscriptionPlanSelectionViewModel', SubscriptionPlanSelectionViewModel);