"use strict";

/*global Kobo, $, window*/
Kobo.Gizmo.LibrarySort = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("LibrarySortController");

  init = function init() {
    Kobo.Library.SortDisplay(el, options);
  };

  init();
};

Kobo.Gizmo.LibrarySort.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.LibrarySort.Collapsible = function (el, options) {
  'use strict';

  var self = this,
      init;
  Kobo.Gizmo.Collapsible.IsList.apply(this, arguments);
  self.setType("LibrarySortController");

  init = function init() {
    Kobo.Library.SortDisplay(el, options);
  };

  init();
};

Kobo.Gizmo.LibrarySort.Collapsible.prototype = Kobo.chainPrototype(Kobo.Gizmo.Collapsible.IsList.prototype);