"use strict";

/*global Kobo,$,console ,ko,DynamicConfiguration*/
Kobo.Gizmo.UserRewardSelectorPopup = function (el, options) {
  'use strict';

  var self = this,
      init,
      template = '#' + options.templateId,
      closeSelector = '.' + options.closeSelector,
      selectorClass = '.' + options.itemDetailsSelector,
      customModalClass = 'modal-content-userrewards';

  self.updateSelectCtaStatus = function () {
    var selectButtonLabel = DynamicConfiguration.resourceStrings[options.selectedCtaResourceString];
    var unselectButtonLabel = DynamicConfiguration.resourceStrings[options.unselectedCtaResourceString]; // style the select button depending on landing page status

    if (self.$canItemBeSelected === true) {
      // make sure the select button is enabled
      self.$popupSelectItem.removeClass(options.disabledSelector);

      if (self.$rewardItem.hasClass(options.selectedSelector)) {
        // show that item has been selected in landing page
        self.$popupSelectItem.addClass(options.selectedSelector);
        self.$popupSelectItem.text(unselectButtonLabel);
      } else {
        // the item can be selected here
        self.$popupSelectItem.removeClass(options.selectedSelector);
        self.$popupSelectItem.text(selectButtonLabel);
      }
    } else {
      // some other item has been selected (or the max amount of items have been selected), so
      // this one can't be selected as well. Show as disabled.
      self.$popupSelectItem.addClass(options.disabledSelector);
      self.$popupSelectItem.text(selectButtonLabel);
    }
  };

  self.showBookSynopsis = function (synopsis) {
    Kobo._modal.open(synopsis, {
      customClass: customModalClass
    });

    synopsis.find('.' + options.popupSelector).removeClass(options.hideSelector);
    self.$popupSelectItem = Kobo.$(synopsis).find('.' + options.popupButtonSelector);
    self.updateSelectCtaStatus(); // close button

    Kobo.$(synopsis).find(closeSelector).on('click', function () {
      Kobo._modal.close();
    });

    if (self.$canItemBeSelected) {
      // "Select" reward button
      self.$popupSelectItem.on('click', function () {
        var selectItem = self.$rewardItem.find('.' + options.landingPageBtnSelector);

        Kobo._mediator.fire('UserRewardSelector::toggleSelect', {
          selectItem: selectItem
        });

        Kobo._modal.close();
      });
    }
  };

  self.loadSynopsis = function (event, data) {
    if (data && data.detail && data.detail.target && data.detail.canSelectItem !== 'undefined') {
      self.$triggerItem = Kobo.$($(data.detail.target).closest(selectorClass));
      self.$rewardItem = self.$triggerItem.closest('.' + options.rewardItemSelector);
      self.$canItemBeSelected = data.detail.canSelectItem;
      var popupData = {
        title: self.$triggerItem.data('popup-title'),
        imageurl: self.$triggerItem.data('popup-img'),
        subtitle: self.$triggerItem.data('popup-subtitle'),
        synopsis: self.$triggerItem.data('popup-synopsis')
      };
      self.productInfoViewModel.update(popupData);
      self.showBookSynopsis(self.$productPopup);
    }
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('userRewardSelectorPopup::loadSynopsis', self.loadSynopsis);
  };

  self.setupHandlers = function () {
    Kobo._mediator.register('userRewardSelectorPopup::loadSynopsis');

    Kobo._mediator.subscribe('userRewardSelectorPopup::loadSynopsis', self.loadSynopsis);
  };

  init = function init() {
    ko.cleanNode(el);
    self.$productPopup = Kobo.$(el).html(Kobo.$(template).html());
    self.productInfoViewModel = new Kobo.ViewModel.UserRewardProductInfo();
    ko.applyBindings(self.productInfoViewModel, el);
    self.setupHandlers();
  };

  init();
};

Kobo.Gizmo.UserRewardSelectorPopup.prototype = Kobo.chainPrototype(Kobo.Gizmo.UserRewardSelectorPopup);