"use strict";

/*global Kobo, ko, DynamicConfiguration */
function PlusPlansDialogViewModel(options) {
  'use strict';

  var self = this;
  self.options = options;
  self.templateId = '';
  self.$view = '';
  self.planAction = '';
  self.planType = '';
  self.redirectUrl = '';
  self.attrs = {
    errorMsg: '',
    stillActiveDesc: '',
    changePlan: {}
  };
  this.init();

  self.closeDialog = function () {
    Kobo._modal.close();

    self.attrs = {};
    self.$view = '';
  };

  self.open = function (templateId) {
    self.closeDialog();
    var $dialogTemplate = Kobo.$(templateId);
    var html = $dialogTemplate.html().trim();
    self.$view = Kobo.$.parseHTML(html)[0];

    switch (self.planAction) {
      case self.options.actions.UpgradeToCombined:
      case self.options.actions.SwitchEbooks:
      case self.options.actions.SwitchAudiobooks:
        this.setChangePlan();
        break;

      case self.options.actions.UnableToSwitchCancelled:
        this.setPlanCancelled();
        break;

      case self.options.actions.Error:
        this.setError();
        break;
    }

    ko.applyBindings(self, self.$view);

    Kobo._modal.open(self.$view); // ABridal: force redraw of the close button to fix weird bug


    $('.modal-x').css('padding', '1px');

    if (self.options.IsBOLGeo) {
      $('#kobo-logo').addClass('combo-bol');
    } else {
      $('#kobo-logo').addClass('combo');
    }

    if (self.options.IsBOLGeo) {
      $('#kobo-logo-read').addClass('ebooks-bol');
    } else {
      $('#kobo-logo-read').addClass('ebooks');
    }
  };

  self.showUpgrade = function () {
    self.planAction = self.options.actions.UpgradeToCombined;
    self.planType = self.options.plans.combo;
    self.open(self.options.templates.UpgradeToCombined);
  };

  self.changePlan = function () {
    Kobo._mediator.fire('plusplan::switchPlan', {
      actionUrl: this.attrs.changePlan.actionUrl,
      onSuccess: function onSuccess(data) {
        self.planAction = self.options.actions.Success;

        if (data && data.RedirectUrl) {
          self.redirectUrl = data.RedirectUrl;
        }

        self.open(self.options.templates.Success);
      },
      onError: function onError() {
        self.planAction = self.options.actions.Error;
        self.open(self.options.templates.Error);
      }
    });
  };

  self.redirect = function () {
    self.closeDialog();

    if (self.redirectUrl) {
      Kobo.Utilities.navigateTo(self.redirectUrl);
    }
  };
}

PlusPlansDialogViewModel.prototype.init = function () {
  var self = this;
  self.redirectUrl = self.options.SwitchRedirectUrl;

  Kobo._mediator.register('plusplan::openmodal');

  Kobo._mediator.subscribe('plusplan::openmodal', function (event, data) {
    self.planAction = data.detail.action;
    self.planType = data.detail.planType;
    self.open(self.options.templates[self.planAction]);
  });
};

PlusPlansDialogViewModel.prototype.setError = function () {
  this.attrs = {};
  var actionDesc = this.planType == this.options.plans.combo ? DynamicConfiguration.resourceStrings.plusPlans.upgrade : DynamicConfiguration.resourceStrings.plusPlans.change;
  this.attrs.errorMsg = DynamicConfiguration.resourceStrings.plusPlans.error.replace('{0}', actionDesc.toLowerCase());
};

PlusPlansDialogViewModel.prototype.setChangePlan = function () {
  var footnote = '';
  var planCost = this.options.PlansCost[this.planType.toLowerCase()];

  if (this.planAction == this.options.actions.UpgradeToCombined && this.options.UpgradeCost.currency && this.options.UpgradeCost.price != '0') {
    footnote = DynamicConfiguration.resourceStrings.plusPlans.upgradeDetail.replace('{0}', this.options.UpgradeCost.currency + ' ' + this.options.UpgradeCost.priceString);
  }

  this.attrs.changePlan = {
    actionUrl: this.options.ActionUrls[this.planType.toLowerCase()],
    nextBillingDate: this.options.NextBillingDate,
    planType: this.planType.toLowerCase(),
    costPerMonth: planCost.currency + ' ' + planCost.price,
    footnote: footnote
  };
};

PlusPlansDialogViewModel.prototype.setPlanCancelled = function () {
  this.attrs = {};
  var planFullName = this.planType.toLowerCase() == this.options.plans.ebooks ? DynamicConfiguration.resourceStrings.plusPlans.koboPlusRead : DynamicConfiguration.resourceStrings.plusPlans.koboPlusListen;
  this.attrs.stillActiveDesc = DynamicConfiguration.resourceStrings.plusPlans.planStillActiveDesc.replace('{0}', planFullName);
};

Kobo.Utilities.assignToNamespace('Kobo.Subscriptions.ViewModels.PlusPlansDialogViewModel', PlusPlansDialogViewModel);