"use strict";

/*global Kobo, $, window,Cookies */
Kobo.Gizmo.LibraryDisplayController = function (el) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      currentView,
      init,
      DisplayFormat = {
    flow: 0,
    list: 1,
    grid: 2
  },
      setWebReaderCloseButtonEventHandler;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("LibraryDisplayController");

  self.updateControls = function (ignore, input) {
    var detail = input.detail;

    if (detail.filter) {
      Kobo._mediator.fire('libraryLayout::filterChanged', detail);
    } else if (detail.sort) {
      Kobo._mediator.fire('libraryLayout::sortChanged', detail);
    }
  };

  self.persistDisplayPreferences = function (val) {
    var valuesForCookie;

    if (Cookies.get('LibraryPreferences') !== undefined) {
      if (Cookies.get('LibraryPreferences').match(/rf=/)) {
        valuesForCookie = Cookies.get('LibraryPreferences').replace(/(rf=)[0-9]*/, '$1' + DisplayFormat[val.displayFormat]);
      } else {
        valuesForCookie = Cookies.get('LibraryPreferences') + '&rf=' + DisplayFormat[val.displayFormat];
      }
    } else {
      valuesForCookie = Kobo.$.param({
        rf: DisplayFormat[val.displayFormat]
      });
    }

    var components = window.location.hostname.split(".");
    var domain = "." + components.slice(components.length - 2, components.length).join(".");
    Cookies.set('LibraryPreferences', valuesForCookie, {
      raw: true,
      domain: domain,
      path: '/',
      expires: 365
    });
  };

  self.persistWebReaderBannerPreferences = function (trigger) {
    var valuesForCookie;

    if (Cookies.get('LibraryPreferences') !== undefined) {
      if (Cookies.get('LibraryPreferences').match(/shouldShowReadNowBanner=/)) {
        valuesForCookie = Cookies.get('LibraryPreferences').replace(/(shouldShowReadNowBanner=)(true|false)/, '$1' + trigger);
      } else {
        valuesForCookie = Cookies.get('LibraryPreferences') + '&shouldShowReadNowBanner=' + trigger;
      }
    } else {
      valuesForCookie = Kobo.$.param({
        shouldShowReadNowBanner: trigger
      });
    }

    var components = window.location.hostname.split(".");
    var domain = "." + components.slice(components.length - 2, components.length).join(".");
    Cookies.set('LibraryPreferences', valuesForCookie, {
      raw: true,
      domain: domain,
      path: '/',
      expires: 365
    });
  };

  var setAriaRolesForTable = function setAriaRolesForTable() {
    var tableDiv = $el.find('.table-wrapper');

    if (tableDiv !== null || tableDiv !== undefined) {
      _adjustSeriesAriaLabels(tableDiv);

      if (currentView === 'list') {
        tableDiv.attr('role', 'table');
        tableDiv.find('.item-detail').attr('role', 'presentation');
        tableDiv.find('.element-flipper').attr('role', 'presentation');
        tableDiv.find('.item-info').attr('role', 'presentation');
        tableDiv.find('.library-items').attr('role', 'rowgroup');
        tableDiv.find('.item-wrapper').attr('role', 'row');
        tableDiv.find('.product-field').attr('role', 'cell');
        tableDiv.find('.item-added').attr('role', 'cell');
        tableDiv.find('.more-actions').attr('role', 'cell');
        tableDiv.find('.genre').attr('tabindex', 0);
        tableDiv.find('.item-status').attr('tabindex', 0);
        tableDiv.find('.date-added').attr('tabindex', 0);
        tableDiv.find('.more-actions').attr('tabindex', 0);
      } else {
        tableDiv.removeAttr('role');
        tableDiv.find('.item-detail').removeAttr('role');
        tableDiv.find('.element-flipper').removeAttr('role');
        tableDiv.find('.item-info').removeAttr('role');
        tableDiv.find('.library-items').removeAttr('role');
        tableDiv.find('.item-wrapper').removeAttr('role');
        tableDiv.find('.product-field').removeAttr('role');
        tableDiv.find('.item-added').removeAttr('role');
        tableDiv.find('.more-actions').removeAttr('role');
        tableDiv.find('.genre').removeAttr('tabindex');
        tableDiv.find('.item-status').removeAttr('tabindex');
        tableDiv.find('.item-added').removeAttr('tabindex');
      }
    }
  };

  function _adjustSeriesAriaLabels(tableDiv) {
    var seriesFields = tableDiv.find('.series.product-field');
    seriesFields.each(function () {
      var seriesField = $(this);
      var seriesLink = seriesField.find('a');
      var seriesPrefix = seriesField.find('.sequenced-name-prefix').text();
      var seriesName = seriesLink.text();

      if (seriesLink && seriesPrefix) {
        seriesLink.attr("aria-label", "".concat(seriesPrefix, " ").concat(seriesName));
      }
    });
  }
  /*jslint unparam: true*/


  self.setViewSettings = function (ignore, input) {
    self.persistDisplayPreferences({
      displayFormat: input.detail.libraryView
    });
    $el.removeClass(currentView).addClass(input.detail.libraryView);
    currentView = input.detail.libraryView;
    self.el.setAttribute('data-view', currentView);
    setAriaRolesForTable();

    Kobo._mediator.fire('libraryLayout::viewChanged', {
      libraryView: currentView
    });
  };
  /*jslint unparam: false*/


  setWebReaderCloseButtonEventHandler = function setWebReaderCloseButtonEventHandler() {
    Kobo.$('.close-button').on('click', _onBannerCloseButtonClick);
  };

  function _onBannerCloseButtonClick() {
    _hideWebReaderBanner();

    self.persistWebReaderBannerPreferences(false);
  }

  function _hideWebReaderBanner() {
    var WebReaderBanner = $el.find('.library-web-reader-banner');
    WebReaderBanner.attr('class', 'hidden');
  }

  init = function init() {
    Kobo._mediator.register('libraryLayout::viewChangeRequested');

    Kobo._mediator.register('libraryLayout::itemCountChanged');

    Kobo._mediator.register('libraryLayout::sortRequested');

    Kobo._mediator.register('libraryLayout::filterRequested');

    Kobo._mediator.register('libraryLayout::pagingRequested');

    Kobo._mediator.subscribe('libraryLayout::viewChangeRequested', self.setViewSettings);

    Kobo._mediator.subscribe('libraryLayout::itemCountChanged libraryLayout::sortRequested libraryLayout::filterRequested', self.updateControls);

    currentView = $el.data('view');
    setAriaRolesForTable();
    setWebReaderCloseButtonEventHandler();

    Kobo._mediator.fire('libraryLayout::viewInitialised', {
      libraryView: currentView
    });
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('libraryLayout::viewChangeRequested', self.setViewSettings);

    Kobo._mediator.unSubscribe('libraryLayout::itemCountChanged libraryLayout::sortRequested libraryLayout::filterRequested', self.updateControls);

    Kobo._mediator.unRegister('libraryLayout::viewChangeRequested');

    Kobo._mediator.unRegister('libraryLayout::itemCountChanged');

    Kobo._mediator.unRegister('libraryLayout::sortRequested');

    Kobo._mediator.unRegister('libraryLayout::filterRequested');

    Kobo._mediator.unRegister('libraryLayout::pagingRequested');
  };

  init();
};

Kobo.Gizmo.LibraryDisplayController.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.LibraryDisplayController.ViewTrigger = function (el, options) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("LibraryDisplayController");
  /*jslint unparam: true*/

  self.setActiveView = function (ignore, data) {
    var $activeEl = $el.find('.' + options.activeClass);

    if ($activeEl.data('koboView') !== data.detail.libraryView) {
      $activeEl.removeClass(options.activeClass);
      $activeEl.attr("aria-pressed", false);
      $el.find('[data-kobo-view="' + data.detail.libraryView + '"]').addClass(options.activeClass).attr("aria-pressed", true);
    }
  };
  /*jslint unparam: false*/


  self.triggerViewChange = function () {
    var $this = Kobo.$(this),
        view = $this.data('kobo-view');
    $this.blur();

    if (!$this.hasClass(options.activeClass)) {
      Kobo._mediator.fire('libraryLayout::viewChangeRequested', {
        libraryView: view
      });
    }
  };

  init = function init() {
    Kobo._mediator.register('libraryLayout::viewInitialised');

    Kobo._mediator.register('libraryLayout::viewChanged');

    Kobo._mediator.subscribe('libraryLayout::viewInitialised libraryLayout::viewChanged', self.setActiveView);

    $el.find('.' + options.toggleClass).on('click', self.triggerViewChange);
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('libraryLayout::viewInitialised libraryLayout::viewChanged', self.setActiveView);

    Kobo._mediator.unRegister('libraryLayout::viewInitialised');

    Kobo._mediator.unRegister('libraryLayout::viewChanged');

    $el.find('.' + options.toggleClass).off('click', self.triggerViewChange);
  };

  init();
};

Kobo.Gizmo.LibraryDisplayController.ViewTrigger.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.LibraryDisplayController.FilterTrigger = function (el, options) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("LibraryDisplayController");
  /*jslint unparam: true*/

  self.setActiveFilter = function (ignore, data) {
    $el.find('.' + options.activeClass).removeClass(options.activeClass);
    $el.find('[data-kobo-filter="' + data.detail.filter + '"]').parent().addClass(options.activeClass);
  };
  /*jslint unparam: false*/


  self.triggerFilterRequest = function () {
    var filter = this.getAttribute('data-kobo-filter');

    if (!Kobo.$(this).parent().hasClass(options.activeClass)) {
      Kobo._mediator.fire('libraryLayout::filterRequested', {
        filter: filter
      });
    }
  };

  init = function init() {
    Kobo._mediator.register('libraryLayout::filterChanged');

    Kobo._mediator.subscribe('libraryLayout::filterChanged', self.setActiveFilter);

    $el.find('.' + options.triggerClass).on('click', self.triggerFilterRequest);
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('libraryLayout::filterChanged', self.setActiveFilter);

    Kobo._mediator.unRegister('libraryLayout::filterChanged');

    $el.find('.' + options.triggerClass).off('click', self.triggerFilterRequest);
  };

  init();
};

Kobo.Gizmo.LibraryDisplayController.FilterTrigger.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.LibraryDisplayController.SortTrigger = function (el, options) {
  "use strict";

  var self = this,
      $el = Kobo.$(el),
      init;
  Kobo.Gizmo.apply(this, arguments);
  self.setType("LibraryDisplayController");
  /*jslint unparam: true*/

  self.setActiveSort = function (ignore, data) {
    $el.find('.' + options.activeClass).removeClass(options.activeClass);
    $el.find('[data-kobo-sort="' + data.detail.sort + '"]').parent().addClass(options.activeClass);
  };
  /*jslint unparam: false*/


  self.triggerSortRequest = function () {
    var sort = this.getAttribute('data-kobo-sort');

    if (!Kobo.$(this).parent().hasClass(options.activeClass)) {
      Kobo._mediator.fire('libraryLayout::sortRequested', {
        sort: sort
      });
    }
  };

  init = function init() {
    Kobo._mediator.register('libraryLayout::sortChanged');

    Kobo._mediator.subscribe('libraryLayout::sortChanged', self.setActiveSort);

    $el.find('.' + options.triggerClass).on('click', self.triggerSortRequest);
  };

  self.destroy = function () {
    Kobo._mediator.unSubscribe('libraryLayout::sortChanged', self.setActiveSort);

    Kobo._mediator.unRegister('libraryLayout::sortChanged');

    $el.find('.' + options.triggerClass).off('click', self.triggerSortRequest);
  };

  init();
};

Kobo.Gizmo.LibraryDisplayController.SortTrigger.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);