"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.CollectionsHeader = function (gizmo, options) {
  "use strict";

  var self = this,
      init,
      windowMediaOrientation = this.findMatchMediaObject(),
      previousOrientation = '';
  self.CONTENT_CHARACTER_CUTOFF = 750; // inherit from base class

  Kobo.Gizmo.apply(this, arguments);
  self.setType("CollectionsHeader");
  self.settings = Kobo.extend({}, options);

  self.replaceWithHtml = function (wholeTextElement, textToReplace, textWithHtml) {
    var newText = wholeTextElement.html().replace(textToReplace, textWithHtml);
    wholeTextElement.html(newText);
  };

  self.isTransparent = function (colour) {
    return colour === "transparent" || colour === "rgba(0, 0, 0, 0)";
  };

  self.endsWith = function (str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  };

  self.changeFontColours = function () {
    var titleWrapper = Kobo.$('.stack-header-titles-wrapper'),
        bgColour = titleWrapper.css('background-color');

    if (self.isTransparent(bgColour)) {
      titleWrapper.parents().each(function () {
        if (!self.isTransparent(Kobo.$(this).css('background-color'))) {
          bgColour = Kobo.$(this).css('background-color');
          return false;
        }
      });
    }

    var coloursOnly = bgColour.substring(bgColour.indexOf('(') + 1, bgColour.lastIndexOf(')')).split(/,\s*/);
    var brightness = (coloursOnly[0] * 299 + coloursOnly[1] * 587 + coloursOnly[2] * 114) / 1000;

    if (brightness > 130) {
      titleWrapper.addClass('stack-header-title-dark-font');
      titleWrapper.removeClass('stack-header-title-light-font');
    } else {
      titleWrapper.addClass('stack-header-title-light-font');
      titleWrapper.removeClass('stack-header-title-dark-font');
    }
  };

  self.highlightFirstOccurrenceKeyword = function () {
    var keyword = self.settings.headerTitle;
    var htmlWrap = '<strong></strong>';
    var prefix = htmlWrap.match(/<[a-z "=]*>/i);
    var suffix = htmlWrap.match(/<\/[a-z]*>/i);
    var strre = new RegExp("(" + keyword + ")", "i");
    self.replaceWithHtml($('.stack-header-description p'), strre, prefix + "$1" + suffix);
  };

  self.applyEllipsis = function () {
    // apply ellipsis before splitting into columns
    // have to allow for space between columns, so add top/bottom padding initially, and remove later
    var descriptionElem = Kobo.$('.stack-header-description'),
        innerDescriptionElem = Kobo.$('.stack-header-description p');
    descriptionElem.removeClass('stack-header-description-columns');
    descriptionElem.addClass('stack-header-description-initial');
    var readMoreLinkElem = self.settings.readMoreLink,
        readMoreLink = Kobo.$(readMoreLinkElem).attr('href'),
        hasReadMoreLink = self.endsWith(innerDescriptionElem.html(), readMoreLinkElem.replace(new RegExp("'", 'g'), "\""));

    if (readMoreLink != '' && !hasReadMoreLink) {
      if (descriptionElem.text().trim().length > self.CONTENT_CHARACTER_CUTOFF) {
        var newText = innerDescriptionElem.html().substr(0, self.CONTENT_CHARACTER_CUTOFF);
        newText = newText.substr(0, newText.lastIndexOf(' '));
        innerDescriptionElem.html(newText);
      }

      innerDescriptionElem.append("... " + readMoreLinkElem);
    }

    descriptionElem.removeClass('stack-header-description-initial');
    descriptionElem.addClass('stack-header-description-columns');
  };

  self.centerBigHeaderImage = function () {
    var imageWidth = self.settings.imageWidth;
    var containerWidth = Kobo.$('.stack-header-large-img-container').width();

    if (containerWidth > 0 && imageWidth > containerWidth) {
      var centeredImgPosition = -(imageWidth - containerWidth) / 2;
      Kobo.$('.stack-header-large-img-container img').css('left', centeredImgPosition);
    } else {
      Kobo.$('.stack-header-large-img-container img').css('left', 0);
    }
  };

  self.fixLongImageOverlapIssue = function () {
    if (Kobo._mediator.determineBreakpoint() === "small") {
      Kobo.$('.stack-header-top-section-without-large-img').css('min-height', Kobo.$('.stack-header-small-img-container').outerHeight() + 20);
    }
  };

  self.manageImages = function () {
    // imageSize: small, big, none
    var imageSize = self.settings.imageSize;
    var imageHeight = self.settings.imageHeight;
    Kobo.$('.stack-header-title-with-big-img-loading').addClass('stack-header-title-with-big-img');

    switch (imageSize) {
      case "small":
        Kobo.$('.stack-header-large-img-container').addClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-top-section-wrapper').addClass('stack-header-top-section-without-large-img');
        Kobo.$('.stack-header-title').removeClass('stack-header-title-with-big-img');
        Kobo.$('.stack-header-small-img-container').removeClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-small-img-container').removeClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-description').addClass('stack-header-description-small-image');
        Kobo.$('.stack-header-description-container').css('min-height', Kobo.$('.stack-header-small-img-container').find('img').height() - Kobo.$('.stack-header-top-section-wrapper').height());
        self.fixLongImageOverlapIssue();
        break;

      case "big":
        Kobo.$('.stack-header-title').addClass('stack-header-title-with-big-img');
        Kobo.$('.stack-header-large-img-container').removeClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-top-section-with-large-img').css('height', imageHeight);
        Kobo.$('.stack-header-top-section-with-large-img').css('height', imageHeight);
        Kobo.$('.stack-header-large-img-container').css('height', imageHeight);
        self.centerBigHeaderImage();
        break;

      case "none":
      default:
        Kobo.$('.stack-header-large-img-container').addClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-small-img-container').addClass('stack-header-hide-img-container');
        Kobo.$('.stack-header-top-section-wrapper').removeClass('stack-header-top-section-with-large-img');
        Kobo.$('.stack-header-top-section-wrapper').addClass('stack-header-top-section-without-large-img');
        Kobo.$('.stack-header-title').removeClass('stack-header-title-with-big-img');
        Kobo.$('.stack-header-title').addClass('stack-header-title-without-big-img');
        Kobo.$('.stack-header-description').removeClass('stack-header-description-small-image');
        break;
    }
  };

  self.resizeDescriptionContainer = function () {
    var mediator = new Kobo.Mediator();
    var screenSize = mediator.determineBreakpoint();

    if (screenSize != 'small') {
      var innerHeight = Kobo.$('.stack-header-description').height();
      Kobo.$('.stack-header-description-container').css('height', innerHeight);
    } else {
      Kobo.$('.stack-header-description-container').css('height', '');
    }
  };

  self.windowResizeRecalculations = function () {
    self.resizeDescriptionContainer();
    self.centerBigHeaderImage();
    self.fixLongImageOverlapIssue();
  };

  var checkOrientation = function checkOrientation(m) {
    var currentOrientation = m.matches ? 'portrait' : 'landscape';

    if (currentOrientation != previousOrientation) {
      previousOrientation = currentOrientation;
      self.windowResizeRecalculations();
    }
  }; // Add a media query change listener (for devices, because on offline pages inline events don't work as expected)


  windowMediaOrientation.addListener(function (m) {
    checkOrientation(m);
  });

  init = function init() {
    // get initial orientation
    previousOrientation = windowMediaOrientation.matches ? 'portrait' : 'landscape';
    $(window).load(function () {
      // this stuff has to happen once all images are loaded
      self.manageImages();
      self.changeFontColours();
      self.applyEllipsis();
      self.highlightFirstOccurrenceKeyword();
      self.events.onResize(self.windowResizeRecalculations);
      self.resizeDescriptionContainer();
    });
  };

  init();
};

Kobo.Gizmo.CollectionsHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
/**
 * definition: match media queries are defined here
 */

Kobo.Gizmo.CollectionsHeader.prototype.mediaQueries = {
  QUERY_ORIENTATION_PORTRAIT: "(orientation: portrait)",
  QUERY_ORIENTATION_LANDSCAPE: "(orientation: landscape)"
};
/**
 * @define returns a stub for browsers not supporting matchMedia.  Should be for android > 2.0, Return stub because shim
 * did not support addListener https://github.com/paulirish/matchMedia.js
 */

Kobo.Gizmo.CollectionsHeader.prototype.findMatchMediaObject = function () {
  var stubMatchMedia = {
    addListener: function addListener() {},
    matches: function matches() {}
  };

  if (window.matchMedia) {
    return window.matchMedia(this.mediaQueries.QUERY_ORIENTATION_PORTRAIT);
  } else {
    return stubMatchMedia;
  }
};